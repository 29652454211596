import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { Card, AvatarProgress, GridItem, ProgressTitleIcon, ProgressChecklistIcon, ProgressCheckIcon } from './styles';
import { DialogEvaluationEdit } from '../Dialogs/DialogEvaluationEdit';
import { DialogEvaluationDelete } from '../Dialogs/DialogEvaluationDelete';

export function EvaluationFormActions() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { group, evaluations } = useSelector(state => state.generalEvaluation);
  const [openDialogEvaluationEdit, setOpenDialogEvaluationEdit] = useState(false);
  const [openDialogEvaluationDelete, setOpenDialogEvaluationDelete] = useState(false);
  const groupId = group[0]?._id;

  const shouldDisableButton = group.every((item) => !!item.description.length) &&
    evaluations.every((item) => !!item.name.length && !!item.description.length);
  
  const titleProgressColor = group.every((item) => !!item.description.length) ?theme.palette.auxiliary.green.main : theme.palette.neutrals.low.lightest;
  const evaluationsProgressColor = evaluations.every((item) => !!item.name.length && !!item.description.length) && !!evaluations.length ? theme.palette.auxiliary.green.main : theme.palette.neutrals.low.lightest;

  console.log(evaluations)

  function handleDialogEvaluationEdit() {
    setOpenDialogEvaluationEdit(!openDialogEvaluationEdit);
  }

  function handleDialogEvaluationDelete() {
    setOpenDialogEvaluationDelete(!openDialogEvaluationDelete);
  }

  return (<>
    <DialogEvaluationEdit open={openDialogEvaluationEdit} onClose={handleDialogEvaluationEdit} />
    <DialogEvaluationDelete open={openDialogEvaluationDelete} onClose={handleDialogEvaluationDelete} />

    <Card elevation={0} style={{ position: 'sticky', top: '100px' }}>
      <Grid container spacing={3}>
        {!groupId && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
              {t('progresso').toUpperCase()}
            </Typography>
          </Grid>
        )}
        {!groupId && (<>
          <GridItem item xs={12}>
            <AvatarProgress style={{ background: titleProgressColor }}>
              <ProgressTitleIcon />
            </AvatarProgress>
            <Typography variant="subtitle1">{t('titulo')}</Typography>
          </GridItem>
          <GridItem item xs={12}>
            <AvatarProgress style={{ background: evaluationsProgressColor }}>
              <ProgressChecklistIcon />
            </AvatarProgress>
            <Typography variant="subtitle1">{t('criterios')}</Typography>
          </GridItem>
        </>)}
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleDialogEvaluationEdit}
            disabled={!shouldDisableButton}
          >
            {groupId ? t('salvar_edicao') : t('salvar_avaliacao')}
          </Button>
        </Grid>
        {groupId && (
          <Grid item xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleDialogEvaluationDelete}
            >
              {t('excluir_avaliacao')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  </>);
}