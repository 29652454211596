import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  ChallengeCreateCard,
  ChallengeImageCard,
  ChallengeCriteriaCard,
  ChallengeProgressCard,
  ChallengeAwardCard,
  ChallengeDatesCard,
  ChallengeVideo,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { challengeActions } from '../../actions';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: 12,
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ChallengeNewPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    newChallenge: {
      rewards,
      name,
      description,
      goal,
      requirement,
      startDate,
      dueDate,
      resultDate,
      thumbnail,
      banner
    },
    progress,
    newChallenge,
    loading
  } = useSelector((state) => state.challenge);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(challengeActions.clearProgress());
  }, []);

  const shouldDisable = (
    !!name?.length &&
    description?.length > 100 &&
    !!requirement?.length &&
    !!goal?.length
  );

  return (
    <>
      <BackNavAdmin title={t('novo_desafio')}></BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ChallengeCreateCard name={name} description={description} goal={goal} />
              </Grid>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.card}>
                  <ChallengeImageCard type="thumbnail" imagem={thumbnail} />
                  <ChallengeImageCard type="banner" imagem={banner} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <ChallengeVideo isEdit={true} />
              </Grid>
              <Grid item xs={12}>
                <ChallengeCriteriaCard requirement={requirement} />
              </Grid>
              <Grid item xs={12}>
                <ChallengeDatesCard
                  startDate={startDate}
                  dueDate={dueDate}
                  resultDate={resultDate}
                />
              </Grid>
              {config.SHOW_REWARD_FIELDS_ON_CHALLENGE && (
                <Grid item xs={12}>
                  <ChallengeAwardCard rewards={rewards} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ChallengeProgressCard
                progress={progress}
                challenge={newChallenge}
                loading={loading}
                shouldDisable={!shouldDisable}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ChallengeNewPage };