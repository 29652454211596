import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { evaluationTeamsActions } from '../../actions';
import { BackNavAdmin, EmptyState } from '../../components/Shared';
import { SkeletonEvaluationTeams } from '../../components/Skeletons';
import { DialogCreateEvaluationTeam, CardWithEvaluationTeams } from '../../components/EvaluationTeams';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-evaluation-teams.svg';
import { portfolioDetailsActions } from '../../actions/portfolio/portfolio-details.actions';

export function EvaluationTeamsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialogCreateEvaluationTeam, setOpenDialogCreateEvaluationTeam] = useState(false);
  const { teams, loading } = useSelector(state => state.evaluationTeams);
  const { responsibles } = useSelector(state => state.portfolioDetails);

  useEffect(() => {
    dispatch(evaluationTeamsActions.getAllEvaluationTeams());
    dispatch(portfolioDetailsActions.getAllResponsibles());
  }, []);

  function handleOpenDialogCreateEvaluationTeam() {
    setOpenDialogCreateEvaluationTeam(!openDialogCreateEvaluationTeam);
  }

  useEffect(() => {
    dispatch(evaluationTeamsActions.loadParticipants(responsibles.map(item => ({
      name: item.name,
      _id: item.value,
    }))));
  }, [responsibles]);

  return (<>
    <DialogCreateEvaluationTeam
      open={openDialogCreateEvaluationTeam}
      onClose={handleOpenDialogCreateEvaluationTeam}
    />

    <BackNavAdmin title={t('equipes_de_avaliacao')}>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={handleOpenDialogCreateEvaluationTeam}
      >
        {t('nova_equipe')}
      </Button>
    </BackNavAdmin>
    <Container maxWidth="lg" style={{ marginTop: 32 }}>
      <Grid container spacing={3}>
        {loading ? (
          <Grid item xs={12}>
            <SkeletonEvaluationTeams />
          </Grid>
        ) : (<>
          {teams?.length > 0 ? (
            <Grid item xs={12}>
              <CardWithEvaluationTeams />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <EmptyState
                icon={emptyStateIcon}
                description={
                  <Box maxWidth="550px" display="flex" flexDirection="column">
                    <strong>{t('ainda_nao_ha_equipes_de_avaliacao')}</strong>
                    {t('que_tal_adicionar_uma_nova_equipe_para_avaliar_as_ideias')}
                  </Box>
                }
              />
            </Grid>
          )}
        </>)}
      </Grid>
    </Container>
  </>);
}