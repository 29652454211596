import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Grid, Card, Typography, Avatar, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import PersonIcon from '@material-ui/icons/Person';
import { ReactComponent as AccompanimentIcon } from '../../assets/imgs/icon-content_paste_search.svg';
import { ReactComponent as TimerIcon } from '../../assets/imgs/icon-timer.svg';
import { ReactComponent as TimerOffIcon } from '../../assets/imgs/icon-timer_off.svg';
import { ReactComponent as InfrastructureIcon } from '../../assets/imgs/infrastucture-icon.svg';

import { dateFormat } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  avatarIcon: {
    backgroundColor: theme.palette.primary.light,
    marginRight: 16
  },
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 151.5
  },
  cardDate: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  iconColors: {
    marginBottom: theme.spacing(2)
  },
  description: {
    color: theme.palette.neutrals.low.light,
    marginTop: 8
  },
  avatarIconWhite: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.neutrals.high.main,
    color: theme.palette.neutrals.low.medium,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  chip: {
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.neutrals.high.main
  }
}));

export function ChallengeFollowUp({ 
  daysAway,
  daysLeft,
  dueDate,
  ups,
  allIdeas,
  comments,
  topUser,
  topArea,
  totalParticipants,
  totalIdeasInExecutionQueue,
  totalIdeasInAwaitingQualification
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { challengeTableIdeas: { ideas } } = useSelector((state) => state.challenge);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingBottom: 32 }}>
        <Box display="flex" alignItems="center">
          <Avatar className={classes.avatarIcon}>
            <AccompanimentIcon style={{ fill: theme.palette.primary.main }} />
          </Avatar>
          <Typography variant="h3">{t('acompanhamento')}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={3}>
        <Card elevation={0} className={classes.cardDate}>
          {daysLeft > 0 ? (
            <>
              <TimerIcon
                className={classes.iconColors}
                style={{ fill: theme.palette.auxiliary.cyan.main }} 
              />
              <Typography variant="h2" style={{ color: theme.palette.auxiliary.cyan.main }}>
                {daysAway} dia{daysAway > 1 && 's'}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {t('do_desafio_aberto_ate_sua_finalizacao')}
              </Typography>
            </>
          ) : (
            <>
              <TimerOffIcon
                className={classes.iconColors}
                style={{ fill: theme.palette.neutrals.low.medium }} 
              />
              <Typography variant="h2">
                {t('fase_encerrada')}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {t('no_dia')}{' '}{dateFormat(dueDate)}
              </Typography>
            </>
          )}
        </Card>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={6}>
            <Card elevation={0} className={classes.card}>
              <EmojiObjectsIcon
                className={classes.iconColors}
                style={{ color: theme.palette.primary.main }} 
              />
              <Typography variant="h2" style= {{ color: theme.palette.primary.main }}>
                {allIdeas || 0}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {t('ideias').toLowerCase()}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Card elevation={0} className={classes.card}>
              <AccountBoxIcon
                className={classes.iconColors}
                style={{ color: theme.palette.auxiliary.indigo.main }} 
              />
              <Typography variant="h2" style={{ color: theme.palette.auxiliary.indigo.main }}>
                {totalParticipants || 0}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {t('participantes')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Card elevation={0} className={classes.card}>
              <ThumbUpIcon
                className={classes.iconColors}
                style={{ color: theme.palette.auxiliary.pink.main }} 
              />
              <Typography variant="h2" style= {{ color: theme.palette.auxiliary.pink.main }}>
                {ups || 0}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {t('ups').toLowerCase()}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Card elevation={0} className={classes.card}>
              <ModeCommentIcon
                className={classes.iconColors}
                style={{ color: theme.palette.auxiliary.orange.main }} 
              />
              <Typography variant="h2" style={{ color: theme.palette.auxiliary.orange.main }}>
                {comments || 0}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {t('comentarios')}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
                {t('ideias_por_status')}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Chip 
                  label={t('aguardando_qualificacao')} 
                  size="small" 
                  className={classes.chip}
                  style={{ background: theme.palette.auxiliary.cyan.main }}
                />
                <Typography variant="subtitle2">
                  {totalIdeasInAwaitingQualification || 0}{' '}{t('ideias').toLowerCase()}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="1rem">
                <Chip
                  label={t('fila_de_execucao')}
                  size="small"
                  className={classes.chip}
                  style={{ background: theme.palette.auxiliary.blue.main }}
                />
                <Typography variant="subtitle2">
                  {totalIdeasInExecutionQueue || 0}{' '}{t('ideias').toLowerCase()}
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Box display="flex" alignItems="center">
                <Avatar className={classes.avatarIconWhite}>
                  <InfrastructureIcon style={{ fill: theme.palette.neutrals.low.medium, width: 17 }} />
                </Avatar>
                <Box marginLeft="1rem">
                  <Typography variant="subtitle2">{topArea}</Typography>
                  <Typography variant="subtitle2" className={classes.description} style={{ marginTop: 2 }}>
                    {t('area_que_mais_contribuiu')}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" marginTop="0.5rem">
                <Avatar className={classes.avatarIconWhite}>
                  <PersonIcon fontSize="small" />
                </Avatar>
                <Box marginLeft="1rem">
                  <Typography variant="subtitle2">{topUser}</Typography>
                  <Typography variant="subtitle2" className={classes.description} style={{ marginTop: 2 }}>
                    {t('colaborador_que_mais_contribuiu')}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
