import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  TableCell,
  IconButton,
  Typography,
} from "@material-ui/core";
import ViewIcon from '@material-ui/icons/Visibility';
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import config from '../../config';
import { history } from '../../helpers';
import { useWindowSize } from '../../hooks';
import { reportActions } from "./../../actions";
import { tableCss } from './../../components/Tables/TableCss';
import { GraphicPanel, PieChartImagine } from "./../../components";
import { TablePanel, ReportCards, ReportLayout } from "./../../components";
import { CustomTooltip } from '../../components/Shared';

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
}))
const getPersonalizedClassificationsNames = (personalizedClassifications) => {
  if (personalizedClassifications && personalizedClassifications.length > 0) {
    const personalizedClassificationsNames = personalizedClassifications.map((pc) => pc.name);
    return personalizedClassificationsNames.toString();
  }
  return '';
};

export const ReportStatusIdea = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [arrItensCard, setArrItensCArd] = useState({
    cardContent: [],
    dialogContent: []
  })
  const [width] = useWindowSize();
  const { 
    tab,
    report, 
    chart,
    filters
  } = useSelector(state => state.report);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  function formatBRL(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
      .format(parseFloat(value).toFixed(2))
  }
  function handleView(id) {
    window.open(`/idea/${id}/general`, '_blank');
  }
  function handleSelectedColumnChecked(columnName) {
    return filters.properties.filter(({ value }) => value === columnName)[0]?.checked;
  }

  const tableInfo = {
    columns: [
      { id: "code", label: t('codigo') },
      { id: 'nomeautor', label: t("autor")},
      { id: 'emailautor', label: t("email_do_autor")},
      { id: 'userEnrolment', label: t("matricula")},
      { id: 'userBusinessUnit', label: t('unidade_de_negocio') },
      { id: 'userLocal', label: t('local') },
      { id: 'userOccupationArea', label: t('area') },
      { id: 'aprovador', label: t("aprovador")},
      { id: 'email', label: t("email")},
      { id: 'ideaName', label: t('nome_da_ideia') },
      { id: 'status', label: t('status') },
      { id: 'ideaVisibility', label: t('visibilidade_no_feed') },
      { id: 'businessUnit', label: t('unidade_de_negocio') },
      { id: 'local', label: t('local') },
      { id: 'area', label: t('area') },
      { id: 'tipoAmbito', label: t('tipo_ambito') },
      { id: 'classificacaoPersonalizada', label: t('classificacao_personalizada') },
      { id: 'date', label: t('data')  + ' ' + t('inicio') },
      { id: 'conclusionDate', label:t('data') + ' ' + t('termino') },
      { id: "challenge", label: t('desafio') },
      { id: "retorno_estimado", label: t("retorno_estimado")},
      { id: "retorno_real", label: t("retorno_real")},
      { id: "custo_estimado", label: t("custo_estimado")},
      { id: "custo_real", label: t("custo_real")},
      // { id: "suggestions_regarding", label: t("ambito")},
      { id: 'link', label: t('link') },
    ],
    data: report.map(item => (
      <Fragment>
        {handleSelectedColumnChecked('code') && <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>{item.code || ""}</TableCell>}
        {handleSelectedColumnChecked('nomeautor') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userName || ""}</TableCell>}
        {handleSelectedColumnChecked('emailautor') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userEmail || ""}</TableCell>}
        {handleSelectedColumnChecked('userEnrolment') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userEnrolment || ""}</TableCell>}
        {handleSelectedColumnChecked('userLocal') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userLocal || ""}</TableCell>}
        {handleSelectedColumnChecked('userBusinessUnit') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userBusinessUnit || ""}</TableCell>}
        {handleSelectedColumnChecked('userOccupationArea') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userOccupationArea || ""}</TableCell>}
        {handleSelectedColumnChecked('aprovador') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.approver && item.approver.length > 0 ? item.approver[0].name : ""}</TableCell>}
        {handleSelectedColumnChecked('email') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.approver && item.approver.length > 0 ? item.approver[0].email : ""}</TableCell>}
        {handleSelectedColumnChecked('ideaName') && <TableCell classes={{ root: tableClasses.tableCell }}>
          <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip>
        </TableCell>}
        {handleSelectedColumnChecked('status') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.statusIdea?.name}</TableCell>}
        {handleSelectedColumnChecked('ideaVisibility') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.timeline === true && t('visivel') || item.timeline === false && t('nao_visivel') || ""}</TableCell>}
        {handleSelectedColumnChecked('businessUnit') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.businessUnit?.name : ""}</TableCell>}
        {handleSelectedColumnChecked('local') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""}</TableCell>}
        {handleSelectedColumnChecked('area') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.occupationArea?.name : ""}</TableCell>}
        {handleSelectedColumnChecked('tipoAmbito') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.methodology?.name}</TableCell>}
        {handleSelectedColumnChecked('classificacaoPersonalizada') && <TableCell classes={{ root: tableClasses.tableCell }}>{getPersonalizedClassificationsNames(item.personalizedClassifications)}</TableCell>}
        {handleSelectedColumnChecked('date') && <TableCell classes={{ root: tableClasses.tableCell }}>{`${moment(item.createdAt).format('DD/MM/YYYY')}`}</TableCell>}
        {handleSelectedColumnChecked('conclusionDate') && <TableCell classes={{ root: tableClasses.tableCell }}>{ item?.conclusionDate ?`${moment(item?.conclusionDate).format('DD/MM/YYYY')}`: ''}</TableCell>}
        {handleSelectedColumnChecked('challenge') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.challenge?.name || ""}</TableCell>}
        {handleSelectedColumnChecked('retorno_estimado') && <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.retornoEstimado)}</TableCell>}
        {handleSelectedColumnChecked('retorno_real') && <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.retornoReal)}</TableCell>}
        {handleSelectedColumnChecked('custo_estimado') && <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.custoEstimado)}</TableCell>}
        {handleSelectedColumnChecked('custo_real') && <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.custoReal)}</TableCell>}
        {handleSelectedColumnChecked('suggestionsRegarding') && <TableCell classes={{ root: tableClasses.tableCell }}>{item?.suggestionsRegarding?.name}</TableCell>}
        {handleSelectedColumnChecked('link') && <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>
          <IconButton onClick={() => {history.push(`/idea/${item._id}/general`)}}>
            <ViewIcon />
          </IconButton>
        </TableCell>}
      </Fragment>
    )),
  }

  useEffect(() => {
    setArrItensCArd({
      cardContent: report.map(item => (
        [
          {
            name: "nome da ideia",
            value: item.name,
          },
          {
            name: "status",
            value: item.statusIdea?.name
          },
          {
            name: "local",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""
          }
        ]
      )),
      dialogContent: report.map(item => (
        [
          {
            name: "code",
            value: item.code,
          },
          {
            name: t("autor"),
            value: item.userName,
          },
          {
            name: t("autor"),
            value: item.userEmail,
          },
          {
            name: t("autor"),
            value: item.userEnrolment,
          },
          {
            name: t('unidade_de_negocio'),
            value: item.userBusinessUnit,
          },
          {
            name: "local",
            value: item.userLocal,
          },
          {
            name: "area",
            value: item.userOccupationArea,
          },
          {
            name: t("aprovador"),
            value: item.approver && item.approver.length > 0 ? item.approver[0].name : "",
          },
          {
            name: t("email"),
            value: item.approver && item.approver.length > 0 ? item.approver[0].email : "",
          },
          {
            name: "nome da ideia",
            value: item.name,
          },
          {
            name: "status",
            value: item.statusIdea?.name
          },
          {
            name: "ideaVisibility",
            value: item.timeline
          },
          {
            name: t('unidade_de_negocio'),
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.businessUnit?.name : ""
          },
          {
            name: "local",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""
          },
          {
            name: "area",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.occupationArea?.name : ""
          },
          {
            name: "data",
            value:  moment(item.createAt).format('DD/MM/YYYY')
          },
          {
            name: "tipo / âmbito",
            value: item.methodology?.name
          },
          {
            name: "classificação personalizada",
            value: getPersonalizedClassificationsNames(item.personalizedClassifications)
          },
          {
            name: "data de conclusão",
            value: moment(item?.conclusionDate).format('DD/MM/YYYY')
          },
          {
            name: t("desafio"),
            value: item.challenge?.name,
          },
          {
            name: t("retorno_estimado"),
            value: item.retornoEstimado,
          },
          {
            name: t("retorno_real"),
            value: item.retornoReal,
          },
          {
            name: t("custo_estimado"),
            value: item.custoEstimado,
          },
          {
            name: t("custo_real"),
            value: item.custoReal,
          },
          {
            name: t("suggestions_regarding"),
            value: item.suggestionsRegarding,
          },
          {
            name: t("link"),
          },
          
        ]
      ))
    })
  }, [report])

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index)
  }
  
  return (
    <ReportLayout title={t('status_das_ideias')} tableInfo={tableInfo.columns}>
      <Tabs className={classes.tabs} value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="Tabs de grafico e tabela" indicatorColor="primary" textColor="primary" variant="fullWidth">
        <Tab 
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab 
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      { tab === "table" && (
        <Fragment>
          { width > config.RESPONSIVE_MOBILE ?  
            <TablePanel tableInfo={tableInfo} /> : 
            <ReportCards arrItems={arrItensCard} /> 
          }
        </Fragment>
      )}

      { tab === "graph" && (
        <GraphicPanel>
          <PieChartImagine data={chart} />
        </GraphicPanel>
      )}
    </ReportLayout>
  )
}
