import React, { useState } from 'react';
import {
  Container,
  Button,
  Grid,
  makeStyles,
  InputAdornment,
  Hidden,
  FormControl,
  InputLabel,
  FilledInput,
  IconButton,
  FormHelperText
} from '@material-ui/core';

import { useLocation } from "react-router-dom";
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { validation } from '../../helpers';
import { userActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { FooterAuthentication } from '../../components/Shared/FooterAuthentication';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import logoImagine from '../../assets/imgs/logo.svg';
import logoCustomer from '../../assets/imgs/logo.svg';
import loginImage from '../../assets/imgs/reset-password-newpassword.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.high.lightest,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(0),
    margin: 0,
  },
  loginGrid: {
    backgroundColor: 'white',
  },
  loginContainer: {
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  loginTitle: {
    fontSize: 28.83,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: 14,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    }
  },
  spaceBottom: {
    paddingBottom: theme.spacing(4),
  },
  presentation: {
    padding: theme.spacing(0, 12),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoImagine: {
    width: '100%',
    maxWidth: 140,
    marginRight: 30,
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      marginLeft: -8,
    },
  },
  logoCustomer: {
    width: '100%',
    maxWidth: 226,
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      marginLeft: -8,
    },
  },
  containerLogoImagine: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
  },
  resetPassword: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    paddingBottom: theme.spacing(3),
    '&:hover': {
      color: theme.palette.secondary.dark,
      textDecoration: 'underline',
    }
  }
}));

function ResetPasswordPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const [codeGetedByQueryString, setCodeGetedByQueryString] = useState(code);


  function handleClickTogglePassword() {
    setShowPassword(!showPassword);
  }

  function handleClickToggleNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function onSubmit({ code, password, confirmPassword }) {
    dispatch(userActions.confirmPasswordRecover(code, password, confirmPassword));
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container style={{ height: '100vh' }}>
        <Hidden smDown>
          <Grid item xs={8} md={6} lg={8} className={classes.presentation}>
            <div className={classes.containerLogoImagine}>

              <img
                className={classes.logoCustomer}
                src={logoCustomer}
                alt="logo-imagine"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ width: '65%' }} src={loginImage} alt="login"></img>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6} lg={4} className={classes.loginGrid}>
          <div className={classes.loginContainer}>
            <div>
              <div className={clsx(classes.title, classes.loginTitle)}>
                redefina a senha
              </div>
              <div
                className={clsx(classes.subtitle, classes.spaceBottom)}
                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              >
                alterar senha
              </div>
              <div className={clsx(classes.subtitle, classes.spaceBottom)}>
                Crie uma senha nova com pelo menos 8 caracteres e 1 caractere especial
              </div>

              <form
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* Código */}
                <FormControl
                  variant="filled"
                  error={!!errors.code}
                  className={classes.spaceBottom}
                >
                  <InputLabel htmlFor="code">código</InputLabel>
                  {codeGetedByQueryString ?
                    <FilledInput
                      id="code"
                      type="text"
                      name="code"
                      inputRef={register()}
                      defaultValue={user.code}
                      value={codeGetedByQueryString}
                    /> :
                    <FilledInput
                      id="code"
                      type="text"
                      name="code"
                      inputRef={register()}
                      defaultValue={user.code}
                    />

                  }

                  <FormHelperText id="helper-code">
                    {errors.code && errors.code.message}
                  </FormHelperText>
                </FormControl>

                {/* Nova Senha */}
                <FormControl
                  variant="filled"
                  error={!!errors.password}
                  className={classes.spaceBottom}
                >
                  <InputLabel htmlFor="password">nova senha</InputLabel>
                  <FilledInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    inputRef={register({
                      validate: {
                        goodPassword: (value) =>
                          validation.goodPassword(value) ||
                          'Senha inválida',
                      },
                    })}
                    defaultValue={user.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickTogglePassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="helper-password">
                    {errors.password && errors.password.message}
                  </FormHelperText>
                </FormControl>

                {/* New Password */}
                <FormControl
                  variant="filled"
                  error={!!errors.confirmPassword}
                  className={classes.spaceBottom}
                >
                  <InputLabel htmlFor="confirmPassword">confirmar nova senha</InputLabel>
                  <FilledInput
                    id="confirmPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    inputRef={register({
                      validate: {
                        goodPassword: (value) =>
                          validation.goodPassword(value) ||
                          'Senha inválida',
                      },
                    })}
                    defaultValue={user.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickToggleNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="helper-password">
                    {errors.confirmPassword && errors.confirmPassword.message}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: 30 }}
                  className={classes.itemsWidth}
                >
                  salvar alterações
                </Button>
              </form>
            </div>
            <div className={classes.footerContainer}>
              <FooterAuthentication />
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export { ResetPasswordPage };