import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import OutlinedFlagRoundedIcon from '@material-ui/icons/OutlinedFlagRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { menuCss } from './menuCss';
import config from '../../config';

export function MenuColaborator({ toggleDrawer, userId,user }) {
  const classes = menuCss();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <List className={classes.list} onClick={toggleDrawer}>
      <ListItem 
        button 
        component={Link} 
        to="/" 
        className={clsx(
          classes.listItem,
          location.pathname === '/'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <HomeOutlinedIcon 
            className={
              location.pathname === '/' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/' 
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('feed')}
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to="/my-ideas"
        className={clsx(
          classes.listItem,
          location.pathname === '/my-ideas'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <EmojiObjectsOutlinedIcon 
            className={
              location.pathname === '/my-ideas' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/my-ideas' 
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('minhas_ideias')}
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/wallet/${userId}`}
        className={clsx(
          classes.listItem,
          location.pathname === `/wallet/${userId}`
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <AccountBalanceWalletOutlinedIcon 
            className={
              location.pathname === `/wallet/${userId}` 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === `/wallet/${userId}`
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('meus_pontos')}
            </Typography>
          }
        />
      </ListItem>

      {
        config.SHOW_MENU_OPTION_RANKING && <ListItem
          button
          component={Link}
          to="/ranking"
          className={clsx(
            classes.listItem,
            location.pathname === '/ranking'
              ? classes.backgroundActived
              : 'inherit'
          )}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <EmojiEventsOutlinedIcon
              className={
                location.pathname === '/ranking'
                  ? classes.colorActived
                  : classes.colorDisabled
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                className={clsx(
                  classes.typography,
                  location.pathname === '/ranking'
                    ? classes.colorActived
                    : classes.colorDisabled
                )}
              >
                {t('ranking')}
              </Typography>
            }
          />
        </ListItem>
      }
      <ListItem
        button
        component={Link}
        to="/challenges"
        className={clsx(
          classes.listItem,
          location.pathname === '/challenges'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <OutlinedFlagRoundedIcon 
            className={
              location.pathname === '/challenges' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/challenges'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('desafios')}
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/archive-ideas"
        className={clsx(
          classes.listItem,
          location.pathname === '/archive-ideas'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <FolderIcon 
            className={
              location.pathname === '/archive-ideas' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/archive-ideas'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('arquivo_de_ideas')}
            </Typography>
          }
        />
      </ListItem>
 {user.businessUnitLocal?.businessUnit?.name!=='Dexco Colombia' && (
      <ListItem
        button 
        onClick={toggleDrawer}
        component={Link} 
        to={`/premiar-page`}
        className={clsx(
          classes.listItem,
          location.pathname === '/premiar-page'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ShoppingCartIcon 
            className={
              location.pathname === '/premiar-page' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/premiar-page'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('troca_de_pontos')}
            </Typography>
          }
        />
      </ListItem>
)}
    </List>
  );
}
