import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const ArchiveService = {
  getArchiveIdeas,
};

function getArchiveIdeas({ page, querySearched, limit }) {
  // page = 1
  if (!page) { page = 1 }
  if (!querySearched) { querySearched = '' }
  if (!limit) { limit = 10 }
  console.log(page, limit, querySearched)
  return axios
    .get(
      `${config.URL_BASE
      }/ideas-archive/user?q=&page=${page}&sort={%22createdAt%22:-1}&filter={%22field%22:%22name%22,%22value%22:%22${querySearched}%22}&limit=${limit}`,
      requestHeaders()
    )
    .then(handleResponse);
}
