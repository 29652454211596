import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { BackNavAdmin } from '../../components/Shared';
import { generalEvaluationActions, generalEvaluationFunctionsActions } from '../../actions';
import { EvaluationForm, EvaluationFormActions } from '../../components/GeneralEvaluation';
import { SkeletonEvaluationForm, SkeletonEvaluationFormActions } from '../../components/Skeletons';

export function GeneralEvaluationEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    group,
    evaluations,
    loading,
    paging: { page, sort }
  } = useSelector(state => state.generalEvaluation);

  useEffect(() => {
    dispatch(generalEvaluationActions.getEvaluationGroup(page, sort));

    if (group[0]?._id) {
      dispatch(generalEvaluationActions.getAllEvaluationsByGroup(group[0]?._id));
    }
    
    dispatch(generalEvaluationFunctionsActions.clearCriteriasToRemove());
  }, []);

  return (<>
    <BackNavAdmin title={t('avaliacao_geral')} />

    <Container maxWidth="lg" style={{ padding: 32 }}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          {loading 
            ? <SkeletonEvaluationForm /> 
            : <EvaluationForm groupTitle={group[0]?.description} evaluations={evaluations} />
          }
        </Grid>
        <Grid item xs={4}>
          {loading 
            ? <SkeletonEvaluationFormActions /> 
            : <EvaluationFormActions />
          }
        </Grid>
      </Grid>
    </Container>
  </>);
}