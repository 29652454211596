import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const pointsTradeService = {
    searchIdeas,
    tradePoints
};

function searchIdeas(searchText) {
    return axios.get(`${config.URL_BASE}/wallet/search/${searchText}`, requestHeaders()).then(handleResponse)
}

function tradePoints(wallet_id, status) {
    return axios.put(`${config.URL_BASE}/wallet/${wallet_id}/${status}`, {}, requestHeaders()).then(handleResponse)

}