import React, { useEffect } from 'react';
import {
  makeStyles,
  withStyles,
  Container,
  Typography,
  Grid
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { faqActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FaqTitle, SkeletonTable } from '../../components';
import { useTranslation } from 'react-i18next';

const ExpansionPanel = withStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    marginBottom: 12,
    '&:before': { display: 'none' },
    borderRadius: 4
  },
  expanded: {
     background: theme.palette.primary.light,
}
})) (MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    minHeight: 56,
    color: theme.palette.neutrals.low.dark,
  },
  
  expanded: {
    color: theme.palette.primary.main
  }
})) (MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    background: theme.palette.primary.light,
    padding: theme.spacing(3, 2),
    lineHeight: 1.5,
    color: theme.palette.neutrals.low.medium,
    borderRadius:  '0 0 4px 4px',
  }
})) (MuiExpansionPanelDetails);
 
const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.neutrals.high.lightest,
  },
  questions: {
    minHeight: '100vh',
  },
  heading: {
    fontSize: 15,
    fontWeight: 600,
  }
}));

function FaqPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    questions,
    loading,
    paging: { _page, _sort, limit },
  } = useSelector((state) => state.faq);

  useEffect(() => {
    if (!loading) {
      dispatch(faqActions.getAllQuestions(1, 0, limit));
    }
  }, []);

  return (
    <div className={classes.root}>
    <FaqTitle title={t('duvidas_frequentes')} />

      <Container className={classes.questions}>
        <Grid container spacing={2} className={classes.gridContent}>
          <Grid item xs={12}>
            {!!questions.length ? questions.map((questions, index) => (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary
                  classes={{root:"root"}}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {questions.title}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    {questions.description}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )) : <SkeletonTable />}
          </Grid>
        </Grid>
      </Container>

    </div>
  );
}

export { FaqPage }