import React from "react";
import { Box, Button, Container, Grid, TextField } from "@material-ui/core";
import { BackNavAdmin } from "../../components";
import { useTranslation } from "react-i18next";
import { Search } from "@material-ui/icons";
import { useState } from "react";
import { pointsTradeService } from "../../services";
import TradeCard from "../../components/TradeCard/TradeCard";

export function PointsTradePage() {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState([]);

    function handleGetIdeasToTrade() {
        pointsTradeService.searchIdeas(searchText)
            .then(({ data }) => {
                setData(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Container>
            <BackNavAdmin title={t("aprovacao_de_pontos")} />
            <Box
                width="100%"
                display="flex"
                justifyContent="center"
            >
                <Box>
                    <TextField
                        label={t("pesquisar_codigo")}
                        variant="filled"
                        value={searchText}
                        style={{marginTop: 20}}
                        onChange={({ target: { value } }) => setSearchText(value)}
                    />
                    <Button onClick={handleGetIdeasToTrade} style={{ marginLeft: 20, marginTop: 20, marginBottom: 20 }} color="primary" variant="contained" startIcon={<Search />}>{t("buscar")}</Button>
                </Box>
            </Box>
            {
                data && data.length > 0 && (
                    <Grid container spacing={2}>
                        {data.map((item, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                                <TradeCard
                                    premmiarId={item.premmiarId}
                                    wallet_id={item._id}
                                    userName={item.user.name}
                                    ideaName={item.idea.name}
                                    code={item.idea.code}
                                    pointsTrade={item.premmiarIntegrated}
                                    points={item.amount}
                                    searchText={searchText}
                                    setter={(data) => setData(data)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )
            }
            {
                data && data.length === 0 && (
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    >
                    Ainda não foram pesquisados pontos, digite o código da ideia na pesquisa e clique em buscar!
                </Box>
                )
            }
            

        </Container>
    );
}