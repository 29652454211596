export const userConstants = {
  GET_USER_REQUEST: 'USER_GET_USER_REQUEST',
  GET_USER_SUCCESS: 'USER_GET_USER_SUCCESS',
  GET_USER_FAILURE: 'USER_GET_USER_FAILURE',

  UPLOAD_USER_PHOTO_REQUEST: 'USER_UPLOAD_USER_PHOTO_REQUEST',
  UPLOAD_USER_PHOTO_SUCCESS: 'USER_UPLOAD_USER_PHOTO_SUCCESS',
  UPLOAD_USER_PHOTO_FAILURE: 'USER_UPLOAD_USER_PHOTO_FAILURE',

  DELETE_USER_PHOTO_REQUEST: 'USER_DELETE_USER_PHOTO_REQUEST',
  DELETE_USER_PHOTO_SUCCESS: 'USER_DELETE_USER_PHOTO_SUCCESS',
  DELETE_USER_PHOTO_FAILURE: 'USER_DELETE_USER_PHOTO_FAILURE',

  UPDATE_USER_REQUEST: 'USER_UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'USER_UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'USER_UPDATE_USER_FAILURE',

  GET_USER_RANKING_DETAILS_REQUEST: 'GET_USER_RANKING_DETAILS_REQUEST',
  GET_USER_RANKING_DETAILS_SUCCESS: 'GET_USER_RANKING_DETAILS_SUCCESS',
  GET_USER_RANKING_DETAILS_FAILURE: 'GET_USER_RANKING_DETAILS_FAILURE',
  RECOVER_PASSWORD_REQUEST:'RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS:'RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE:'RECOVER_PASSWORD_FAILURE',
  CONFIRM_PASSWORD_RECOVER_REQUEST: 'CONFIRM_PASSWORD_RECOVER_REQUEST',
  CONFIRM_PASSWORD_RECOVER_SUCCESS: 'CONFIRM_PASSWORD_RECOVER_SUCCESS',
  CONFIRM_PASSWORD_RECOVER_FAILURE: 'CONFIRM_PASSWORD_RECOVER_FAILURE',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
};
