import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiContainer from '@material-ui/core/Container';

export const Container = styled(MuiContainer)({
  paddingTop: 32
});

export const EmptyStateGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});