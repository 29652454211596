import React, { useEffect } from 'react';
import {
  Dialog,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { dateFormat } from '../../helpers';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBotton: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#6D6D6D'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 664,
    height: 480,
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    border: 0,
    marginTop: theme.spacing(3),
  },
  body: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBotton: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  textTop: {
    fontSize: 16,
    color: '#6D6D6D',

  },
  textFooter: {
    fontSize: 14,
    color: '#6D6D6D',
  },
  footer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),

  },
  infoIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  imageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 8,

  },
  link: {
    color: '#7E0386',
    fontWeight: 600,
    textDecoration: 'none',
  },
  titleIdea: {
    color: '#7E0386',

  },
  autorName: {
    marginTop: 48,

  }

}));


function ModalArchiveDetails({ openWelcomePopUp, setOpenWelcomePopUp, idea }) {
  const [open, setOpen] = React.useState(openWelcomePopUp);
  const [urlVideo, setUrlVideo] = React.useState();

  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenWelcomePopUp(false)
    setOpen(false)
  };

  useEffect(() => {
    setOpen(openWelcomePopUp)
  }, [openWelcomePopUp])

  useEffect(() => {

  }, [])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth='lg'
    >
      <div className={classes.dialog} >

        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <strong>{t('detalhes_da_ideia')}</strong>
        </DialogTitle>
        <DialogContent
          className={classes.body}
          dividers
        >
          <div>
            <strong className={classes.titleIdea}>{idea.name}</strong>
            <p>{idea.description}</p>
          </div>
          <div className={classes.autorName}>
            <strong >{idea.userName}</strong>
            <p>{dateFormat(idea.createdAt)}</p>
          </div>

        </DialogContent>
      </div>

    </Dialog>

  );
}
export { ModalArchiveDetails };
