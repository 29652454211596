import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { DialogCreateEvaluationTeam } from './DialogCreateEvaluationTeam';
import { DialogDeleteEvaluationTeam } from './DialogDeleteEvaluationTeam';
import { Gridflex, GridParticipants, UserPhoto, Chip } from './styles';
import { evaluationTeamsActions } from '../../actions';

export function TeamParticipants({ team, index }) {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [openMoreOptions, setOpenMoreOptions] = useState(null);
  const [openDialogUpdateTeam, setOpenDialogUpdateTeam] = useState(false);
  const [openDialogDeleteTeam, setOpenDialogDeleteTeam] = useState(false);
  const { teams } = useSelector(state => state.evaluationTeams);

  function splitedName(name) {
    const nameSplit = name.split(' ');

    if (nameSplit.length > 1) {
      return `${nameSplit[0]} ${nameSplit[1]}`;
    }

    return nameSplit;
  }

  function handleOpenMenu(event) {
    setOpenMoreOptions(event.currentTarget);
  }

  function handleCloseMenu() {
    setOpenMoreOptions(null);
  }

  function handleOpenDialogUpdateTeam() {
    if (!openDialogUpdateTeam) {
      dispatch(evaluationTeamsActions.loadEvaluationTeamDialog(team));
    }
    setOpenDialogUpdateTeam(!openDialogUpdateTeam);
    handleCloseMenu();
  }

  function handleOpenDialogDeleteTeam() {
    setOpenDialogDeleteTeam(!openDialogDeleteTeam);
    handleCloseMenu();
  }

  return (<>
    <DialogCreateEvaluationTeam
      open={openDialogUpdateTeam}
      onClose={handleOpenDialogUpdateTeam}
      team={team}
      isEdit={true}
    />
    <DialogDeleteEvaluationTeam
      open={openDialogDeleteTeam}
      onClose={handleOpenDialogDeleteTeam}
      team={team}
    />

    <Grid container spacing={3} style={{ paddingTop: 12 }}>
      <Gridflex item xs={4}>
        <Typography variant="body2">{team.name}</Typography>
      </Gridflex>
      <Gridflex item xs={6}>
        <Gridflex container spacing={2}>
          {team?.participants?.map(({ _id, name, attachment}) => (
            <GridParticipants item key={_id}>
              <UserPhoto src={attachment?.url} alt="user-photo" />
              <Typography variant="body2">{splitedName(name)}</Typography>
            </GridParticipants> 
          ))}
        </Gridflex>
      </Gridflex>
      <Gridflex item xs={1}>
        <Chip
          label={team.status === true ? t('ativa') : t('inativa')} size="small"
          style={{
            background: team.status === true 
              ? theme.palette.auxiliary.cyan.main
              : theme.palette.auxiliary.red.main
          }}
        />
      </Gridflex>
      <Gridflex item xs={1}>
        <IconButton onClick={handleOpenMenu}>
          <MoreHorizIcon />
        </IconButton>
      </Gridflex>
      {index !== (teams.length - 1) && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>

    <Menu
      keepMounted
      elevation={2}
      anchorEl={openMoreOptions}
      open={Boolean(openMoreOptions)}
      onClose={handleCloseMenu}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuList style={{ padding: 0 }}>
        <MenuItem onClick={handleOpenDialogUpdateTeam}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary={
            <Typography variant="subtitle2">{t('editar')}</Typography>
          }/>
        </MenuItem>
        <MenuItem onClick={handleOpenDialogDeleteTeam}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={
            <Typography variant="subtitle2">{t('excluir')}</Typography>
          }/>
        </MenuItem>
      </MenuList>
    </Menu>
  </>);
}