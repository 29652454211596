import React from 'react';
import { 
  makeStyles, 
  Container,
  Box
} from '@material-ui/core';
import { FooterTerms } from '../../components/Shared';
import { FaqTitle } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.neutrals.low.medium,
    background: theme.palette.neutrals.high.lightest,
  },
  containerContent: {
    paddingTop: theme.spacing(4),
    minHeight: '100vh',
  },
  declaration: {
    fontSize: 15,
    fontWeight: '500',
    paddingBottom: 30,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: '600',
    paddingBottom: 10,
  },
  info: {
    fontSize: 13,
    paddingBottom: 30,
    lineHeight: 1.8
  },
}));

function TermsPage() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
    <FaqTitle title="termos de uso" />

      
      <Container maxWidth="lg" className={classes.containerContent}>
        <Box p={0}>
          <div className={classes.declaration}>
            Todo(s) aquele(s) que utilizar(em) e/ou navegar(em) neste site e na 
            plataforma do Imagine declara(m) concordar com o regulamento e os termos 
            de uso aqui descritos.
          </div>
          <div className={classes.subTitle}>Cessão e uso de ideias</div>
          <div className={classes.info}>
            <p>Considerando que o intuito do Programa Imagine é promover a participação 
              direta dos Colaboradores no processo de inovação e desenvolvimento da Empresa, 
              ao cadastrar sua ideia no Programa, os Colaboradores cedem exclusiva, irrevogável e irrestritamente à Empresa a titularidade e o uso de sua ideia para seu amplo, total, pleno e irrestrito uso, exploração e disposição. A Empresa poderá implantar, a qualquer tempo e de acordo com seu exclusivo interesse, as ideias que os Colaboradores apresentarem no Programa Imagine, sem qualquer tipo de restrição, inclusive no caso da ideia não ter sido objeto de premiação, tendo em vista que o resultado decorrente da ideia apresentada será sempre de propriedade exclusiva da Empresa.<br/>
 O Colaborador que apresentar a ideia não terá direito a qualquer remuneração adicional ao seu salário pela apresentação e/ou implantação da ideia. Somente terá direito à premiação específica, caso sua ideia seja escolhida como vencedora de um desafio, o qual tem suas regras de premiação definidas de acordo com o critério estabelecido pela área criadora do desafio. Outras premiações podem ser aplicadas a participantes da plataforma, com base em critérios previamente definidos e comunicados pelas áreas de negócio.</p>
          </div>

          <div className={classes.subTitle}>Sobre o funcionamento</div>
          <div className={classes.info}>
          <p>Todos os colaboradores poderão acessar a plataforma através do computador, tablet e celular (smartphone), dentro ou fora da empresa. Poderão enviar ideias para todas as áreas de negócios, locais e áreas, de forma individual ou conjunta, além de colaborar com outras ideias publicadas no painel de ideias. Além das ideias cadastradas pelos Colaboradores no Programa de forma rotineira, a Comissão de Inovação e/ou a Coordenação do Programa e/ou gestores poderão criar esporadicamente, por períodos determinados, desafios para assuntos específicos, de interesse da Empresa.</p>
          </div>

          {/* <div className={classes.subTitle}>Ideias Patenteáveis</div>
          <div className={classes.info}>
          As ideias cadastradas no Programa Imagine, a exclusivo critério da 
          Empresa e em qualquer etapa do Programa, podem ser objeto de pedido 
          formal de patente protocolado pela Empresa perante o Instituto Nacional 
          de Propriedade Industrial (“INPI”).

          A decisão de solicitar o registro da patente da ideia junto ao INPI 
          caberá exclusivamente à Empresa, na qualidade de titular da ideia, 
          levando em conta os requisitos de patenteabilidade descritos na Lei 
          de Propriedade Industrial nº 9.279/96.

          O Colaborador cuja ideia for objeto de pedido de patente não terá 
          direito a qualquer remuneração adicional ao seu salário.

          O Colaborador que eventualmente for desligado da Empresa antes do 
          protocolo do pedido de patente da ideia não terá direito à pontuação 
          descrita neste item, e consequentemente nem à respectiva premiação.
          </div> */}
        </Box>
      </Container>

      <FooterTerms />
    </div>
  );
}

export { TermsPage };
