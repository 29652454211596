import React from 'react';
import { uniqueId } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogContent,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { ideaCreateActions, ideaCreateDialogsActions } from '../../actions';
import config from '../../config';
import { dialogCss } from './dialogCss';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import { CreateIdeaActions } from './CreateIdeaActions';
import { useCreateIdeaController } from './hooks';

function DialogChallenge({ styles }) {
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  let { Challenge } = useSelector((state) => state.domains);
  const { dialogChallenge, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const idea = useSelector((state) => state.ideaCreate);
  const { handleSubmit, register } = useForm();
  const notChallenge = {
    _id: 300,
    name: 'Ideia Aberta',
    notChallenge: true,
  };
  const { t } = useTranslation();
  const { previous, close } = useCreateIdeaController();

  function onSubmit() {
    if (fromHistory === 'sendIdea') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }

    if (config.PERSONALIZED_CLASSIFICATION_COLABORATOR) {
      dispatch(ideaCreateDialogsActions.openDialogClassification('DIALOG_CHALLENGE'));
    } else {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
    };
  }

  function selectedChallenge(challenge) {
    dispatch(ideaCreateActions.setChallenge(challenge));
  }

  return (
    <DialogCreateIdea open={dialogChallenge}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('para_qual_desafio_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={dialogClasses.dividerBottom}>
        <FormControl fullWidth>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('se_sua_ideia_responde_a_um_desafio_selecione_a')}, {' '}
            {t('se_nao_selecione_uma_ideia_aberta')}.
          </FormLabel>
          <RadioGroup aria-label="challenge" name="challenge">
            {Challenge &&
              Challenge?.filter(item => item.statusChallenge?.code === 4).map((challenge) => (
                <FormControlLabel
                  key={challenge._id}
                  value={challenge._id}
                  className={dialogClasses.label}
                  control={<Radio color="secondary" />}
                  onChange={() => selectedChallenge(challenge)}
                  label={challenge.name}
                  inputRef={register}
                  checked={idea?.challenge?.name === challenge?.name}
                />
              ))}
            <FormControlLabel
              key={notChallenge._id}
              value={notChallenge._id}
              className={dialogClasses.label}
              control={<Radio color="secondary" />}
              label={ t("ideia_aberta") }
              onChange={() => selectedChallenge(notChallenge)}
              inputRef={register}
              checked={idea?.challenge?._id === notChallenge?._id}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <CreateIdeaActions name="DIALOG_CHALLENGE" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}

export { DialogChallenge };