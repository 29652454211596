import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

import { MenuInformation } from '../../Shared';
import { Card, GridItem, AvatarIcon } from './styles';
import { generalEvaluationFunctionsActions } from '../../../actions';
import { ReactComponent as ChecklistIcon } from '../../../assets/imgs/checklist-icon.svg';
import { useState } from 'react';
import { ImageCrop } from '../../ImageCrop';
import { Photo } from '@material-ui/icons';
import { useForm } from 'react-hook-form';

export function EvaluationForm({ groupTitle, evaluations }) {
  const inputRef = React.createRef();
  const [src, setSrc] = useState(null);
  const [open, setOpen] = useState(false);
  const [newGroup, setNewGroup] = useState('');
  const [criteriaId, setCriteriaId] = useState('');
  const [previewImage, setPreviewImage] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  function handleAddNewCriteria() {
    dispatch(generalEvaluationFunctionsActions.addNewCriteria());
  }

  function handleRemoveCriteria(id) {
    dispatch(generalEvaluationFunctionsActions.removeCriteria(id));
  }

  function handleChangeField(e) {
    const { name, value } = e.target;
    dispatch(generalEvaluationFunctionsActions.changeFieldTitle(name, value));
    setNewGroup(value);
  }

  function handleSetCriteria(id, field, value) {
    dispatch(generalEvaluationFunctionsActions.setCriteria(id, field, value));
  }

  function handleInputChange(e) {
    setPreviewImage(prev => ([...prev, { name: e.target.files[0].name, size: e.target.files[0].size, criteriaId: e.target.id }]));
    setSrc(URL.createObjectURL(e.target.files[0]));
    setOpen(true);
    setCriteriaId(e.target.id);
  }

  function handleSetImagesToPost(blob, id) {
    const icons = previewImage.map((item) => {
      if (item.criteriaId === id) {
        return {
          ...item,
          blob,
          order: evaluations.find(({ _id }) => _id === id).order
        }
      }
      return item;
    });
    setPreviewImage(icons);
    dispatch(generalEvaluationFunctionsActions.setCriteriaIcons(icons))
  };

  function handleDeleteImage(id) {
    setPreviewImage(previewImage.filter(({ criteriaId }) => criteriaId !== id))
    dispatch(generalEvaluationFunctionsActions.deleteFile(id));
  }

  function closeCropper() {
    setOpen(false);
  }

  function formatSize(bytes) {
    const megabytes = bytes / 1024;
    return `${megabytes.toFixed(1)} KB`;
  }


  return (
    <>
      <ImageCrop src={src} dispatch={(blob) => handleSetImagesToPost(blob, criteriaId)} open={open} close={closeCropper} aspect={1 / 1} shape='round' />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card elevation={0}>
            <Grid container spacing={3}>
              <GridItem item xs={12}>
                <AvatarIcon>
                  <AssignmentTurnedInOutlinedIcon />
                </AvatarIcon>
                <Typography variant="h3">{t('titulo')}</Typography>
                <MenuInformation style={{ marginLeft: 16 }}>
                  {t('o_titulo_corresponde_ao_nome_dado_ao_conjunto_de_criterios...')}
                </MenuInformation>
              </GridItem>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  name="description"
                  label={t('titulo_da_avaliacao')}
                  defaultValue={(groupTitle || newGroup)}
                  onChange={handleChangeField}
                  required
                  inputRef={register({
                    required: t('o_titulo_do_grupo_e_obrigatorio'),
                  })}
                  error={!!errors.description}
                  helperText={errors.description && errors.description.message}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={0}>
            <Grid container spacing={3}>
              <GridItem item xs={12}>
                <AvatarIcon>
                  <ChecklistIcon />
                </AvatarIcon>
                <Typography variant="h3">{t('criterios')}</Typography>
                <MenuInformation style={{ marginLeft: 16 }}>
                  {t('os_criterios_sao_os_itens_a_serem_avaliados_quantitativamente...')}
                </MenuInformation>
              </GridItem>
              {evaluations.map((item, index) => (
                <Fragment key={item._id}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle2">{t('criterio')}{' nº'}{index + 1}</Typography>
                      <IconButton onClick={() => handleRemoveCriteria(item._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="filled"
                      name={`name${index}`}
                      label={t('nome')}
                      defaultValue={item.name}
                      onChange={(e) => handleSetCriteria(item._id, 'name', e.target.value)}
                      required
                      inputRef={register({
                        required: t('o_nome_do_criterio_e_obrigatorio'),
                      })}
                      error={!!errors[`name${index}`]}
                      helperText={errors[`name${index}`] && errors[`name${index}`].message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      variant="filled"
                      name={`description${index}`}
                      label={t('descricao')}
                      defaultValue={item.description}
                      onChange={(e) => handleSetCriteria(item._id, 'description', e.target.value)}
                      required
                      inputRef={register({
                        required: t('a_descricao_do_criterio_e_obrigatorio'),
                      })}
                      error={!!errors[`description${index}`]}
                      helperText={errors[`description${index}`] && errors[`description${index}`].message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {item.attachment?.url || previewImage.find(({ criteriaId }) => criteriaId === item._id) ? <Box style={{ width: '100%' }}>
                      <Box display="flex" alignItems="center">
                        <Photo style={{ marginRight: 10 }} />
                        <Box>
                          <Typography variant='subtitle2'>
                            {item.attachment?.fileName || previewImage.find(({ criteriaId }) => criteriaId === item._id).name}
                          </Typography>

                          <Typography variant='caption'>
                            {formatSize(item.attachment?.size || previewImage.find(({ criteriaId }) => criteriaId === item._id).size)}
                          </Typography>
                        </Box>
                        <IconButton onClick={() => handleDeleteImage(item._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box> : <Button
                      startIcon={<PhotoOutlinedIcon />}
                      variant="text"
                      component="label"
                    >
                      <input
                        id={item._id}
                        onChange={handleInputChange}
                        onClick={() => {
                          inputRef.current.value = '';
                        }}
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                      {t('inserir_icone')}
                    </Button>}
                  </Grid>
                </Fragment>
              ))}
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  onClick={handleAddNewCriteria}
                >
                  {t('adicionar_novo_criterio')}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}