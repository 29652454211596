import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Button, Card } from "@material-ui/core"
import { ideaEditStyles } from "./IdeaEditStyles"
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux'
import { ideaUpdateActions } from "./../../actions"
import { useParams } from 'react-router-dom';
import { DialogDeleteDraft } from '../../components';
import { Skeleton } from '@material-ui/lab';

export const IdeaEditActions = () => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deleteLoading } = useSelector(state => state.idea)
  const { filesToRemove, saveLoading } = useSelector(state => state.ideaUpdate)
  const { fields } = useSelector(state => state.multiAreaCascade)
  const { ideaUpdate } = useSelector(state => state)
  const { id } = useParams();
  const [ deleteDialog, setDeleteDialog ] = useState(false)

  function handleSave() {
    if (filesToRemove.length > 0) {
      dispatch(ideaUpdateActions.sendRemoveFiles(id, filesToRemove));
    }

    let data = {
      ...ideaUpdate,
      challenge: ideaUpdate.challenge?.value || null,
      businessUnitLocal: fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit),
    }
    dispatch(ideaUpdateActions.updateIdea(id, data, t('sua_ideia_foi_salva')));
  }

  function handleSaveAndSendQualification() {
    if (filesToRemove.length > 0) {
      dispatch(ideaUpdateActions.sendRemoveFiles(id, filesToRemove));
    };
    dispatch(ideaUpdateActions.updateIdea(id, {
      ...ideaUpdate,
      sendToQualification: true,
      businessUnitLocal: fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit),
    }, t('sua_ideia_foi_salva_qualificacao')));
  }
  function toggleDeleteDialog() {
    setDeleteDialog(!deleteDialog)
  }

  function SkeletonCard() {
      return(<Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 50 }}>
          <Skeleton width='90%' height={80} />
          <Skeleton width='90%' height={80} />
        </Card>)
  }
  return (
    <>
    <DialogDeleteDraft open={deleteDialog} idea={ideaUpdate} onClose={toggleDeleteDialog} />
    { ideaUpdate?._id ? (
      <Grid item container spacing={2} className={`${classes.root} ${classes.sticky}`}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            className={classes.actionButton}
            onClick={handleSave}
            disabled={deleteLoading || saveLoading}
          >
            {t('salvar_ideia')}
          </Button>
        </Grid>
        {(ideaUpdate?.statusIdea?.code === 'PENDING_REVIEW') && (<Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            className={classes.actionButton}
            onClick={handleSaveAndSendQualification}
            disabled={deleteLoading || saveLoading}
          >
            {t('enviar_para_qualificacao')}
          </Button>
        </Grid>
        )}
{(ideaUpdate?.statusIdea?.code === 'AWAITING_QUALIFICATION') && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DeleteIcon />}
            className={classes.actionButton}
            onClick={toggleDeleteDialog}
            disabled={deleteLoading || saveLoading}
          >
            {t('excluir_ideia')}
          </Button>
        </Grid>
 )}
      </Grid>
    ) : <SkeletonCard />}
    </>
  )
}
