import { executionConstants, ideaConclusionConstanst } from '../constants';
import { executionService } from '../services';
import { alertActions } from './alert.actions';
import { history, formatReal } from '../helpers';
import { ideaConclusionActions } from './idea-conclusion.actions';

export const executionActions = {
  setInputDeadline,
  addCostField,
  changeFinancialReturn,
  changeDetails,
  deleteCostField,
  changeCost,
  executeIdea,
  clear,
  getIdea,
  loadArrayCosts, 
  setFiles,
  removeFile
};

function getIdea(id) {
  return (dispatch) => {
    dispatch({
      type: executionConstants.GET_EXECUTED_IDEA_REQUEST
    });
    executionService.getIdea(id)
      .then((response) => {
        dispatch({
          type: executionConstants.GET_EXECUTED_IDEA_SUCCESS,
          payload: {
            estimatedConclusionDate: response.data.estimatedConclusionDate,
            estimatedFinancialReturn: response.data.estimatedFinancialReturn ? formatReal(String(response.data.estimatedFinancialReturn).replace(new RegExp('[.]', 'g'), "")) : "",
            estimatedStartDate: response.data.estimatedStartDate || null,
            executionDetails: response.data.executionDetails || null,
            costsFields: response.data.costs.map(item => ({
              ...item,
              cost: item.cost ? formatReal(String(item.cost).replace(new RegExp('[.]', 'g'), "")) : "", 
              estimatedCost: item.estimatedCost ? formatReal(String(item.estimatedCost).replace(new RegExp('[.]', 'g'), "")) : ""
            })),
          }
        });
        dispatch({
          type: ideaConclusionConstanst.SET_CONCLUSION_INPUT,
          payload: {
            name: "financialReturn",
            value: response.data.financialReturn ? formatReal(String(response.data.financialReturn).replace(new RegExp('[.]', 'g'), "")) : ""
          }
        });
        dispatch({
          type: ideaConclusionConstanst.SET_CONCLUSION_INPUT,
          payload: {
            name: "conclusionDetails",
            value: response.data.conclusionDetails ? response.data.conclusionDetails: response.data.executionDetails
          }
        });
    dispatch({
          type: ideaConclusionConstanst.SET_CONCLUSION_INPUT,
          payload: {
            name: "startDate",
            value: response.data.startDate || null
          }
        });
    dispatch({
          type: ideaConclusionConstanst.SET_CONCLUSION_INPUT,
          payload: {
            name: "conclusionDate",
            value: response.data.conclusionDate || null
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: executionConstants.GET_EXECUTED_IDEA_FAILURE
        })
      });
  };
}

function setInputDeadline(value, input) {
  return dispatch => {
    dispatch({
      type: executionConstants.SET_INPUT_EXECUTION,
      payload: {
        input,
        value,
      }
    })
  }
}

function changeDetails(value) {
  return dispatch => {
    dispatch({
      type: executionConstants.CHANGE_EXECUTION_DETAILS,
      payload: value
    })
  }
}

function changeCost(value, index, name) {
  return dispatch => {
    dispatch({
      type: executionConstants.CHANGE_EXECUTION_COST,
      payload: {
        value,
        index,
        name
      }
    })
  }
}

function changeFinancialReturn(value) {
  return dispatch => {
    dispatch({
      type: executionConstants.CHANGE_EXECUTION_FINANCIAL_RETURN,
      payload: value
    })
  }
}

function addCostField() {
  return dispatch => {
    dispatch({
      type: executionConstants.ADD_EXECUTION_COST_FIELD
    })
  }
}

function deleteCostField(index) {
  return dispatch => {
    dispatch({
      type: executionConstants.DELETE_EXECUTION_COST_FIELD,
      payload: index
    })
  }
}

function loadArrayCosts() {
  return dispatch => {
    dispatch({
      type: executionConstants.CREATE_IDEA_ARRAY_COSTS
    })
  }
}

function executeIdea(execution, id, successMessage, validacaoFiles, memoriaFiles) {

   
  return (dispatch) => {
    dispatch({
      type: executionConstants.POST_EXECUTION_IDEA_REQUEST 
    });
    executionService
      .executeIdea(execution, id)
      .then(() => {
        executionService.uploadFiles('calculationMemoryFile', memoriaFiles, id)
        .then(() => {
          executionService.uploadFiles('controllershipValidationFile', validacaoFiles, id)
            .then(() => {
        dispatch({
          type: executionConstants.POST_EXECUTION_IDEA_SUCCESS
        });

        dispatch(alertActions.success(successMessage));

        dispatch({
          type: executionConstants.IDEA_EXECUTION_CLEAR
        });

        history.push('/curation');
            })
        });
      })
      .catch((error) => {
        dispatch({
          type: executionConstants.POST_EXECUTION_IDEA_FAILURE,
          payload: error
        })
      });
  };
}

function clear() {
  return { type: executionConstants.IDEA_EXECUTION_CLEAR };
}
function setFiles(name, files) {
  return dispatch => {
    if (name === "memoria-calculo") {
      dispatch({
        type: executionConstants.SET_MEMORIA_CALCULO_FILES,
        payload: files
      })
    } 
    if (name === "validacao-controladoria") {
      dispatch({
        type: executionConstants.SET_VALIDACAO_CONTROLADORIA_FILES,
        payload: files
      })
    }
  }
}
function removeFile(name, id) {
  return dispatch => {
    if (name === "memoria-calculo") {
      dispatch({
        type: executionConstants.DELETE_MEMORIA_CALCULO_FILE,
        payload: id
      })
    } 
    if (name === "validacao-controladoria") {
      dispatch({
        type: executionConstants.DELETE_VALIDACAO_CONTROLADORIA_FILE,
        payload: id
      })
    }
  }
}