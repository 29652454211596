import React from 'react';
import { Card, CardActions, CardContent, Grid, Button } from '@material-ui/core';
import { HeaderCollapse } from './HeaderCollapse';
import { collapseCss } from './collapseCss';
import { useDispatch, useSelector } from 'react-redux';
import { executionActions, ideaConclusionActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import dialogFileSvg from '../../assets/imgs/dialog-file.svg';
import { 
  Upload,
  UploadButton,
  AttachmentFiles
} from "./../../components"

function UploadSection({ isConclusion, isResults = false }) {
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { execution: {executionDetails},  ideaConclusion: {conclusionDetails}} = useSelector(state => state);
  const { ideaDetails } = useSelector((state) => state.idea);

  const value = isResults ? conclusionDetails : executionDetails;

  function handleChangeDetails(e) {
   if (isConclusion || isResults) {
      dispatch(
        ideaConclusionActions.setConclusionInput(
          e.target.value,
          'conclusionDetails'
        )
      );
    } else {
      dispatch(executionActions.changeDetails(e.target.value));
    }
  }

  return (
    <Card className={collapseClasses.card} elevation={0}>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('documentos')}
          icon={<CloudUploadIcon color="primary" />}
        />
      </CardActions>
      <CardContent className={collapseClasses.content}>
        { isResults ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AttachmentFiles attachments={ideaDetails.calculationMemoryFiles} />
            </Grid>
            <Grid item xs={12}>
              <AttachmentFiles attachments={ideaDetails.controllershipValidationFiles} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Upload
                type="file"
                img={dialogFileSvg}
                name="arquivo"
                message={'memória de cálculo'}
                accept=".pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                maxSize={26214400}
                uploadFrom="memoria-calculo"
              />
                <UploadButton
                type="file"
                text={'adicionar memória de cálculo'}
                uploadFrom="memoria-calculo"
              />
            </Grid>
            <Grid item xs={12}>
              <Upload
                type="file"
                img={dialogFileSvg}
                name="arquivo"
                message={'validação controladoria'}
                accept=".pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                maxSize={26214400}
                uploadFrom="validacao-controladoria"
              />
              <UploadButton
                type="file"
                text={'adicionar validação controladoria'}
                uploadFrom="validacao-controladoria"
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export { UploadSection };
