import React from 'react';
import { makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { SearchArchive } from '../Shared';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  filter: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ArchiveIdeasFilters({ queryFilter, classification, changeStatus }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function query(q) {
    queryFilter(q);
  }

  // function handleChangeStatus(event) {
  //   changeStatus(event.target.value);
  // }

  return (
    <div className={classes.filter}>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: 300,
        }}
      >
        <div>Visualizar: </div>
        <div style={{ marginLeft: 10, width: '100%' }}>
          <FormControl
            variant="filled"
            style={{ width: '100%', maxWidth: 220 }}
          >
            <Select
              labelId="select-status"
              id="select-status"
              fullWidth
              value={classification}
              onChange={handleChangeStatus}
              disableUnderline
            >
              <MenuItem value={0}>Mais recente</MenuItem>
              <MenuItem value={1}>Mais antigo</MenuItem>
              <MenuItem value={2}>Título da ideia (A - Z)</MenuItem>
              <MenuItem value={3}>Título da ideia (Z - A)</MenuItem>
              <MenuItem value={4}>Nome do autor (A - Z)</MenuItem>
              <MenuItem value={5}>Nome do autor (Z - A)</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div> */}
      <SearchArchive query={query} placeholder={t('pesquisar_por_titulos')} />
    </div>
  );
}

export { ArchiveIdeasFilters };
