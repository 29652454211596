import React, { useEffect, useState } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import {
  BackNavAdmin,
} from '../../components';
import ChangePointsImage from '../../assets/imgs/illustration_troca de pontos.svg';
import { useSelector } from 'react-redux';
import { PremiarService } from '../../services/premiar.service'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { PointsRuleTable } from '../../components/Shared';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(6),

  },
  container: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',

  },
  icon: {
  },
  image: {
    width: 450,

  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: 171,
    height: 48,
    borderRadius: 8,
    font: 'normal normal 600 16px/ 20px Poppins',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',


  },
  siteContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'fle-start',
    width: 286,
    marginLeft: 48,

  },
  mainText: {
    font: 'normal normal bold 20px/26px Poppins',
  },
  text: {
    font: 'normal normal normal 14px/21px Poppins',
  },
  label: {
    marginLeft: 8,
  },

}));

function PremiarPage() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [url, setUrl] = useState('')

  useEffect(() => {
 

    if (!!user?._id) {

      PremiarService.getUrlPremiar({ userId: user?._id })
        .then(response => {

          setUrl(response.data.url)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user])

  return (
    <div>
      <BackNavAdmin title={t('troca_de_pontos')}
      >
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <div className={classes.container} >

          <img
            src={ChangePointsImage}
            alt='card'
            className={classes.image}
          />
          {/* <ShoppingCartIcon className={classes.icon} /> */}
          <div className={classes.siteContainer}>
            <p className={classes.mainText}>
              {t('tem_pontos_acumulados_na_plataforma')}
              <PointsRuleTable />
            </p>
            <p className={classes.text}>
              {t('agora_voce_pode_troca_los_por_diversos_premios')}
            </p>

            {url && (

              <a
                href={`${url}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}

              ><ShoppingCartIcon />
                <p className={classes.label}>
                  {t('trocar_pontos')}
                </p>
              </a>

            )}
<hr/>
{url && (

              <a
                href={`${url}`}
                target="_blank"
          
              >  
                  ACESSAR PREMMIAR
                
              </a>

            )}

            <p className={classes.text}>
              * Pontos válidos por 12 meses após recebimento na plataforma Premmiar
            </p>
          </div>
        </div>

      </Container>
    </div>
  );
}

export { PremiarPage };
