import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';
import { authHeader } from '../helpers/auth-headers';

export const executionService = {
  executeIdea,
  getIdea,
  uploadFiles
};

function executeIdea(execution, id) {
  return axios
    .post(
      `${config.URL_BASE}/ideas/${id}/execution`,
      execution,
      requestHeaders()
    )
    .then(handleResponse);
}
function getIdea(id) {
  return axios
    .get(`${config.URL_BASE}/ideas/${id}/execution/`, requestHeaders())
    .then(handleResponse);
}
function uploadFiles(type, files, ideaId) {
  const promises = files.map((file) => {
    const data = new FormData();
    data.append(type, file.file, file.name);
    return axios.post(`${config.URL_BASE}/uploads/idea/${ideaId}`, data, {
      headers: authHeader(),
    });
  });
  return Promise.all(promises);
}