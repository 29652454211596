import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

import { Container, EmptyStateGrid } from './styles';
import { generalEvaluationActions } from '../../actions';
import { Evaluations } from '../../components/GeneralEvaluation';
import { BackNavAdmin, EmptyState } from '../../components/Shared';
import { SkeletonGeneralEvaluation } from '../../components/Skeletons';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';

export function GeneralEvaluationPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { 
    group,
    evaluations,
    loading, paging: { page, sort } 
  } = useSelector(state => state.generalEvaluation);
  const groupId = group[0]?._id;
  const withoutEvaluation = group[0]?.description.length === 0 && evaluations.length === 0;

  useEffect(() => {
    dispatch(generalEvaluationActions.getEvaluationGroup(page, sort));

    // if (groupId) {
    //   dispatch(generalEvaluationActions.getAllEvaluationsByGroup(groupId));
    // }
  }, []);

  return (<>
    <BackNavAdmin title={t('avaliacao_geral')}>
      <Button
        color="primary"
        variant="contained"
        startIcon={withoutEvaluation ? <AddCircleIcon /> : <EditIcon/>}
        onClick={() => history.push('/preferences/general-evaluation/edit')}
      >
        {withoutEvaluation ? t('adicionar_avaliacao') : t('editar_avaliacao')}
      </Button>
    </BackNavAdmin>

    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          {loading ? <SkeletonGeneralEvaluation /> : (<>
            {withoutEvaluation ? (
              <EmptyStateGrid item xs={12}>
                <Box maxWidth={500}>
                  <EmptyState
                    icon={emptyStateIcon}
                    description={<>
                      <b>{t('ainda_nao_ha_avaliacao_cadastrada')}</b><br/>
                      {t('que_tal_adicionar_uma_nova_avaliacao_para_incrementar_o_processo_de_analise_das_ideias')}
                    </>}
                  />
                </Box>
              </EmptyStateGrid>
            ) : (
              <Evaluations groupTitle={group[0]?.description} />
            )}
          </>)}
        </Grid>
      </Grid>
    </Container>
  </>);
}