import { generalEvaluationConstants } from '../../constants';
import { generalEvaluationService } from '../../services';
import { alertActions } from '../alert.actions';

export const generalEvaluationActions = {
  getEvaluationGroup,
  getAllEvaluationsByGroup,
  createEvaluationGroup,
  createGeneralEvaluation,
  updateEvaluationGroup,
  updateGeneralEvaluation,
  deleteGeneralEvaluation,
  deleteGeneralEvaluationGroup
}

function getEvaluationGroup(page, sort) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.CLEAR_GENERAL_EVALUATION });
    dispatch({ type: generalEvaluationConstants.GET_EVALUATION_GROUP_REQUEST });
    generalEvaluationService.getEvaluationGroup(page, sort)
      .then(({ data }) => {
        const group = data.data.length ? data.data : [{ description: "" }];
        data.data.length && dispatch(generalEvaluationActions.getAllEvaluationsByGroup(data.data[0]._id));
        dispatch({
          type: generalEvaluationConstants.GET_EVALUATION_GROUP_SUCCESS,
          payload: group
        });
      })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.GET_EVALUATION_GROUP_FAILURE,
          error: error.toString()
        });
      })
  }
}

function getAllEvaluationsByGroup(id) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.GET_ALL_EVALUATIONS_BY_GROUP_REQUEST });
    generalEvaluationService.getAllEvaluationsByGroup(id)
      .then(({ data }) => {
        dispatch({
          type: generalEvaluationConstants.GET_ALL_EVALUATIONS_BY_GROUP_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.GET_ALL_EVALUATIONS_BY_GROUP_FAILURE,
          error: error.toString()
        });
      })
  }
}

function createEvaluationGroup(data, successMessage, criterias = [], icons) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.CREATE_EVALUATION_GROUP_REQUEST });
    generalEvaluationService.createEvaluationGroup(data).then(({ data }) => {
      if (!!criterias.length) {
        dispatch(createGeneralEvaluation(criterias.map((item) => ({ ...item, personalizedEvaluationGroup: data.data._id })),
          icons,
          successMessage
        ))
      }
      dispatch({
        type: generalEvaluationConstants.CREATE_EVALUATION_GROUP_SUCCESS,
        payload: data.data
      });
      dispatch(alertActions.success(successMessage));
    })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.CREATE_EVALUATION_GROUP_FAILURE,
          error: error.toString()
        });
      })
  }
}


function uploadCriteriaIcon(data, icons) {
  const iconListToPost = data.map(({ order, _id }) => {
    const icon = icons.find(icon => icon.order === order);
    return {
      ...icon,
      criteriaId: _id
    }
  });

  return dispatch => {
    generalEvaluationService.uploadCriteriaIcon(iconListToPost).then(() => {
      generalEvaluationService.getEvaluationGroup(1, { createAt: -1 })
        .then(({ data }) => {
          const group = data.data.length ? data.data : [{ description: "" }];
          data.data.length && dispatch(generalEvaluationActions.getAllEvaluationsByGroup(data.data[0]._id));
          dispatch({
            type: generalEvaluationConstants.GET_EVALUATION_GROUP_SUCCESS,
            payload: group
          });
        })
        .catch((error) => {
          dispatch({
            type: generalEvaluationConstants.GET_EVALUATION_GROUP_FAILURE,
            error: error.toString()
          });
        })


    }).catch(err => console.error(err));
  }
}

function createGeneralEvaluation(data, icons, successMessage) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.CREATE_GENERAL_EVALUATION_REQUEST });
    generalEvaluationService.createGeneralEvaluation(data).then(data => {
      dispatch(uploadCriteriaIcon(data, icons));
      dispatch(alertActions.success(successMessage));
    })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.CREATE_GENERAL_EVALUATION_FAILURE,
          error: error.toString()
        });
      })
  }
}

function updateEvaluationGroup(id, data) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.UPDATE_EVALUATION_GROUP_REQUEST });
    generalEvaluationService.updateEvaluationGroup(id, data)
      .then(({ data }) => {
        dispatch({
          type: generalEvaluationConstants.UPDATE_EVALUATION_GROUP_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.UPDATE_EVALUATION_GROUP_FAILURE,
          error: error.toString()
        });
      })
  }
}

function updateGeneralEvaluation(data, successMessage) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.UPDATE_GENERAL_EVALUATION_REQUEST });
    generalEvaluationService.updateGeneralEvaluation(data)
      .then(({ data }) => {
        dispatch({
          type: generalEvaluationConstants.UPDATE_GENERAL_EVALUATION_SUCCESS,
          payload: data.data
        });
        dispatch(alertActions.success(successMessage));
      })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.UPDATE_GENERAL_EVALUATION_FAILURE,
          error: error.toString()
        });
      })
  }
}

function deleteGeneralEvaluation(data, successMessage) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.DELETE_GENERAL_EVALUATION_REQUEST });
    generalEvaluationService.deleteGeneralEvaluation(data)
      .then(({ data }) => {
        dispatch({
          type: generalEvaluationConstants.DELETE_GENERAL_EVALUATION_SUCCESS,
          payload: data.data
        });
        dispatch(alertActions.success(successMessage));
      })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.DELETE_GENERAL_EVALUATION_FAILURE,
          error: error.toString()
        });
      })
  }
}

function deleteGeneralEvaluationGroup(groupId) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.DELETE_GENERAL_EVALUATION_GROUP_REQUEST })
    generalEvaluationService.deleteGeneralEvaluationGroup(groupId)
      .then(() => {
        dispatch(getEvaluationGroup(1, { createAt: -1 }));
        dispatch({
          type: generalEvaluationConstants.DELETE_GENERAL_EVALUATION_GROUP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: generalEvaluationConstants.DELETE_GENERAL_EVALUATION_GROUP_FAILURE,
          error: error.toString()
        });
      })
  }
}