import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import ViewIcon from '@material-ui/icons/Visibility';

import { dateFormat, truncate } from '../../helpers';
import { ModalArchiveDetails } from './ModalArchiveDetails';

function ArchiveIdeasItem({ tableClasses, idea }) {
  const [isModalOpened, setIsmodalOpened] = useState(false)



  function handleView() {
    console.log('verdadeiro clice')
    setIsmodalOpened(true)
  }

  return (
    <>
      <ModalArchiveDetails
        openWelcomePopUp={isModalOpened}
        setOpenWelcomePopUp={setIsmodalOpened}
        idea={idea}
      >

      </ModalArchiveDetails>
      <TableRow
        hover
        key={idea._id}
        style={{ cursor: 'pointer' }}
        classes={{ root: tableClasses.tableRow }}
      >

        <TableCell classes={{ root: tableClasses.tableCell }}>
          {dateFormat(idea.createdAt)}
        </TableCell>
        <TableCell
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          {truncate(idea.name, 70)}
        </TableCell>
        <TableCell
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          {idea.userName}
        </TableCell>
        <TableCell
          classes={{ root: tableClasses.tableCellRadiusRight }}
        >
          <IconButton
            onClick={() => {
              handleView(idea._id);
            }}
          >
            <ViewIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ArchiveIdeasItem;