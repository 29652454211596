import { MultiSelectCustom, Search } from '../Shared';
import React, { useEffect } from 'react';
import { curationActions } from '../../actions';
import { makeStyles } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CurationFilter } from './CurationFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  view: {
    color: theme.palette.neutrals.low.medium,
    marginRight: 10,
    fontSize: 12,
  },
}));

function CurationFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    paging,
    filters,
    curationTab
  } = useSelector((state) => state.curation);
  const { occupationArea } = useSelector(state => state.businessUnitProps);
  const { t } = useTranslation();

  function searchIdea(query) {
    if (curationTab === "listagem") {
      dispatch(curationActions.changeSearch(query))
      dispatch(curationActions.loadIdeasAdmin({...filters, searchText: query}, {
        ...paging,
      }, occupationArea));
    } else {
      dispatch(curationActions.changeSearch(query))
    dispatch(curationActions.loadIdeas({...filters, searchText: query}, {
      ...paging,
    }, occupationArea));
    }
  }
  useEffect(() => {
    return () => dispatch(curationActions.clearText())
  }, [dispatch])

  return (
    <div className={classes.root}>
      <div className={classes.containerSelect}>
        <CurationFilter />
      </div>
      <Search
        query={searchIdea}
        placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
      />
    </div>
  );
}

export { CurationFilters };
