import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Card, Box, Typography } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import InfoIcon from '@material-ui/icons/Info';
import { AvatarIconWithText } from '../Shared';
import { ChallengeUploadVideo } from './ChallengeUploadVideo';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 2)
  },
  infoIcon: {
    color: theme.palette.neutrals.low.lightest
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginTop: theme.spacing(4)
  },
  infoLabel: {
    fontSize: 13
  }
}));

export function ChallengeVideo({ isEdit }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card elevation={0} className={classes.card}>
      <AvatarIconWithText
        text="Video"
        icon={<VideocamIcon />}
      />
      <Box className={classes.content}>
        <ChallengeUploadVideo
          name="video"
          accept="video/*"
          maxSize={32428800}
          isEdit={isEdit}
        />

        <Box className={classes.info}>
          <InfoIcon className={classes.infoIcon} />
          <Typography className={classes.infoLabel}>
            {t('o_arquivo_deve_possuir_tamanho_maximo_de')}{' '}<strong>32mb</strong>.
          </Typography>  
        </Box>       
      </Box>
    </Card>
  );
}