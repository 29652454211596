import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, Grid, Typography, Box, Avatar } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { UserEdit, SelectedUser } from '../../components';
import { userFunctionActions, alertActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  avatarIcon: {
    marginRight: 16,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
}));

export const AdminUserEditPage = ({ id }) => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const {
    loadingUser,
    selectedUser,
    userType,
    sendEmailPasswordSuccess,
    sendEmailPasswordError
  } = useSelector(state => state.userFunction)
  const { t } = useTranslation();
  const { imagineLocals } = useSelector(state => state.domains)

  useEffect(() => {
    if (!selectedUser) {
      if (id && !loadingUser) {
        dispatch(userFunctionActions.loadPage(id, imagineLocals));
      } else {
        if (!userType) {
          dispatch(userFunctionActions.getUserType())
        } else {
          dispatch(userFunctionActions.getAllUsers(userType))
        }
      }
    } else {
      dispatch(userFunctionActions.loadPage(selectedUser.id, imagineLocals));
    }
  }, [id])


  useEffect(() => {
    if (selectedUser && userType) {
      dispatch(userFunctionActions.getAdminUserEdit(selectedUser.id));
      dispatch(userFunctionActions.getAllUsers(userType))
    }
  }, [selectedUser.id, dispatch]);
  
  useEffect(() => {
    if (sendEmailPasswordSuccess) {
      dispatch(alertActions.success(t('link_de_recuperacao_enviado')));
    }

    if (sendEmailPasswordError) {
      dispatch(alertActions.error(t('erro_ao_enviar_o_link_de_recuperacao')));
    }
  }, [sendEmailPasswordSuccess, sendEmailPasswordError])


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        { selectedUser && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SelectedUser />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Avatar className={classes.avatarIcon}>
                    <SettingsIcon />
                  </Avatar>
                  <Typography variant="h3">{t('editar_dados_do_usuario')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <UserEdit loadAdminUserEdit={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        )}
      </Grid>
    </Grid>
  );
}
