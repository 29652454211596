import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, makeStyles, TableCell, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Create, Delete, Edit, ExpandMore, Help, More, Save } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';
import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, faqActions } from '../../actions';
import { DialogCustomTitle, DialogImagine, SkeletonTable, TemplateCrudTable } from '../../components';
import { ImagineBackNav } from '../../components/ImagineTemplates/ImagineBackNav';
import { ImagineFormTextField } from '../../components/ImagineTemplates/ImagineFormTextField';
import { ImagineSectionTitle } from '../../components/ImagineTemplates/ImagineSectionTitle';
import { ImagineSwitchSidebar } from '../../components/ImagineTemplates/ImagineSwitchSidebar';
import { tableCss } from '../../components/Tables/TableCss';
import { dateFormat, truncate } from '../../helpers';
import { imagineService } from '../../services/crudTemplate.service';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ImagineDialogConfirmation } from '../../components/ImagineTemplates';

const ExpansionPanel = withStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    marginBottom: 12,
    '&:before': { display: 'none' },
    borderRadius: 4
  },
  expanded: {
    background: theme.palette.primary.light,
  }
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    minHeight: 56,
    color: theme.palette.neutrals.low.dark,
  },
  expanded: {
    color: theme.palette.primary.main
  }
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    background: theme.palette.primary.light,
    padding: theme.spacing(3, 2),
    lineHeight: 1.5,
    color: theme.palette.neutrals.low.medium,
    borderRadius: '0 0 4px 4px',
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  titleIcon: {
    color: theme.palette.primary.main,
    width: 18
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  },
  contentDescription: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  }
}));

export function FaqEditPage() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [paging, setPaging] = useState({});
  const [openDialogConfirmation, setDialogConfirmation] = useState(false);
  const [typePage, setTypePage] = useState('table');
  const [itemId, setItemId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const { t } = useTranslation();
  const { language } = useSelector(state => state.language);
  const tableClasses = tableCss();
  const classes = useStyles();
  const shouldDisabled = useMemo(() => !(!!title.length && !!description.length), [title, description]);
  const dispatch = useDispatch();
  const [dialogOpen, setDialog] = useState(false);

  const {
    questions,
    loading,
    paging: { _page, _sort, limit },
  } = useSelector((state) => state.faq);

  useEffect(() => {
      dispatch(faqActions.getAllQuestions(1, 0, limit));
  }, []);

  const FaqEditor = () => (
    <Card style={{ padding: 32 }}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <ImagineSectionTitle icon={<Help className={classes.titleIcon} />} title={t('duvidas_frequentes')} />
        <Button startIcon={<Create />} style={{ height: 48 }} variant='outlined' color='primary' onClick={() => setDialog(true)}>{t('editar_duvida')}</Button>
      </Box>
      <CardContent>
        <Box marginBottom={2}>
          <Typography className={classes.contentTitle} gutterBottom>{t('como_posso_ver_todas_as_ideias_ja_postadas_em_um_desafio')}</Typography>
          <Typography className={classes.contentDescription} gutterBottom>{t('no_menu_do_canto_superior_esquerdo_acesse_desafios_no_final_da_pagina_vera_uma_lista_de_todas_as_ideias_enviadas_neste_desafio_especifico.').replace(`'`, `"`).replace(`'`, `"`)}</Typography>
        </Box>
        {!!questions.length ? questions.map((questions, index) => (
          <ExpansionPanel key={index}>
            <ExpansionPanelSummary
              classes={{ root: "root" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {questions.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                {questions.description}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )) : questions.length === 0 ? <></> : <SkeletonTable />}
      </CardContent>
    </Card>
  );

  function FaqEditorCard({ index, title, description, id }) {
    function handleQuestionField(value) {
      dispatch(faqActions.setQuestion(index, value));
    }

    function handleAnswerField(value) {
      dispatch(faqActions.setAnswer(index, value));
    }

    return (
      <Box>
        <Box style={{ marginBottom: 21 }} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography style={{ fontSize: 14, fontWeight: 700, color: '#495057' }}>{`${t('duvida')} nº ${index + 1}`}</Typography>
          <IconButton onClick={() => {
            setItemId(id)
            setDialogConfirmation(true)
          }}><Delete /></IconButton>
        </Box>
        <ImagineFormTextField name={`question${index + 1}`} label='pergunta' isRequired onChange={handleQuestionField} defaultValue={title} />
        <ImagineFormTextField name={`answer${index + 1}`} label='resposta' isRequired onChange={handleAnswerField} defaultValue={description} rows={4} />
      </Box>
    );
  }

function handleAdd() {
  dispatch(faqActions.addNewQuestion());
}

function handleDelete() {
  dispatch(faqActions.deleteQuestion(itemId));
  setDialogConfirmation(false);
    if (!loading) {
      dispatch(faqActions.getAllQuestions(1, 0, limit));
    }
}

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialog(false)}>
        <DialogCustomTitle onClose={() => setDialog(false)}>{t('editar_duvidas')}</DialogCustomTitle>
        <Divider />
        <DialogContent>
          <Typography style={{ fontSize: 14, fontWeight: 400, color: '#495057', marginBottom: 21 }}>{t('adicione_respostas_as_duvidas_frequentes_dos_usuarios_sobre_a_plataforma')}</Typography>
          {!!questions.length && questions.map(({ title, description, _id }, index) => (<FaqEditorCard key={index} id={_id} index={index} title={title} description={description} />))}
        </DialogContent>
        <DialogActions style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0 21px 0 21px' }} ><Button variant="text" color='primary' startIcon={<AddCircle />} onClick={handleAdd}>{t('adicionar_duvida')}</Button>
          <Button variant="contained" color='primary' onClick={() => dispatch(faqActions.createQuestion(questions, t('dúvidas_adicionadas')))}><Save /></Button>
        </DialogActions>

      </Dialog>
      <ImagineDialogConfirmation
        open={openDialogConfirmation}
        onClose={setDialogConfirmation}
        title={t('excluir_duvida')}
        subtitle={t('deseja_excluir_a_duvida')}
        description={t('as_informacoes_sobre_a_duvida_Como_posso_editar_as_informaçoes_do_meu_perfil_serao_excluidas_permanentemnte')}
      >
        <Box>
          <Button
            style={{ marginRight: 16 }}
            variant="outlined"
            color="primary"
            onClick={() => setDialogConfirmation(false)}
          >
            {t('voltar')}
          </Button>
          <Button
            startIcon={<Delete />}
            variant="contained"
            color="primary"
            onClick={handleDelete}
          >
            {t('excluir_duvida')}
          </Button>
        </Box>
      </ImagineDialogConfirmation>
      <Grid item xs={12} lg={9}>
        <Container maxWidth="lg">
          <Typography gutterBottom>{t('duvidas_frequentes').toLocaleUpperCase()}</Typography>
          <FaqEditor title={title} description={description} />
        </Container>
      </Grid>
    </>
  );
}