import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Card, Grid, makeStyles, TextField, Typography, Avatar } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { challengeActions } from '../../actions';
import { ChallengeInfoAwardsCard } from './ChallengeInfoAwardsCard';
import { ReactComponent as MedalIcon } from '../../assets/imgs/worskpace-premium-icon.svg';

const useStyle = makeStyles(theme => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(4),
  },
  cardInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  boxIcon: {
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.neutrals.low.dark,
    marginLeft: 16
  },
  colocation: {
    display: 'flex',
    alignItems: 'center',
    width: 90,
    fontWeight: 600,
    fontSize: 16,
  },
  ideaSelect: {
    backgroundColor: theme.palette.neutrals.high.light
  },
  inputLabel: {
    textTransform: 'lowercase'
  },
  button: {
    display: 'flex',
    justifyContent: 'end'
  }
}));

export function ChallengeAwardWinningIdeas({ ideas }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { winners, challengeDetail, challengeDetail: { rewards } } = useSelector((state) => state.challenge);
  
  useEffect(() => {
    !!rewards?.length && rewards.forEach(({ index, win }) => dispatch(
      challengeActions.setWinners({ index, idea: win })
    ));
  }, [rewards]);

  function handleSendWinners() {
    if (winners.length > 0) {
      dispatch(challengeActions.postWinners(winners))
    }
  };

  function handleSelectWinners(index, id) {
    if (index !== null && id !== null) {
      const idea = ideas.find(idea => idea._id === id);
      dispatch(challengeActions.setWinners({ index, idea }))
    };
  };

  function handleSelectDefaultValue(index) {
    if (rewards[index].win) {
      const { userName, name, _id } = rewards[index].win;
      return { userName, name, _id };
    }
    return null
  };

  function clearSelectWinner(index) {
    dispatch(challengeActions.clearSelectWinner(index));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={8}>
        <Card elevation={0} className={classes.card}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.cardInfo}>
              <Avatar className={classes.boxIcon}>
                <MedalIcon className={classes.icon} />
              </Avatar>
              <Typography variant="h3" className={classes.title}>{t('ideias_premiadas')}</Typography>
            </Grid>
            <Grid item xs={12}>
              {rewards && rewards.length > 0 && rewards.map((_award, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="space-evenly" marginTop="32px">
                  <Typography className={classes.colocation}>{index + 1}° {t('lugar')}</Typography>
                  <Autocomplete
                    fullWidth
                    id={`autoComplete${index}`}
                    defaultValue={handleSelectDefaultValue(index)}
                    className={classes.ideaSelect}
                    options={ideas.map(({ user, name, _id }) => ({ userName: user.name, name, _id }))}
                    getOptionLabel={(option) => `${option.name} - ${option.userName}`}
                    onChange={(_e, newValue) => newValue !== null ? handleSelectWinners(index, newValue._id) : clearSelectWinner(index)}
                    renderInput={(params) => <TextField {...params} placeholder={t('selecione_a_ideia_premiada')} label={t('ideias').toLowerCase()} variant="filled" name={index} />}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} className={classes.button}>
              <Button onClick={handleSendWinners} variant="contained" color="primary">
                {t('salvar_selecao')}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <ChallengeInfoAwardsCard challenge={challengeDetail} />
      </Grid>
    </Grid>
  );
}