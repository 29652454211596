import React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { alertActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
    fontSize: 14.22,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14.22,
  },
  text: {
    color: theme.palette.grey[500],
    fontSize: 14.22,
  },
  imageChildren: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3, 0),
  },
}));

function UploadFile({ onUpload, children, name, message, accept, maxSize }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleErrorMessage(error) {
    if (error[0].errors[0].code === 'file-too-large') {
      return dispatch(alertActions.error('Arquivo não suportado, possui mais de 32mb!'));
    }
  }

  function renderDragMessage(isDragActive) {
    if (!isDragActive) {
      return <div className={classes.text}>{message}</div>;
    }

    return <div className={classes.success}>Solte a {name} aqui</div>;
  }

  return (
    <Dropzone
      accept={accept}
      maxSize={maxSize}
      onDropAccepted={onUpload}
      onDropRejected={error => handleErrorMessage(error)}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} style={{ cursor: 'pointer', outline: 0 }}>
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive)}
          <div className={classes.imageChildren}>{children}</div>
        </div>
      )}
    </Dropzone>
  );
}

export { UploadFile };