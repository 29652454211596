import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
  AttachmentFiles,
  UploadSection
} from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ideaConclusionActions, executionActions } from '../../actions';
import { useTranslation } from 'react-i18next';

function IdeaResults() {
  const { ideaDetails } = useSelector((state) => state.idea);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.execution);
  useEffect(() => {
    if (ideaDetails._id) {
      dispatch(executionActions.getIdea(id));
    }
  }, [dispatch, id, ideaDetails._id]);

  useEffect(() => {
    return () => {
      dispatch(executionActions.clear());
      dispatch(ideaConclusionActions.clear());
    };
  }, [dispatch]);

  function onCloseDialogJustification() {
    setOpenDialog(false);
  }

  return (
    <Fragment>
      {loading ? <Box display='flex' justifyContent='center' alignItems='center' height='80vh' width='100%'><CircularProgress /></Box> : 
      <><DialogJustification
        open={openDialog}
        onClose={onCloseDialogJustification}
        id={id}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DeadlineCollapse isResults />
        </Grid>
        <Grid item xs={12}> 
          <CostStructureCollapse isResults />
        </Grid>
        <Grid item xs={12}>
          <FinancialFeedbackCollapse isResults />
        </Grid>
        <Grid item xs={12}>
          <DetailsCollapse isResults />
        </Grid>
        <Grid item xs={12}>
          <UploadSection isResults />
        </Grid>
      </Grid></>}
    </Fragment>
  );
}

export { IdeaResults };
