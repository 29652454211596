import React, { useState, useEffect, useCallback } from 'react';
import { Container, makeStyles, Grid, Hidden } from '@material-ui/core';
import {
  BackNavAdmin,
  ArchiveIdeasFilters,
  IdeasArchiveTables
} from '../../components';
import { challengeActions } from '../../actions';
import { ArchiveService } from '../../services';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  btnNewChallenge: {
    float: 'right'
  },
}));

function ArchiveIdeasPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [ideas, setIdeas] = useState([])
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(1)
  const [filter, setFilter] = useState([])
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)

  const getArchiveIdeas = useCallback(({ page, limit, querySearched }) => {
    ArchiveService.getArchiveIdeas({ page, limit, querySearched }).then(response => {
      setIdeas(response.data)
      setTotal(response.paging.total)
    })
      .catch((error) => {
        console.error(error);
      });

  }, [])


  useEffect(() => {
    getArchiveIdeas({ page, limit })
  }, [getArchiveIdeas, limit, page]);

  function searchChallenge(query) {
    getArchiveIdeas({ page, limit, querySearched: query })
  }

  function changeStatus(status) {
    // filter[1].value = status;
    // dispatch(challengeActions.getChallenges(1, sort, filter));
  }

  function renderTable() {
    if (ideas.length > 0) {
      return (
        <IdeasArchiveTables
          ideas={ideas}
          setIdeas={setIdeas}
          total={total}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit} />
      );
    }

    return null;
  }

  return (
    <div>
      <BackNavAdmin title={t('arquivo_de_ideas')} pathname="/" />

      <Container maxWidth="lg" className={classes.root}>
        <ArchiveIdeasFilters
          queryFilter={searchChallenge}
          classification={filter}
          changeStatus={changeStatus}
        />

        <Grid xs={12} item>
          <Hidden smDown>
            {renderTable()}
          </Hidden>
          {/* <Hidden smUp>
            <div style={{ fontSize: 14.22, fontWeight: 'bold', paddingBottom: 12 }}>
              IDEIAS PUBLICADAS NO FEED
            </div>
            {renderCards()}
          </Hidden> */}
        </Grid>
      </Container>
    </div>
  );
}

export { ArchiveIdeasPage };
