import React from 'react';
import {
  Card,
  Button,
  makeStyles,
  TextField,
  Grid,
  IconButton,
  useTheme
} from '@material-ui/core';
import { challengeActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import DeleteIcon from '@material-ui/icons/Delete';
import { grey } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import { AvatarIconWithText } from '..';
const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 2)
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  containerRemoveButton: {
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    color: grey[500],
    '&:hover': {
      color: theme.palette.auxiliary.pink.main,
    },
  },
}));

function ChallengeAwardCard({ rewards }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleSubmit, register, errors, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      rewards: rewards ? rewards : [{ description: '', score: null }],
    },
  });
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'rewards',
  });

  function removeAward(index) {
    dispatch(challengeActions.removeAward(index));
  }

  function onSubmit(data) {
    if (data) {
      dispatch(challengeActions.setAward(data.rewards));
    }
  }

  return (
    <Card elevation={0} className={classes.card}>
      <AvatarIconWithText
        text={t('premiacao')}
        icon={<EmojiEventsIcon color="primary" />}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            {t('colocacao')}
          </Grid>
          <Grid item xs={7}>
            {t('descricao')}
          </Grid>
          <Grid item xs={2}>
            {t('pontos').toLowerCase()}
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        {fields.map((award, index) => (
          <Grid container spacing={3} key={award.id}>
            <Grid
              item
              xs={2}
              style={{
                color: theme.palette.neutrals.low.dark,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {index + 1}º {t('lugar')}
            </Grid>
            <Grid item xs={7} className={classes.marginBetweenInputs}>
              <TextField
                name={`rewards[${index}].description`}
                id="description"
                autoComplete="description"
                type="text"
                variant="filled"
                margin="normal"
                placeholder={t('qual_sera_o_premio')}
                fullWidth
                inputRef={register({
                  required: t('necessario_a_descricao_do_premio'),
                  maxLength: 100,
                })}
                error={!!errors?.['rewards']?.[index]?.description}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                defaultValue={award.description}
                onBlur={handleSubmit(onSubmit)}
              />
              <p className={classes.error}>
                {errors?.['rewards']?.[index]?.description?.type ===
                  'maxLength' && t('so_e_permitido_somente_cem_caracteres')}
                {errors?.['rewards']?.[index]?.description?.type ===
                  'required' && t('necessario_a_descricao_do_premio')}
              </p>
              <p className={classes.error}>
                {errors?.['rewards']?.[index]?.score?.type ===
                  'maxLength' && 'Só é permitido somente 6 caracteres'}
              </p>
              <p className={classes.error}>
                {errors?.['rewards']?.[index]?.score?.type ===
                  'min' && 'A pontuação deve ser acima de 0'}
              </p>
            </Grid>
            <Grid item xs={2} className={classes.marginBetweenInputs}>
              <TextField
                name={`rewards[${index}].score`}
                id="score"
                autoComplete="score"
                type="number"
                variant="filled"
                margin="normal"
                placeholder="0"
                fullWidth
                inputRef={register({
                  maxLength: 6,
                  min: 0
                })}
                error={!!errors?.['rewards']?.[index]?.score}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                defaultValue={award.score}
                onBlur={handleSubmit(onSubmit)}
              />
              <p className={classes.error}>
                {errors?.['rewards']?.[index]?.score?.message}
              </p>
            </Grid>
            <Grid item xs={1} className={classes.containerRemoveButton}>
              <IconButton
                onClick={() => {
                  remove(index);
                  removeAward(index);
                }}
                className={classes.removeButton}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Button
          onClick={() => append({ description: '', score: null })}
          startIcon={<AddCircleIcon />}
          color="primary"
        >
          {t('incluir_nova_posicao')}
        </Button>
      </form>
    </Card>
  );
}

export { ChallengeAwardCard };