import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField,
  IconButton,
  Hidden,
  useTheme,
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dialogCss } from './dialogCss';
import { useForm } from 'react-hook-form';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DialogCreateIdea } from './DialogCreateIdea';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    textTransform: 'lowercase',
  },
  alert: {
    color: theme.palette.grey[500],
  },
  strong: {
    fontWeight: 'bold',
  },
  dividerBottom: {
    borderBottom: 'none',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function DialogLink() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const { dialogLinks, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const { handleSubmit, register, errors, reset } = useForm();
  const { links } = useSelector((state) => state.ideaCreate);
  const idea = useSelector((state) => state.ideaCreate);
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const theme = useTheme();

  function createDraft() {
    dispatch(ideaCreateActions.createDraft(idea, t('ideia_salva_em_rascunhos')));
  }

  function next() {
    if (fromHistory[fromHistory.length - 1] === 'DIALOG_SEND_IDEA') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }

    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  function onSubmit(data) {
    if (data) {
      dispatch(ideaCreateActions.setLinks(data.link));
      reset();
    }
  }

  function removeLink(link) {
    dispatch(ideaCreateActions.removeLink(link));
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
    }

  return (
    <DialogCreateIdea open={dialogLinks}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('publicar_links')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <div>{t('insira_links_como_referencia_para_a_sua_ideia')}</div>
        {links.length > 0 &&
          links.map((link, index) => (
            <div className={classes.row} key={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AttachmentIcon style={{ marginRight: 8 }} />{' '}
                <span style={{ color: theme.palette.auxiliary.indigo.main }}>{link}</span>
              </div>
              <IconButton onClick={() => removeLink(link)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.margin}>
            <div
              style={{ display: 'flex', width: '100%', alignItems: 'center' }}
            >
              <AttachmentIcon style={{ marginRight: 8 }} />
              <TextField
                error={!!errors.link}
                type="text"
                id="link"
                label={t('insira_o_link_aqui') + '...'}
                autoComplete="link"
                fullWidth
                name="link"
                inputRef={register({
                  required: t('o_link_e_obrigatorio'),
                  pattern:
                    /(?:https?:\/\/|www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                })}
                helperText={
                  (errors.link?.type === 'pattern' &&
                    'Esse não é um link valido') ||
                  (errors.link && errors.link.message)
                }
                InputProps={{ disableUnderline: true }}
              />
            </div>
            <Button
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.button}
              type="submit"
            >
              {t('adicionar_link')}
            </Button>
          </div>
        </form>
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {fromHistory.length > 0 && (
                <Button
                  color="primary"
                  onClick={previous}
                  startIcon={<ArrowBackIcon />}
                  className={dialogClasses.button}
                >
                  {t('voltar')}
                </Button>
              )}
            </div>
            <div>
              <Hidden smDown>
                <Button
                  className={dialogClasses.button}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 8 }}
                  onClick={createDraft}
                >
                  {t('salvar_rascunho')}
                </Button>
              </Hidden>
              <Button
                className={dialogClasses.button}
                variant="contained"
                color="primary"
                startIcon={<ArrowForwardIcon />}
                onClick={() => next()}
              >
                {t('avancar')}
              </Button>
            </div>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogLink };
