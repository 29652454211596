import { evaluationTeamsService } from '../services';
import { evaluationTeamsConstants } from '../constants';
import { alertActions } from './alert.actions';

export const evaluationTeamsActions = {
  changeTeamStatus,
  setEvaluationTeamName,
  addParticipantField,
  removeParticipantField,
  changeParticipantField,
  clearParticipantField,
  loadParticipants,
  loadEvaluationTeamDialog,
  clearDialogCreateTeam,
  createEvaluationTeam,
  getAllEvaluationTeams,
  deleteEvaluationTeam,
  updateEvaluationTeam,
}

function changeTeamStatus(status) {
  return dispatch => {
    dispatch({
      type: evaluationTeamsConstants.CHANGE_TEAM_STATUS,
      payload: status
    });
  }
}

function setEvaluationTeamName(name, value) {
  return dispatch => {
    dispatch({
      type: evaluationTeamsConstants.SET_EVALUATION_TEAM_NAME,
      payload: { name, value }
    });
  }
}

function addParticipantField() {
  return dispatch => {
    dispatch({ type: evaluationTeamsConstants.ADD_PARTICIPANT_FIELD });
  }
}

function removeParticipantField(value) {
  return dispatch => {
    dispatch({
      type: evaluationTeamsConstants.REMOVE_PARTICIPANT_FIELD,
      payload: value
    })
  }
}

function changeParticipantField(data, index) {
  return dispatch => {
    dispatch({
      type: evaluationTeamsConstants.EVALUATION_TEAM_CHANGE_PARTICIPANT_FIELD,
      payload: { data, index }
    });
  }
}

function clearParticipantField(index) {
  return dispatch => {
    dispatch({
      type: evaluationTeamsConstants.CLEAR_EVALUATION_TEAM_PARTICIPANT_FIELD,
      payload: index
    });
  }
}

function loadParticipants(data) {
  return dispatch => {
    dispatch({ 
      type: evaluationTeamsConstants.LOAD_PARTICIPANTS, 
      payload: data
    });
  }
}

function loadEvaluationTeamDialog(newEvaluationTeam) {
  return dispatch => {
    dispatch({ 
      type: evaluationTeamsConstants.LOAD_EVALUATION_TEAM_IN_DIALOG, 
      payload: newEvaluationTeam
    });
  }
}

function clearDialogCreateTeam() {
  return dispatch => {
    dispatch({ type: evaluationTeamsConstants.CLEAR_DIALOG_CREATE_TEAM });
  }
}

function createEvaluationTeam(data, successMessage) {
  return dispatch => {
    dispatch({ type: evaluationTeamsConstants.CREATE_EVALUATION_TEAM_REQUEST });
    evaluationTeamsService.createEvaluationTeam(data)
      .then(() => {
        dispatch({ type: evaluationTeamsConstants.CREATE_EVALUATION_TEAM_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(getAllEvaluationTeams());
      })
      .catch((err) => {
        dispatch({
          type: evaluationTeamsConstants.CREATE_EVALUATION_TEAM_FAILURE,
          error: err
        });
      })
  }
}

function getAllEvaluationTeams() {
  return dispatch => {
    dispatch({ type: evaluationTeamsConstants.GET_ALL_EVALUATION_TEAMS_REQUEST });
    evaluationTeamsService.getAllEvaluationTeams()
      .then(({ data }) => {
        dispatch({ 
          type: evaluationTeamsConstants.GET_ALL_EVALUATION_TEAMS_SUCCESS,
          payload: data.data
        });
      })
      .catch((err) => {
        dispatch({
          type: evaluationTeamsConstants.GET_ALL_EVALUATION_TEAMS_FAILURE,
          error: err
        });
      })
  }
}

function deleteEvaluationTeam(teamId, successMessage) {
  return dispatch => {
    dispatch({ type: evaluationTeamsConstants.DELETE_EVALUATION_TEAM_REQUEST });
    evaluationTeamsService.deleteEvaluationTeam(teamId)
      .then(() => {
        dispatch({ type: evaluationTeamsConstants.DELETE_EVALUATION_TEAM_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(getAllEvaluationTeams());
      })
      .catch((err) => {
        dispatch({
          type: evaluationTeamsConstants.DELETE_EVALUATION_TEAM_FAILURE,
          error: err
        });
      })
  }
}

function updateEvaluationTeam(teamId, data, successMessage) {
  return dispatch => {
    dispatch({ type: evaluationTeamsConstants.UPDATE_EVALUATION_TEAM_REQUEST });
    evaluationTeamsService.updateEvaluationTeam(teamId, data)
      .then(() => {
        dispatch({ type: evaluationTeamsConstants.UPDATE_EVALUATION_TEAM_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(getAllEvaluationTeams());
      })
      .catch((err) => {
        dispatch({
          type: evaluationTeamsConstants.UPDATE_EVALUATION_TEAM_FAILURE,
          error: err
        });
      })
  }
}