import { combineReducers } from 'redux';
// import { registration } from './registration.reducer';
import { authentication } from './authentication.reducer';
import { user } from './user.reducer';
import { timeline } from './timeline.reducer';
import { ideaCreate } from './idea-create.reducer';
import { ideaCreateDialogs } from './idea-create-dialogs.reducer';
import { domains } from './domains.reducer';
import { language } from './language.reducer';
import { idea } from './idea.reducer';
import { notification } from './notification.reducer';
import { alert } from './alert.reducer';
import { profile } from './profile.reducer';
import { wallet } from './wallet.reducer';
import { ranking } from './ranking.reducer';
import { challenge } from './challenge.reducer';
import { execution } from './execution.reducer';
import { ideaConclusion } from './idea-conclusion.reducer';
import { curation } from './curation.reducer';
import { externalContent } from './external-content.reducer';
import { contents } from './contents.reducer';
import { manageUsers } from "./manage-users.reducer";
import { userFunction } from "./user-function.reducer";
import { faq } from './faq.reducer';
import { report } from './reports.reducer';
import { multiAreaCascade } from './multi-area-cascade.reducer';
import { businessUnitProps } from "./business-unit-props.reducer";
import { engagement } from "./engagement.reducer";
import { ideaUpdate } from './ideaUpdate.reducer';
import { feedback } from './feedback.reducer';
import { dashboard } from './dashboard.reducer';
import { infraStructure } from './infraStructure.reducer';
import { personalizedClassification } from './personalized-classification.reducer';
import { crudTemplate } from './crudTemplate.reducer';
import { portfolioHome } from "./portfolio/portfolio-home.reducer"
import { portfolioDialogProject } from "./portfolio/portfolio-dialog-project.reducer"
import { portfolioDetails } from "./portfolio/portfolio-details.reducer"
import { portfolioDialogTeams } from "./portfolio/portfolio-dialog-teams.reducer"
import { portfolioDialogFinnancial } from "./portfolio/portfolio-dialog-finnacial.reducer"
import { portfolioDialogTask } from "./portfolio/portfolio-dialog-task.reducer";
import { portfolioExecutionFlow } from './portfolio/portfolio-execution-flow.reducer';
import { imageUpload } from './imageUpload.reducer';
import { uploadsReducer } from './uploads.reducer';
import { tutorialsReducer } from './tutorials.reducer';
import { adminsReducer } from './admins.reducer';
import { evaluationTeams } from './evaluation-teams.reducer';
import { ideaGeneralEvaluation  } from './idea-general-evaluation.reducer';
import { generalEvaluation } from './generalEvaluation/general-evaluation.reducer';
import { progressBar } from './progressBar.reducer';

const rootReducer = combineReducers({
  //   registration,
  alert,
  authentication,
  user,
  timeline,
  ideaCreate,
  ideaCreateDialogs,
  domains,
  idea,
  notification,
  profile,
  wallet,
  ranking,
  challenge,
  execution,
  engagement,
  ideaConclusion,
  curation,
  contents,
  language,
  manageUsers,
  userFunction,
  faq,
  report,
  externalContent,
  multiAreaCascade,
  businessUnitProps,
  infraStructure,
  ideaUpdate,
  feedback,
  dashboard,
  portfolioHome,
  portfolioDialogProject,
  portfolioDetails,
  portfolioDialogTeams,
  portfolioDialogFinnancial,
  portfolioDialogTask,
  personalizedClassification,
  portfolioExecutionFlow,
  crudTemplate,
  imageUpload,
  uploadsReducer,
  tutorialsReducer,
  adminsReducer,
  evaluationTeams,
  ideaGeneralEvaluation,
  generalEvaluation,
  progressBar,
});

export default rootReducer;
