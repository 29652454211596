import React, { useEffect, useState, Fragment } from 'react';
import { 
  Container,
  Grid,
  makeStyles,
  Hidden,
  CircularProgress,
  Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { 
  BackNavAdmin,
  IdeaGeneral,
  IdeaExecution,
  IdeaConclusion,
  IdeaQualification,
  IdeaQualificationTabs,
  IdeaResults,
  IdeaEngagement,
  LinkedProjects,
  IdeaGeneralEvaluationLayout,
} from '../../components';
import { SidebarIdeaPoint, CustomSelect } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { ideaActions, ideaCreateActions, personalizedClassificationActions, portfolioExecutionFlow } from '../../actions';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { optionConstants } from '../../constants';
import { useTranslation } from 'react-i18next';
import { SkeletonSidebarIdeaDetails, SkeletonCardIdeaDetails } from '../../components/Skeletons/SkeletonIdeaDetails';
import { EmptyState } from '../../components/Shared';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
}));

export const IdeaDetailsPage = () => {
  const classes = useStyles();
  const history = useHistory()
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, page } = useParams();
  const { t } = useTranslation();
  const { ideaDetails, loading } = useSelector((state) => state.idea);
  const { typeProfile, _id: userId } = useSelector((state) => state.user);
  const { projects} = useSelector((state) => state.portfolioExecutionFlow);
  const ideaCreate = useSelector((state) => state.ideaCreate);
  useEffect(() => {
    dispatch(portfolioExecutionFlow.getProjects(id));
  }, [dispatch, id]);
  useEffect(() => {
  dispatch(ideaActions.clearApprovedDetails()) 
}, []);
  useEffect(() => {
    if (ideaDetails?.statusIdea?.code === 'DRAFT') {
      dispatch(ideaCreateActions.loadIdeaDetailsDraft(ideaDetails));
    }
  }, [ideaDetails]);

  useEffect(() => {
    if (['general', 'qualification', 'general-evaluation', 'execution', 'conclusion', 'results', 'engagement'].includes(page)) {
      if (!ideaDetails._id || ideaDetails._id !== id) {
        dispatch(ideaActions.getIdeaDetails(id));
      }
    } else {
      history.goBack();
    }
  }, [page, id]);

  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, [dispatch]);
  useEffect(() => {
    return () => dispatch(ideaActions.clearIdeaDetails());
  }, [dispatch]);

  function handleChangePage(key) {
    history.push(`/idea/${ideaDetails._id}/${key}`)
  }

  function getOptionsIdeaMobile() {
    const status = ideaDetails.statusIdea.code;
    const arrOptions = [optionConstants.GENERAL];

    //qualification so pode ser acessada por admins e nao pode estar aguardando qualficacao  
    if (typeProfile.type !== "colaborador") {
      // if (['AWAITING_QUALIFICATION'].includes(status)) {

        arrOptions.push(optionConstants.QUALIFICATION)
      // }
    }

    //acesso a engagement, nao deve exibir se nao foi publicada 
    if (!['AWAITING_QUALIFICATION', 'DRAFT', 'PATENT_PENDING', 'PENDING_REVIEW'].includes(status)) {
      arrOptions.push(optionConstants.ENGAGEMENT)
    } 


    //ACESSO A CONCLUSION
    if(ideaDetails?.contributors.filter(item => item?._id === userId).length > 0 || ideaDetails.user?._id === userId || typeProfile.type !== 'colaborator') {
      if (!['EXECUTING', 'EXECUTION_QUEUE'].includes(ideaDetails.statusIdea.code)) {
        arrOptions.push(optionConstants.CONCLUSION)
      }
    }

    //ACESSO A RESULTS
    if (ideaDetails.statusIdea.code === 'CONCLUDED') {
      arrOptions.push(optionConstants.RESULTS)
    }

    return arrOptions;
  }

  function handleEditIdea() {
    history.push(`/idea-edit/${ideaDetails._id}`);
  }
  async function handleSendIdea() {
    dispatch(ideaCreateActions.createIdea(ideaCreate, t('sua_ideia_foi_enviada')));
  }
  return (
    <>
      <BackNavAdmin title={loading ? 'carregando...' : ideaDetails.name}>
      {(ideaDetails?.contributors?.filter(item => item?._id === userId).length > 0 || ideaDetails.user?._id === userId || typeProfile.type === 'admin')  && (ideaDetails.statusIdea?.code !== 'CANCELED' && ideaDetails.statusIdea?.code !== 'CONCLUDED' && ideaDetails.statusIdea?.code !== 'UNQUALIFIED'  ) && (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={handleEditIdea}
            disabled={loading || ideaCreate.loading}
        >
          {t('editar_ideia')}
          </Button>
        )}
        {ideaDetails?.statusIdea?.code === 'DRAFT' && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleSendIdea}
            disabled={loading || ideaCreate.loading}
            style={{ marginLeft: 10 }}
          >
            {t('enviar')}
        </Button>
      )}
      </BackNavAdmin>
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={4}>
        <Hidden smDown>
          <Grid item xs={3}>
              {loading 
                ? <SkeletonSidebarIdeaDetails /> 
                : ideaDetails?.statusIdea?.code && <SidebarIdeaPoint page={page} />
              }
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
              { ideaDetails?.statusIdea?.code && (
            <CustomSelect
                  optionsAvaiable={getOptionsIdeaMobile()}
                  handlePage={handleChangePage}
                  startOption={optionConstants.GENERAL}
            />
              )}
          </Grid>
        </Hidden>
        <Grid item xs={12} md={9}>
          { page && (
            <Fragment>
                {loading 
                  ? <SkeletonCardIdeaDetails /> 
                  : page === "general" && ideaDetails._id && <IdeaGeneral />
                }

              { page === "qualification" && ideaDetails._id && (
                <Fragment>
                  {(typeProfile.type === 'admin' || typeProfile.type === 'manager') && 
                  ideaDetails.statusIdea?.code === 'AWAITING_QUALIFICATION' ? (
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <IdeaQualificationTabs />
                      </Grid>
                    </Grid>
                  ) : (
                    <IdeaQualification 
                      idea={ideaDetails} 
                      createdAt={ideaDetails.createdAt}
                      approveDate={ideaDetails.approveDate}
                    />
                  )}
                </Fragment>
              )} 
{/* 
                {loading ? <CircularProgress /> : 
                  page === "execution" && ideaDetails._id && !!projects.length ? 
                  (<LinkedProjects projects={projects} />) : (
                  <IdeaExecution />
                )} */}
                {page === "general-evaluation" && ideaDetails._id && (<>
                  {ideaDetails.personalizedEvaluationGroup !== undefined 
                    ? <IdeaGeneralEvaluationLayout />
                    : <EmptyState icon={emptyStateIcon} />
                  }
                </>)}
                {loading ? <CircularProgress /> : 
                  page === "execution" && ideaDetails._id &&   (
                  <IdeaExecution />
                )}
              { page === "conclusion" && ideaDetails._id && (
                <IdeaConclusion />
              )} 
              { page === "results" && ideaDetails._id && (
                <IdeaResults />
              ) }
              { page === "engagement" && ideaDetails._id && (
                <IdeaEngagement />
              ) }
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Container>
    </>
  )
}
