import React, { useEffect, useState } from 'react';
import { Box, Card, makeStyles, TextField } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { challengeActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AvatarIconWithText } from '../Shared';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 2)
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  }
}));

function ChallengeCreateCard({ name, description, goal }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [descriptionChar, setDescription] = useState('');
  const { t } = useTranslation();
  const [goalChar, setGoal] = useState('');
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (!!name.length && description.length >= 100 && !!goal.length) {
      dispatch(challengeActions.setChallenge({ name, description, goal }));
    };
  }, [name, description, goal, dispatch])

  function handleDescription() {
    const description = getValues().description;
    setDescription(description);
  }

  function handleGoal() {
    const goal = getValues().goal;
    setGoal(goal);
  }

  function setPropChallenge({ target: { value, name } }) {
    dispatch(challengeActions.setPropChallenge(value, name));
  }

  return (
    <Card elevation={0} className={classes.card}>
      <AvatarIconWithText
        text={t('desafio')}
        icon={<DescriptionIcon color="primary" />}
      />
      <Box className={classes.content}>
        <TextField
          name="name"
          id="name"
          label={t('nome_do_desafio')}
          autoComplete="name"
          type="text"
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({
            required: t('titulo_do_desafio_e_obrigatorio'),
          })}
          error={!!errors.name}
          helperText={errors.name && errors.name.message}
          className={classes.marginBetweenInputs}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          defaultValue={name}
          onBlur={setPropChallenge}
        />

        <div className={clsx(classes.about, classes.marginBetweenInputs)}>
          <div className={classes.characters}>
            <span>{descriptionChar.length}/10000 caracteres</span>
          </div>
          <TextField
            error={!!errors.description}
            id="outlined-multiline-static"
            name="description"
            label={t('descricao_do_desafio')}
            multiline
            fullWidth
            minRows={8}
            variant="filled"
            onChange={handleDescription}
            inputRef={register({
              maxLength: 10000,
              minLength: 100,
              required: t('descricao_do_desafio_e_obrigatorio'),
            })}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            defaultValue={description}
            onBlur={setPropChallenge}
          />
          <p className={classes.error}>
            {errors.description && errors.description.message}
            {errors.description &&
              errors.description.type === 'maxLength' &&
              'Você passou o limite de caracteres'}
            {errors.description &&
              errors.description.type === 'minLength' &&
              t('a_quantidade_minima_de_caracteres_da_descricao_e') +
              ' 100'}
          </p>
        </div>

        <div className={clsx(classes.about, classes.marginBetweenInputs)}>
          <div className={classes.characters}>
            <span>{goalChar.length}/10000 caracteres</span>
          </div>
          <TextField
            error={!!errors.goal}
            id="outlined-multiline-static"
            name="goal"
            label={t('objetivos_do_desafio')}
            multiline
            fullWidth
            minRows={8}
            variant="filled"
            onChange={handleGoal}
            inputRef={register({
              maxLength: 10000,
              required: t('objetivos_do_desafio_e_obrigatorio'),
            })}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            defaultValue={goal}
            onBlur={setPropChallenge}
          />
          <p className={classes.error}>
            {errors.goal && errors.goal.message}
            {errors.goal &&
              errors.goal.type === 'maxLength' &&
              t('voce_passou_o_limite_de_caracteres')}
          </p>
        </div>
      </Box>
    </Card>
  );
}

export { ChallengeCreateCard };