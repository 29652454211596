import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    makeStyles,
    Grid,
    Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { pointsTradeService } from '../../services';
import { alertActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 300,
        marginBottom: theme.spacing(2),
        marginTop: 50,
    },
    cardContent: {},
    label: {
        fontSize: 12,
        color: theme.palette.neutrals.low.medium,
        marginBottom: theme.spacing(1),
    },
    value: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
}));

function TradeCard({
    wallet_id,
    userName,
    ideaName,
    pointsTrade,
    points,
    searchText,
    setter,
    premmiarId = false,
    code,
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [statusCard, setStatusCard] = useState('aguardando_integracao');
    const dispatch = useDispatch();

    function handleGetIdeasToTrade() {
        pointsTradeService
            .searchIdeas(searchText)
            .then(({ data }) => {
                setter(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function handleChangeStatus(status) {
        pointsTradeService
            .tradePoints(wallet_id, status)
            .then(({ data }) => {
                dispatch(alertActions.success(data.message));
                handleGetIdeasToTrade();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (premmiarId) {
            setStatusCard('indisponivel');
        } else if (!premmiarId && !pointsTrade) {
            setStatusCard('aguardando_integracao');
        } else if (pointsTrade) {
            setStatusCard('disponivel');
        }
    }, [premmiarId, pointsTrade]);

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('nome')}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            {userName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('nome_da_ideia')}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            {ideaName}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('nome')}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            {userName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('troca_de_pontos')}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            <CheckCircle
                                style={{
                                    fill:
                                        statusCard === 'disponivel'
                                            ? 'green'
                                            : statusCard === 'indisponivel'
                                            ? 'red'
                                            : '#FFC56C',
                                }}
                            />{' '}
                            {t(statusCard)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('pontos').toLocaleLowerCase()}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            {points}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('codigo').toLocaleLowerCase()}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            {code}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('identificacao_premmiar').toLocaleLowerCase()}
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                            {premmiarId}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center">
                    <Button
                        onClick={() => handleChangeStatus(!pointsTrade)}
                        disabled={premmiarId ? true : false}
                        variant="contained"
                        color="primary"
                    >
                        {!premmiarId && !pointsTrade
                            ? t('cancelar')
                            : t('aprovar')}
                    </Button>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default TradeCard;