import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logoImagine from '../../assets/imgs/logo.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    color: theme.palette.neutrals.low.light,
    fontSize: 11,
  },
  copyright: {
    fontWeight: 600,
  },
  logo: {
    width: 200,
    padding:10
  },
  descriptionRegulation: {
    color: theme.palette.neutrals.low.light,
    textDecoration: 'none',
    transition: 'all ease-out .3s',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  helpCenter: {
    color: theme.palette.neutrals.low.light,
    textDecoration: 'none',
    transition: 'all ease-out .3s',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function Copyright() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);

  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box>
        <img src={logoImagine} alt="Logo" className={classes.logo} />
      </Box>
      <Link to="/help-center" className={classes.helpCenter}>
        <Box>{t('central_de_ajuda')}</Box>
      </Link>
      <Link to="/custom-privacy-policy" className={classes.descriptionRegulation}>
        <div>{t('politicas_e_termos_de_privacidade')}</div>
      </Link>

      {user.businessUnitLocal?.businessUnit?.name!=='Dexco Colombia'? (
        <Link to="/regulation" className={classes.descriptionRegulation}>
        <div>{t('politicas_e_termos_de_privacidade_imagine')}</div>
        </Link>
      ) : (
        <>
          <a href="https://imagine-files.imagineinovacao.com.br/dexco/regulamento-es-v1.html" target="_blank" className={classes.descriptionRegulation}> 
            {t('politicas_e_termos_de_privacidade')}
          </a> 
          <br/>
        </>
      )}

      <Box display='flex' justifyContent='center'>
        <a align="center" href="#" onClick={window.showConsentPreferencesPopup} className={classes.descriptionRegulation} >Preferências de Cookie</a>
      </Box>

      <a className={classes.descriptionRegulation} href={"mailto:" + "suporte@imagineinovacao.com.br"}>{t('fale_conosco')} - {t('suporte')}</a>
      <Box className={classes.copyright}>
        Imagine Inovação &copy; {new Date().getFullYear()}
      </Box>

    </Box>
  );
}

export { Copyright };
