import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  Menu,
  Paper,
  Table,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { CustomTooltip } from './CustomTooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 370,
  },
  paper: {
    maxWidth: 500,
    padding: theme.spacing(3)
  },
  iconWithReturn: {
    marginRight: 8,
    color: theme.palette.auxiliary.green.light
  },
  iconNoReturn: {
    marginRight: 8,
    color: theme.palette.auxiliary.red.main
  },
  tableContainer: {
    padding: theme.spacing(0, 2)
  },
  tableHeader: {
    fontSize: 14,
    border: 'none',
    padding: theme.spacing(2, 1),
    background: theme.palette.neutrals.high.main
  },
  tableRow: {
    background: theme.palette.neutrals.high.main
  },
  tableCell: {
    fontSize: 14,
    border: 'none',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 1),
  },
  divider: {
    margin: theme.spacing(2, 0, 1),
    color: theme.palette.neutrals.low.medium
  }
}));

export function PointsRuleTable() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const noReturn = [
    { label: t('qualquer_ambito'), points: 0 },
  ];

  const withReturn = [
    { label: "Até R$ 4.999,99", points: 10 },
    { label: "R$ 5.000 a R$ 9.999,99", points: 100 },
    { label: "R$ 10.000 a R$ 49.999,99", points: 500 },
    { label: "R$ 50.000 a R$ 99.999,99", points: 1000 },
    { label: "R$ 100.000 a R$ 499.999,99", points: 3000 },
    { label: "R$ 500.000 a R$ 999.999,99", points: 5000 },
    { label: "R$ 1.000.000,00 a R$ 4.999.999,99", points: 10000 },
    { label: "Acima de R$ 5.000.000,00", points: 20000 },
  ]

  return(
    <>
      <CustomTooltip title={t('ver_detalhes')} aria-label={t('ver_detalhes')}>
        <IconButton onClick={handleClick} size="small">
          <InfoIcon
            fontSize="inherit"
            color={anchorEl ? 'primary' : 'inherit'}
          />
        </IconButton>
      </CustomTooltip>
      <Menu
        id="menu-information"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        MenuListProps={{ disablePadding: true }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        className={classes.root}
      >
        <Paper className={classes.paper} elevation={0}>
          <Typography> 
            {t('os_pontos_no_imagine_sao_atribuidos_com_base_no_retorno_financeiro_das_ideias_seguindo_os_seguintes_criterios')}
          </Typography>

          <Box display="flex" align="center" paddingTop="1.2rem">
            <MoneyOffIcon className={classes.iconNoReturn} />
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('ideias_sem_retorno_financeiro')} 
            </Typography>
          </Box>

          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="table rule points">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableHeader}>{t('ambito')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('pontos').toLowerCase()}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {noReturn.map((noReturn) => (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {noReturn.label}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {noReturn.points}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider className={classes.divider} />

          <Box display="flex" align="center" paddingTop="1.2rem">
            <AttachMoneyIcon className={classes.iconWithReturn} />
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('ideias_com_retorno_financeiro')}
            </Typography>
          </Box>

          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="table rule points">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableHeader}>{t('retorno')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('pontos').toLowerCase()}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {withReturn.map((withReturn) => (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {withReturn.label}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {withReturn.points}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Menu>
    </>
  );
}