import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Box
} from "@material-ui/core"
import { useSelector } from 'react-redux';
import { DialogResponsive } from './../UserFunction/DialogResponsive';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { ImagineGrid as Grid } from '../../components'
import { useDispatch } from "react-redux"
import { userFunctionActions } from "../../actions"
import { history } from "./../../helpers"

const useStyle = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalWrapper: {
    padding: "2rem",
    justifySelf: "center"
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  warningIcon: {
    color: "#FE9900",
    fontSize: 100,
  },
  button: {
    width: "100%"
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontWeight: 600
  },
  description: {
    textAlign: "center",
    fontSize: 16
  }
}))

export const DialogConfirmEmailRecoverPassword = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dialogConfirmRecoverPassword, adminUserEdit } = useSelector(state => state.userFunction)

  function handleNoButton() {
    dispatch(userFunctionActions.toggleDialogConfirmEmail(false))
  }

  function handleYesButton() {
    dispatch(userFunctionActions.sendRequestNewPassword(adminUserEdit.email))
  }

  return (
    <DialogResponsive open={dialogConfirmRecoverPassword}>
      <DialogTitle></DialogTitle>
      <DialogContent className={classes.content}>
        <Box display={"flex"} flexDirection="column">
        <b className={classes.description}>{t('deseja_redefinir_senha')}</b>
        <p className={classes.description}>
          {t('um_link_de_recuperacao_de_senha_sera_encaminhado_para_o_email_do_usuario')}  
        </p>

        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={4} cols={2}>
          <Grid item>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handleNoButton}
            >
              {t('voltar')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleYesButton}
            >
              {t('redefinir')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogResponsive>
  )
}
