import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import logoFcamara from '../../assets/imgs/logo-fcamara.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
    },
  },
  copyright: {
    fontSize: 12.8,
    color: theme.palette.neutrals.low.medium,
  },
  logo: {
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      width: 200,
    },
  },
}));
function FooterAuthentication() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.footer}>
        <Box className={classes.logo}>
          <img src={logoFcamara} alt="logo fcamara" style={{ width: '200px' }} />
        </Box>
        <Box className={classes.copyright}>
          Imagine {t('inovacao')} &copy; {new Date().getFullYear()}
        </Box>
      </Box>
      <Box display='flex' justifyContent='center'>
        <a align="center" href="#" onClick={window.showConsentPreferencesPopup}>Preferências de Cookie</a>
      </Box>
    </>
  );
}

export { FooterAuthentication };
