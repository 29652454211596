import React, { Fragment, useEffect, useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText"
import Input from "@material-ui/core/Input";
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { useTranslation } from 'react-i18next';
import { makeStyles, TextField, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { ideaActions } from "./../../../actions"
import { useWindowSize  } from "./../../../hooks"
import config from '../../../config';
import { uniqueId } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: theme.palette.grey.dark,
    borderRadius: "4px",
    fontSize: "15px",
    padding: "1rem",
    color: "#343A40"
  },
  filterButtonIcon: {
    color: "#343A40",
    marginRight: "0.5rem",
    fontSize: "20px"
  },
  filterButtonArrow: {
    fontSize: "1rem",
    color: "#343A40",
    marginLeft: "0.5rem"
  },
  filterButtonBadge: {
    padding: "4px 8px",
    fontSize: "10px",
    color: "#343A40",
    borderRadius: "4px",
    backgroundColor: "#CED4DA",
    marginLeft: "0.5rem"
  },
  filterMenu: {
    width: "328px",
    backgroundColor: "#fff",
    padding: "1rem",
  },
  filterMenuHeader: {
    backgroundColor: "#fff",
    padding: "1rem",
    width: "328px",
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
  },
  filterMenuHeaderTitle: {
    color: theme.palette.grey.darkest,
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    width: "100%"
  },
}))

export const MyIdeasFilterMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusCheckList, setStatusCheckList] = useState([]);
  const {
    loading,
    paging,
  } = useSelector((state) => state.idea);
  const [width] = useWindowSize();
  const filters = [
    { checked: false, value: 'SELECT_ALL', label: t('selecionar_todos') },
    { checked: false, value: 'CONCLUDED', label: t('concluida') },
    { checked: false, value: 'CANCELED', label: t('cancelada') },
    {
      checked: false,
      value: 'AWAITING_QUALIFICATION',
      label: t('aguardado_qualificacao'),
    },
    { checked: false, value: 'UNQUALIFIED', label: t('nao_qualificado') },
    { checked: false, value: 'PATENT_PENDING', label: t('pendente_de_patente') },
    { checked: false, value: 'EXECUTION_QUEUE', label: t('fila_de_execucao') },
    { checked: false, value: 'EXECUTING', label: t('execucao') },
    { checked: false, value: 'QUALIFIED', label: t('qualificada') },
    { checked: false, value: 'DRAFT', label: t('rascunho') },
    { checked: false, value: 'PENDING_REVIEW', label: t('solicitar_revisao') }
  ];

  useEffect(() => {
    setStatusCheckList(filters);
  }, []);
  useEffect(() => {
    handleChangeFilter(
      statusCheckList
        .filter(({ checked, value }) => checked === true && value !== 'SELECT_ALL')
        .map(({ value }) => (value))
    );
  }, [statusCheckList]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeFilter(statusList) {
    dispatch(ideaActions.toggleFilter(statusList));
  }

  function handleFilter() {
    dispatch(
      ideaActions.getIdeasUser(
        1,
        paging.sort,
        paging.search,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function handleStatusCheckListChange(details) {
    if (details) {
      const { option } = details;
      const updatedStatus = statusCheckList.map((item) => {
        if (item.value === option.value) {
          return {
            ...item,
            checked: !item.checked
          }
        }
        return item;
    })
      if (option.value === 'SELECT_ALL') {
        setStatusCheckList(statusCheckList.map(item => ({ ...item, checked: option.checked ? false : true })));
      } else {
        setStatusCheckList(updatedStatus);
  }
    } else {
      setStatusCheckList(statusCheckList.map(item => ({ ...item, checked: false })))
    }
  }

  return (
    <Fragment>
      <ButtonBase 
          className={classes.filterButton}
          onClick={handleClick}
        >
          <FilterListIcon className={classes.filterButtonIcon} />
        {t('filtro')}
          <ArrowDropDownIcon className={classes.filterButtonArrow} />
        </ButtonBase>
        <Menu
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box display="flex" flexDirection="column">
            <Box className={classes.filterMenuHeader}>
              <Typography component="p" className={classes.filterMenuHeaderTitle}>filtrar por</Typography>
            </Box>
            <Box className={classes.filterMenu}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                <Autocomplete
                      multiple
                  disableCloseOnSelect
                  id="status-checkbox"
                  options={statusCheckList}
                  loading={statusCheckList.length === 0}
                  getOptionLabel={(input) => input.label}
                  value={statusCheckList.filter(item => item.checked)}
                  onChange={(e, items, options, details) => handleStatusCheckListChange(details)}
                  renderInput={(params) => (
                    <TextField {...params} label="status" variant="filled" />
                  )}
                  renderTags={(option, state) => {
                    return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
                      }}
                  renderOption={(value) => (
                    <li className={classes.flex}>
                          <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={value.checked}
                          />
                      {value.label}
                    </li>
                  )}
                />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Button 
                        variant="contained" 
                        color="primary"
                        startIcon={<FilterListIcon />}
                        onClick={handleFilter}
                        disabled={loading}
                      >
                        filtrar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Menu>
    </Fragment>
  )
}
