import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { challengeActions } from '../../actions';
import { VideoPreview, UploadFile } from '../Upload';
import videoImg from '../../assets/imgs/dialog-video.svg';

export function ChallengeUploadVideo({ name, message, accept, maxSize, isEdit }) {
  const dispatch = useDispatch();
  const { newChallenge: { video } } = useSelector((state) => state.challenge);

  function handleUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    }));

    dispatch(challengeActions.setChallengeVideo(newUploadedFiles[0]));
  };

  return (
    !video ? (
      <UploadFile
        onUpload={handleUpload}
        name={name}
        message={message}
        accept={accept}
        maxSize={maxSize}
      >
        <img src={videoImg} alt={name} />
      </UploadFile>
    ) : (
      <VideoPreview isChallenge={true} video={video} isEdit={isEdit} />
    )
  );
}