import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  FilledInput,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import config from '../../config';
import { userActions } from '../../actions';
import { validation } from '../../helpers';
import { UserSettingsTabs, UserSettingsContent, UserSecurity } from './';
import { SettingsTitle } from './userStyles';
const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
}));

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function UserSettings() {
  const [selectedTab, setSelectedTab] = useState('notifications');
  const [showPassword, setShowPassword] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();

  const { handleSubmit, register, errors, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      notificationAccepted: {
        email: user?.notificationAccepted?.email ? user?.notificationAccepted?.email : false,
        sms: user?.notificationAccepted?.sms ? user?.notificationAccepted?.sms : false,
        _id: user?.notificationAccepted?._id
      },
      notificationTrigger: {
        ideaApproved: user?.notificationTrigger?.ideaApproved
          ? user?.notificationTrigger?.ideaApproved
          : false,
        ideaComment: user?.notificationTrigger?.ideaComment
          ? user?.notificationTrigger?.ideaComment
          : false,
        ideaUpped: user?.notificationTrigger?.ideaUpped
          ? user?.notificationTrigger?.ideaUpped
          : false,
        ideaConcluded: user?.notificationTrigger?.ideaConcluded
          ? user?.notificationTrigger?.ideaConcluded
          : false,
        creditedPointIdea: user?.notificationTrigger?.creditedPointIdea
          ? user?.notificationTrigger?.creditedPointIdea
          : false,
        finishExecutionPending: user?.notificationTrigger?.finishExecutionPending
          ? user?.notificationTrigger?.finishExecutionPending
          : false,
        awaitingQualificationIdeas: user?.notificationTrigger?.awaitingQualificationIdeas
          ? user?.notificationTrigger?.awaitingQualificationIdeas
          : false,
        _id: user?.notificationTrigger?._id,
      },
    },
  });

  function handleClickTogglePassword() {
    setShowPassword(!showPassword);
  }

  function onSubmit(data) {
    if (data.password) {
      user.newPassword = data.password;
      user.password = data.password;
      user.confirmPassword = data.confirmPassword;
    }

    const notificationAccepted = {
      ...user.notificationAccepted,
      ...data.notificationAccepted,
    };

    const notificationTrigger = {
      ...user.notificationTrigger,
      ...data.notificationTrigger,
    };

    user.notificationAccepted = notificationAccepted;
    user.notificationTrigger = notificationTrigger;

    dispatch(userActions.updateUser(user, t('configuracoes_de_conta_salvas')));
  }


  function handleProfileType(perfil) {
    switch (capitalizeFirstLetter(perfil)) {
      case "Administrador":
        return []

      case "Colaborador":
        return [{
          label: 'pontos_creditados_pela_conclusao_da_ideia',
          name: 'notificationTrigger.creditedPointIdea'
        }]


      case "Gestor":
        return [{
          label: 'ideias_que_estao_em_execucao',
          name: 'notificationTrigger.finishExecutionPending'
        },
        {
          label: 'ideias_aguardando_qualificacao',
          name: 'notificationTrigger.awaitingQualificationIdeas'
        }]

      default:
        return []
    }
  }

  return (
    <>
        {/* prop office365 fake */}
        {user.office365 && (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant="subtitle1"
              className={clsx(classes.title, classes.marginBetweenInputs)}
            >
              {t('alterar_senha')}
            </Typography>
            <div
              className={classes.marginBetweenInputs}
              style={{ fontSize: '1rem' }}
            >
              {t('informe_uma_senha_exclusiva_para_proteger_a_sua_conta')}.
            </div>
            <FormControl
              variant="filled"
              error={!!errors.password}
              className={classes.marginBetweenInputs}
            >
              <InputLabel htmlFor="password">{t('senha')}</InputLabel>
              <FilledInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                inputRef={register({
                  validate: {
                    goodPassword: (value) =>
                      validation.goodPassword(value) ||
                      t('senha_invalida_verifique_as_instrucoes_abaixo'),
                  },
                })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickTogglePassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                disableUnderline
              />
              <FormHelperText id="helper-password">
                {errors.password && errors.password.message}
              </FormHelperText>
            </FormControl>
            <div>
              <div style={{ color: theme.palette.neutrals.low.medium, fontWeight: 600 }}>
                {t('sua_senha_deve_possuir')}:
              </div>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                <li>
                  - {t('no_minimo')} 8 {t('caracteres')}
                </li>
                <li>
                  - {t('possuir')} 1 {t('caractere_especial')}
                </li>
              </ul>
            </div>{' '}
            *
        </form>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <SettingsTitle variant="subtitle1">
            {t('configuracoes_de_conta')}
          </SettingsTitle>
          </Grid>          
        {!config.SHOW_OFFICE365_FORM && !config.SHOW_GOOGLE_FORM && (
          <Grid item xs={12}>
            <UserSettingsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          </Grid>
        )}
        <Grid item xs={12}>
          {selectedTab === 'notifications' && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <UserSettingsContent
                register={register}
                control={control}
                handleProfileType={handleProfileType}
              />
            </form>
          )}
          { !config.SHOW_OFFICE365_FORM &&
            !config.SHOW_GOOGLE_FORM &&
            selectedTab === 'security' && (
              <UserSecurity />
          )}
          </Grid>
        </Grid>
    </>
  );
}

export { UserSettings };
