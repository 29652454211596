export const reportConstants = {
  LOAD_LOCAL: "LOAD_LOCAL",
  CHANGE_AREA: "CHANGE_AREA",
  CHANGE_STATUS: "CHANGE_STATUS",
  CHANGE_PERSONALIZED_CLASSIFICATIONS: "CHANGE_PERSONALIZED_CLASSIFICATIONS",
  CHANGE_PROPERTIES: "CHANGE_PROPERTIES",
  CHANGE_LOCAL: "CHANGE_LOCAL",
  LOAD_STATUS: "LOAD_STATUS",
  LOAD_PERSONALIZED_CLASSIFICATIONS: "LOAD_PERSONALIZED_CLASSIFICATIONS",
  LOAD_PROPERTIES: "LOAD_PROPERTIES",
  CHANGE_START_DATE: "CHANGE_START_DATE",
  CHANGE_END_DATE: "CHANGE_END_DATE",
  CHANGE_BUL_EMPTY_CHECKED: "CHANGE_BUL_EMPTY_CHECKED",
  REPORT_DATA_REQUEST: "REPORT_DATA_REQUEST",
  REPORT_DATA_SUCCESS: "REPORT_DATA_SUCCESS",
  REPORT_DATA_CHART_SUCCESS: 'REPORT_DATA_CHART_SUCCESS',
  REPORT_DATA_FAILURE: "REPORT_DATA_FAILURE",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  CLEAR_LOCAL: "CLEAR_LOCAL",
  CLEAR_AREA: "CLEAR_AREA",
  CLEAR_STATUS: "CLEAR_STATUS",
  CLEAR_PERSONALIZED_CLASSIFICATIONS: "CLEAR_PERSONALIZED_CLASSIFICATIONS",
  CLEAR_ALL_REPORT: "CLEAR_ALL_REPORT",
  GET_REPORT_CSV_REQUEST: "GET_REPORT_CSV_REQUEST",
  GET_REPORT_CSV_SUCCESS: "GET_REPORT_CSV_SUCCESS",
  GET_REPORT_CSV_FAILURE: "GET_REPORT_CSV_FAILURE",
  SER_REPORT_TAB: "SER_REPORT_TAB",
  PRE_REPORT_DATA_REQUEST: "PRE_REPORT_DATA_REQUEST",
  CHANGE_REPORT_SEARCH: "CHANGE_REPORT_SEARCH",
  CLEAR_REPORT_SEARCH: "CLEAR_REPORT_SEARCH",
  REPORTS_LOAD_CHALLENGE: 'REPORTS_LOAD_CHALLENGE',
  REPORTS_CHANGE_CHALLENGE: 'REPORTS_CHANGE_CHALLENGE',
  REPORTS_CLEAR_CHALLENGE: 'REPORTS_CLEAR_CHALLENGE',
  SHOW_IDEAS_UNGROUPED: 'SHOW_IDEAS_UNGROUPED',
  SHOW_IDEA_DESCRIPTION_ON_CSV: 'SHOW_IDEA_DESCRIPTION_ON_CSV',
  USE_CONCLUSION_DATE: 'USE_CONCLUSION_DATE',
  SHOW_HIDDEN_IDEAS_IN_FEED: 'SHOW_HIDDEN_IDEAS_IN_FEED',
}