import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../../hooks';
import config from '../../../config';
import {
  ideaCreateActions,
  multiAreaCascadeActions,
  ideaCreateDialogsActions,
} from '../../../actions';

export function useCreateIdeaController() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const [loadingCreateIdea, setLoadingCreateIdea] = useState(false);
  const ideaCreate = useSelector((state) => state.ideaCreate);
  const { fields } = useSelector(state => state.multiAreaCascade);
  const { dialogSendIdea, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const { personalizedClassification } = useSelector((state) => state.personalizedClassification);
  const isSendIdeaDialog = fromHistory[fromHistory.length -1] === 'sendIdea';

  async function createIdea() {
    setLoadingCreateIdea(true);

    if (fields.map(field => field.selectedBusinessUnit).length === 0 || !ideaCreate.challenge) {
      return;
    }

    if (personalizedClassification.map(item => item.selectedClassification).length === 0 || !ideaCreate.personalizedClassifications) {
      return;
    }

    if (ideaCreate?.challenge?.notChallenge) {
      delete ideaCreate.challenge;
    }

    ideaCreate.businessUnitLocal = fields.map(field => field.selectedBusinessUnit);

    await dispatch(ideaCreateActions.createIdea(ideaCreate, t('sua_ideia_foi_enviada')));

    setLoadingCreateIdea(false);

    dispatch(ideaCreateDialogsActions.close());
    dispatch(ideaCreateActions.clearAll());
    dispatch(ideaCreateActions.clearClassification());
  }

  async function createDraft() {
    setLoadingCreateIdea(true);

    await dispatch(ideaCreateActions.createDraft(ideaCreate, t('ideia_salva_em_rascunhos')));
    setLoadingCreateIdea(false);
    
    dispatch(ideaCreateActions.clearAll());
    dispatch(ideaCreateActions.clearClassification());
  }

  function redirectToEditTitle() {
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditArea() {
    dispatch(ideaCreateDialogsActions.openDialogMultiArea(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditChallenge() {
    dispatch(ideaCreateDialogsActions.openDialogChallenge(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditClassification() {
    dispatch(ideaCreateDialogsActions.openDialogClassification(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditRadical() {
    dispatch(ideaCreateDialogsActions.openDialogRadical(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditAmbit() {
    dispatch(ideaCreateDialogsActions.openDialogAmbit(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditLinks() {
    dispatch(ideaCreateDialogsActions.openDialogLink(!isSendIdeaDialog && 'sendIdea'));
  }
  
  function redirectToEditPhoto() {
    dispatch(ideaCreateDialogsActions.openDialogPhoto(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditVideo() {
    dispatch(ideaCreateDialogsActions.openDialogVideo(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditFile() {
    dispatch(ideaCreateDialogsActions.openDialogFile(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToReviewIdea() {
    dispatch(ideaCreateDialogsActions.openDialogSendIdea());
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog());
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
    dispatch(ideaCreateActions.clearAll());
    dispatch(ideaCreateActions.clearClassification());
  }
  
  return {
    ideaCreate,
    dialogSendIdea,
    loading: ideaCreate.loading,
    isSendIdeaDialog,
    loadingCreateIdea,
    createIdea,
    createDraft,
    redirectToEditTitle,
    redirectToEditArea,
    redirectToEditChallenge,
    redirectToEditClassification,
    redirectToEditPhoto,
    redirectToEditLinks,
    redirectToEditFile,
    redirectToEditVideo,
    redirectToReviewIdea,
    redirectToEditRadical,
    redirectToEditAmbit,
    previous,
    close
  }
}