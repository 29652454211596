import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogContent,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import { ideaCreateActions, ideaCreateDialogsActions } from '../../actions';
import { DialogCustomTitle } from './DialogCustomTitle';
import { DialogCreateIdea } from './DialogCreateIdea';
import { CreateIdeaActions } from './CreateIdeaActions';
import { dialogCss } from './dialogCss';
import { useCreateIdeaController } from './hooks';

function DialogAmbit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();  
  const dialogClasses = dialogCss();
  const { handleSubmit, register } = useForm();
  const { suggestionsRegarding } = useSelector((state) => state.domains);
  const { selectedSuggestion } = useSelector((state) => state.ideaCreate);
  const { dialogAmbit } = useSelector((state) => state.ideaCreateDialogs);
  const { previous, close } = useCreateIdeaController();

  function onSubmit() {
    dispatch(ideaCreateDialogsActions.openDialogChallenge('DIALOG_AMBIT'));
  }

  function selectedAmbit(suggestionsRegarding) {
    dispatch(ideaCreateActions.setAmbit(suggestionsRegarding));
  }

  return (
    <DialogCreateIdea open={dialogAmbit}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('onde_sua_ideia_vai_impactar')}
      </DialogCustomTitle>
      <DialogContent dividers className={dialogClasses.dividerBottom}>
        <FormControl fullWidth>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('selecione_o_principal_ambito_que_sera_impactado_pela_sua_ideia')}.
          </FormLabel>
          <RadioGroup aria-label="suggestions" name="suggestions">
            {suggestionsRegarding && suggestionsRegarding.map((suggestions) => (
              <FormControlLabel
                key={suggestions._id}
                value={suggestions._id}
                className={dialogClasses.label}
                control={<Radio color="secondary" />}
                onChange={() => selectedAmbit(suggestions)}
                label={suggestions.name}
                inputRef={register}
                checked={selectedSuggestion?._id === suggestions?._id}
              />
            ))
            }
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <CreateIdeaActions name="DIALOG_AMBIT" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}

export { DialogAmbit };