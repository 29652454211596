import React, { useEffect } from 'react';
import { makeStyles, Container, Grid, Hidden } from '@material-ui/core';
import {
  BackNavAdmin,
  WalletTable,
  WalletPoints,
  WalletFilters,
  Loading,
  WalletMobileFilter,
  WalletCard,
  Search,
  SkeletonTable,
} from '../../components';
import { walletActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowSize } from '../../hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../../config';
import { useTranslation } from 'react-i18next';
// import {debounce} from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  filter: {
    width: '100%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

function WalletPage() {
  const { _id } = useSelector((state) => state.user);
  const [width] = useWindowSize();
  const {
    paging,
    paging: { quantityPerPage, filter, sort, query },
    wallets,
    points,
    loading
  } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (wallets.length < 1) {
      dispatch(
        walletActions.getWallets(_id, 1, quantityPerPage, filter, sort, query)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!points) {
      dispatch(walletActions.getPoints());
    }
  }, []);

  function loadMoreWallets() {
    if (!loading) {
      var newPage = paging.page === 1 ? 2 : paging.page;
      dispatch(
        walletActions.getWalletsMobile(
          _id,
          newPage,
          quantityPerPage,
          filter,
          sort,
          query
        )
      );
    }
  }

  function renderTable() {
    if (wallets.length > 0 && _id) {
      return <WalletTable wallets={wallets} paging={paging} id={_id} />;
    } else {
      return <SkeletonTable cols={4} />
    }

  }

  function searchWallet(q) {
    dispatch(
      walletActions.getWallets(_id, 1, quantityPerPage, filter, sort, q)
    );
    dispatch(walletActions.setQuery(q));
  }

  function handleChangeView(e) {
    var newFilter = { ...filter };
    newFilter.classification = e.target.value;
    dispatch(
      walletActions.getWallets(_id, 1, quantityPerPage, newFilter, sort),
      query
    );
    dispatch(walletActions.setStatus(e.target.value));
  }

  function handleChangePeriod(e) {
    var newFilter = { ...filter };
    newFilter.period = e.target.value;
    dispatch(
      walletActions.getWallets(_id, 1, quantityPerPage, newFilter, sort, query)
    );
    dispatch(walletActions.setPeriod(e.target.value));
  }

  function renderCards() {
    if (wallets.length > 0) {
      return (
        <InfiniteScroll
          dataLength={wallets.length}
          next={loadMoreWallets}
          hasMore={true}
          scrollThreshold={'90%'}
          loader={
            loading && (
              <div className={classes.containerLoading}>
                <Loading />
              </div>
            )
          }
        >
          {wallets.map((wallet, index) => (
            <WalletCard key={index} wallet={wallet} />
          ))}
        </InfiniteScroll>
      );
    }

    return null;
  }

  return (
    <>
      <BackNavAdmin title={t('meus_pontos')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <WalletPoints points={points} />
            <Hidden smDown>
              {_id && (
                <WalletFilters id={_id} paging={paging} search={searchWallet} />
              )}
            </Hidden>
            <Hidden smUp>
              <div className={classes.filter} style={{ paddingTop: 32 }}>
                <WalletMobileFilter
                  period={filter.period}
                  classification={filter.classification}
                  onHandlePeriod={handleChangePeriod}
                  onHandleClassification={handleChangeView}
                />
                <Search
                  query={searchWallet}
                  placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
                />
              </div>
            </Hidden>
            {width > config.RESPONSIVE_MOBILE ? renderTable() : renderCards()}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { WalletPage };
