import React from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.neutrals.high.light
  },
  title: {
    padding: theme.spacing(4, 0),
    color: theme.palette.neutrals.low.main
  },
  subTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  description: {
    fontSize: 15,
    lineHeight: 1.8,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  link: {
    transition: '0.5s ease-in-out',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.neutrals.low.main
    }
  }
}));

export function PrivacyPolicyPage() {
  const classes = useStyles();
  
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" className={classes.title}>
        Política de Privacidade Dexco
      </Typography>
      
      <Typography variant="h6" className={classes.subTitle}>
        PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS NO IMAGINE
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Os sites da DEXCO conectam você às diversas soluções em suas linhas de negócio tais como Duratex Madeira, Durafloor, Ceusa, Portinari, DEXperience, Hydra, Castelatto, Deca e Revestimentos Cerâmicos e projetos de sustentabilidade e inovação desenvolvidos pelas marcas DEXCO.
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Ao interagir conosco online alguns dados pessoais seus podem ser coletados ou fornecidos por você. Esta Política de Privacidade faz parte do nosso compromisso de transparência e de uso ético dos dados pessoais: aqui você encontra os detalhes sobre como utilizamos seus dados pessoais.
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Esta política traz ainda os seus direitos como titular de dados e dispõe de um Sistema de Gestão de Privacidade [SGP] próprio, facilitado e gratuito, por onde administra todas as permissões, solicitações tratadas e finalidades para coleta e gestão de dados pessoais de nossos clientes, bem como todos os assuntos descritos abaixo, tal como nossas Políticas de Cookies.
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        DEXCO S.A. 
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Endereço: Av. Paulista – 1938 – Bela Vista <br />
        São Paulo – SP CEP: 01310-942 <br />
        Telefone: (11) 3179-7733 <br />
        CNPJ: 97.837.181/0001-47 <br />
        Inscrição Estadual: 114.578.650.114 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        DADOS PESSOAIS NO IMAGINE
      </Typography>
  
      <Typography variant="body1" className={classes.description}>
        Vide abaixo para saber mais sobre a utilização de dados pessoais em nossos sites.
      </Typography>

      <Box className={classes.box}>
        <Typography variant="h6" className={classes.subTitle}>
          DEXCO
        </Typography>
        <Typography variant="body1" className={classes.description}>
          https://imagine.dex.co
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Quais dados pessoais são utilizados? 
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Matrícula, e-mail, senha [CPF], nome completo, data de nascimento, imagem, telefone, unidade de negócio, local, área, função, biografia, foto, pontuação, celular, operadora do celular, endereço de entrega, CEP, rua, número, complemento, ponto de referência, bairro, cidade, estado, destinatário e dados de cartão de crédito de colaboradores Dexco
        </Typography>       
        <Typography variant="h6" className={classes.subTitle}>
          Para que utilizamos seus dados pessoais?
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Você pode nos fornecer os dados pessoais mencionados para pré-cadastro, fazer login e editar os dados em seu cadastro, permitindo que: tenha acesso à plataforma, consiga fazer login, bem como editar seus dados pessoais, para envio de ideias, sugestões e melhorias nos processos da empresa, passando pela avaliação do gestor responsável, e possibilitando o recebimento de pontos via plataforma Imagine, com posterior resgate de prêmios, para identificação do participante no ranking de pontuações e
para o resgate de prêmios. 
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Com quem compartilhamos seus dados pessoais? 
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
- Amazon Web Services e Azzure [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
- Braspag: Meio de pagamento, bem como para realização do débito no cartão de crédito do colaborador, caso queira complementar a pontuação para o resgate;<br/>
- FCamara: Acesso e fornecimento de informações e relatórios à Área de Negócio Dexco responsável pelo processo;<br/>
- Parceiros Premmiar: Entrega e emissão de nota fiscal. Cada parceiro possui uma regra específica sendo que, maiores esclarecimentos podem ser encontrados na Política de Privacidade da empresa Premmiar, responsável pelo respectivo compartilhamento. Poderão ser compartilhados maior ou menor quantidade de dados pessoais, a depender do parceiro;<br/>
- Premmiar: Controle da pontuação e recarga de créditos para que o colaborador consiga resgatar os prêmios a que tiver direito; e<br/>
- Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
 
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Fundamento legal:
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Consentimento
        </Typography>
      </Box>
 
 

      <Typography variant="h6" className={classes.subTitle}>
        Conservação e Eliminação de dados
      </Typography>
      <Typography variant="body1" className={classes.description}>
Manteremos seus dados pessoais armazenados enquanto for necessário, para prestar os serviços e para fins legítimos e essenciais, tais como para manter o correto funcionamento e rastreabilidade das informações, tomar decisões empresariais acerca de funcionalidades e mailing com base em dados, cumprir os fins legais, e resolver disputas. Conservamos alguns dos seus dados pessoais enquanto permanecer ativo no portal.
      </Typography>
      <Typography variant="body1" className={classes.description}>
Quando solicitado, eliminaremos os seus dados pessoais de modo que não o identifiquem, exceto se for legalmente permitido ou obrigatório manter determinados dados pessoais, incluindo situações como as seguintes:
      </Typography>
      <Typography variant="body1" className={classes.description}>
        •	Se formos obrigados a manter os dados pessoais para as nossas obrigações jurídicas, fiscais, de auditoria e contabilidade, iremos reter os seus dados pessoais necessários pelo período exigido pela legislação aplicável;
 <br />
        •	Sempre que necessário para os nossos legítimos interesses comerciais, como a prevenção contra fraudes ou para manter a segurança do nosso ambiente e demais usuários do portal/site. 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Registro de acesso aos sites e aplicativos do Imagine
      </Typography>
      <Typography variant="body1" className={classes.description}>
        A DEXCO coleta e armazena pelo período necessário os dados de acesso aos seus sites de aplicativos [data, hora e IP] para cumprimento de obrigação legal imposta pelo Marco Civil da Internet [art. 15, Lei nº 12.965/2014].
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Cookies 
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você visita o Site. A DEXCO utiliza cookies para facilitar o uso e melhor adaptar seus sites e aplicativos aos interesses e necessidades de seus usuários. 
      </Typography>

      {/*  */}
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Tipos de Cookies
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" className={classes.subTitle}>
            O que eles fazem?
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Necessários 
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são essenciais para que os sites e aplicativos carreguem corretamente e permitem que você navegue e faça uso de todas as funcionalidades disponíveis.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Desempenho  
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies nos ajudam a entender como os visitantes interagem com sites e aplicativos, fornecendo informações sobre as áreas visitadas, o tempo de visita e quaisquer problemas encontrados, como mensagens de erro.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Funcionais  
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
Esses cookies permitem que sites e aplicativos se lembrem das escolhas dos usuários, para proporcionar uma experiência mais personalizada. Também, possibilitam que os Usuários utilizem a integração com redes sociais, campos para comentários, entre outros.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Marketing   
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
           Esses cookies são utilizados para fornecer mais conteúdo relevante e do interesse dos usuários. Podem ser utilizados para apresentar publicidade direcionada ou limitar sua veiculação.
        </Typography>
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.description}>
        A qualquer momento, você poderá rever a forma de utilização dos cookies, utilizando para tanto 
        as configurações de seu navegador de preferência. Para mais informações sobre como proceder em 
        relação à gestão dos cookies nos navegadores, disponibilizamos os links abaixo:  
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Internet Explorer: {' '}
        <a 
          href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies" 
          target="_blank" 
          className={classes.link}
        >
          https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
        </a> <br />
        Mozilla Firefox: {' '}
        <a 
          href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam" 
          target="_blank" 
          className={classes.link}
        >
          https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
        </a> <br />
        Google Chrome: {' '}
        <a 
          href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR" 
          target="_blank" 
          className={classes.link}
        >
          https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR 
        </a> <br />
        Safari: {' '}
        <a 
          href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" 
          target="_blank" 
          className={classes.link}
        >
          https://support.apple.com/pt-br/guide/safari/sfri11471/mac
        </a> <br />
        Caso você não permita a utilização de alguns cookies, certos serviços poderão não funcionar de maneira ideal [por exemplo, a utilização de serviços que requerem o login].    
      <br/>

      </Typography>
      <Typography variant="body1" className={classes.description}>
Abaixo está a lista dos principais cookies que utilizamos:

      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Tipos de Cookies
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" className={classes.subTitle}>
           Objetivo e finalidade
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies analíticos
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são usados para estimar público visitante, identificar padrões de uso e acelerar as pesquisas.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies do Google Analytics
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são usados para coletar informações sobre como os visitantes usam os sites da Dexco. A Dexco e as empresas de seu conglomerado econômico usam as informações para compilar relatórios e para ajudar a melhorar o site. Os cookies coletam informações de forma anônima, incluindo o número de visitantes do site, de onde os visitantes vieram até o site e as páginas visitadas.
<br/>
O Google armazena as informações coletadas pelo cookie em servidores nos Estados Unidos. O Google também pode transferir essas informações para terceiros quando exigido por lei ou quando tais terceiros processarem as informações em nome do Google. O Google não associará seu endereço IP a nenhum outro dado mantido pelo Google. 
<br/>

Clique aqui para uma visão geral da privacidade no Google.

          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies de sessão
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Cookies de sessão	Esses cookies são usados para manter sua transação. Geralmente seus cookies de sessão expiram quando seu navegador é fechado. O uso dos cookies de sessão reduz a necessidade de a Dexco transferir suas informações pela Internet.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Web Beacons
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Os Web beacons são arquivos de imagem nítidos [arquivos .gif transparentes] que permitem à Dexco rastrear as interações do usuário. A Dexco pode usar web beacons em mensagens de e-mail ou newsletters para determinar se as mensagens foram abertas ou se os links foram acessados. Eles também permitem rastrear as ações do cliente nos websites para adaptar os serviços da Dexco a você. O conteúdo personalizado é influenciado por sua interação com a Dexco, incluindo as páginas visualizadas, as ofertas pelas quais você demonstra interesse e as respostas fornecidas para as perguntas da pesquisa
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies de funcionalidade
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são usados para reconhecer visitantes repetidos no site e permitir que o site se lembre das escolhas que você fez [como nome de usuário, idioma ou a região em que você está]. Eles costumam ser usados para registrar informações específicas de navegação [incluindo a maneira como você chega ao site, as páginas que você visualiza, as opções selecionadas, as informações inseridas e o caminho que você percorre no site] em relação a um perfil de cliente individual.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies de preferência do país
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são usados para registrar sua preferência de país. Eles permitem que a página do país selecionado seja apresentada ao visitante sem a reinserção da preferência do país durante cada visita ao site.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies de preferência de idioma
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Esses cookies são usados para gravar o idioma preferido. Eles permitem que o idioma preferido seja exibido para o visitante sem a reinserção da preferência de idioma durante cada visita ao site.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies do Sistema de Gerenciamento de Conteúdo
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Indicam a maneira como o site está sendo visualizado [Live Site / Design Mode, etc.]
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Gerenciamento de Autenticação
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Contém suas informações de ticket de autenticação.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies de Terceiros 
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Também desenvolvemos relacionamentos com parceiros cuidadosamente selecionados e monitorados para ajudar na entrega de um site de qualidade. Alguns desses parceiros podem definir cookies durante a sua visita para acompanhar o desempenho de campanhas de marketing e/ou cumprir obrigações contratuais com a Dexco. Embora esses cookies não armazenem nenhum dado pessoal relacionado a você, a Dexco não tem acesso ou controle sobre os cookies e tecnologias semelhantes que os seus parceiros usam.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Cookies de rastreamento
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
          Os cookies são usados por esses parceiros para acompanhar o desempenho de campanhas de marketing de recrutamento.
          </Typography>
        </Grid>
      </Grid>
</Typography>
<hr/>
      <br/>

      <br/>

      <Typography variant="h6" className={classes.subTitle}>
        Compartilhamento de dados pessoais 
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Além dos aspectos detalhados anteriormente, a DEXCO pode compartilhar dados pessoais com parceiros comerciais para a prestação dos serviços oferecidos por meio de seus sites e aplicativos, com autoridades e demais interessados, para proteção de seus interesses em qualquer tipo de conflito, incluindo ações judiciais; mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para esta requisição. Todos os parceiros da DEXCO garantem adotar medidas de segurança técnicas e administrativas para proteção dos dados pessoais. 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Segurança da informação e dos dados pessoais
      </Typography>
      <Typography variant="body1" className={classes.description}>
A DEXCO adota medidas de segurança para manter a confidencialidade, a integridade e a disponibilidade das informações e dos dados pessoais em seus sites e aplicativos, incluindo as diretrizes sobre padrões de segurança estabelecidas no Decreto Regulamentador do Marco Civil da Internet [Decreto nº 8.771/2016], tais como métodos padrão e de mercado para criptografar os dados coletados; proteção contra acesso não autorizado a seus sistemas; e compromisso de confidencialidade de todos os que tratam dados pessoais em nome da DEXCO.    
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Apesar de todas as medidas de segurança adotadas, é importante relembrar que nenhum site ou aplicativo é totalmente seguro. Por isso, incentivamos que você também tome medidas de segurança para proteção de seus dados, como por exemplo, manter seus logins e senha confidenciais. 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Seus direitos
      </Typography>
      <Typography variant="body1" className={classes.description}>
As leis brasileiras de privacidade e proteção de dados pessoais asseguram a você, titular de dados pessoais, uma série de garantias que o colocam no controle das informações que você fornece a nós. Como modo de informá-lo sobre os seus Direitos e sobre como exercê-los, apresentamos a seguir tudo aquilo que se aplica à Você.
<br/>        

A Dexco conta com profissionais treinados para atender às suas dúvidas e requisições. Para exercer esses direitos, você pode solicitá-los à Dexco, acessando no rodapé da página inicial de seus sites o link “Política de Privacidade”. Vá até a seção Seus Direitos, e clique no link {' '}
        <a 
          href="https://privacy-central.securiti.ai/#/dsr/c261c14c-8c17-4e1c-96ab-7029d8b2b485" 
          target="_blank" 
          className={classes.link}
        >
           Portal de Abertura de Solicitações de Titulares (DSR):
        </a>    


<br/>
Importante ressaltar, que pais, mães ou responsáveis legais poderão exercer os direitos em nome de crianças ou de adolescentes, nos termos da legislação aplicável.
<br/>
Conforme previsto na LGPD, você pode exercer os seguintes direitos com relação aos seus dados pessoais:
<br/>
•	Confirmação da existência de tratamento: este direito permite que você possa requisitar e receber uma confirmação sobre a existência de coleta, armazenamento, compartilhamento ou qualquer tipo de tratamento a partir de seus dados pessoais;
<br/>
•	Acesso aos dados: este direito permite que você requisite e receba uma cópia gratuita e em formato acessível dos dados pessoais tratados por nós;
<br/>
•	Correção de dados quando incompletos, inexatos ou desatualizados: caso você identifique alguma incorreção ou imprecisão nos seus dados pessoais que tratamos, poderá requerer que os retifiquemos;
<br/>
•	Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei: este direito permite que seja requerida a anonimização, o bloqueio ou a eliminação dos dados pessoais da nossa base. Todos os dados coletados serão anonimizados, bloqueados ou eliminados dos nossos servidores e arquivos físicos quando assim for possível e requisitado por você e, em conformidade também com o atendimento a outras legislações vigentes;
<br/>
•	Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial: a Dexco permite a você ou a terceiro nomeado, obter acesso aos dados pessoais que são tratados por nós em formato estruturado e interoperável;
<br/>
•	Eliminação dos dados tratados com consentimento do titular, exceto nas hipóteses de guarda legal e outras dispostas em Lei: caso você nos tenha fornecido o seu consentimento para uma finalidade de tratamento, você ainda pode requisitar a eliminação dos dados pessoais que armazenamos até então; 
<br/>

•	Obtenção de informações sobre as entidades públicas e privadas com as quais a DEXCO compartilhou seus dados;

<br/>
•	Peticionar em relação aos seus dados contra o controlador perante a Autoridade Nacional de Proteção de Dados;
<br/>
•	Revogação do consentimento: você tem o direito de revogar o seu consentimento ou, se for o caso, de não nos fornecê-lo e ser informado sobre as consequências da negativa. Nestes casos, o não consentimento não acarretará prejuízo na utilização de produtos ou serviços da Dexco;
<br/>
•	Revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade: você tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado dos dados pessoais pela Dexco e de receber informações claras e adequadas a respeito dos critérios utilizados na decisão, desde que essas informações não violem qualquer segredo comercial da Dexco; e
<br/>
•	Oposição ao tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento e em desconformidade com a lei: você também tem o direito de se opor a determinadas finalidades de tratamento de dados pessoais, como, por exemplo, o envio de publicidade, newsletter ou novas ofertas.
<br/>

<br/>
No caso do site “Jeito de Ser e Fazer Dexco”, em casos de colaboradores DEXCO Colômbia, os direitos podem ser solicitados diretamente ao endereço de e-mail: datospersonales@dex.co.


      </Typography>

      <Typography variant="body1" className={classes.description}>
Ainda, te informamos a seguir como atuamos quando recebermos a sua requisição. Em primeiro lugar, nosso time de Privacidade irá percorrer algumas etapas antes de analisar sua solicitação. Essas etapas incluem um cadastro inicial do titular de dados, uma etapa de validação de canal de comunicação e uma etapa de validação de identidade do titular. 

<br/>
Essas etapas visam garantir que:
<br/>
•	Exista um meio válido para comunicação entre você e a Dexco;
<br/>
•	O solicitante é realmente quem diz ser, prevenindo que seus dados não sejam divulgados a qualquer pessoa que não tenha legitimidade para recebê-los e;
<br/>
•	A Dexco tenha em mãos os dados corretos para realizar a pesquisa interna a seu respeito.
<br/>
Percorridas as etapas iniciais acima, a Dexco irá protocolar e analisar sua solicitação e poderá responder-lhe de duas maneiras, determinando que: 
<br/>
•	Seu pedido é lícito e legítimo, devendo, portanto, ser acatado, ou;
<br/>
•	Seu pedido foi negado ou parcialmente aceito e, por determinadas razões, não poderá ser acatado ou será acatado parcialmente. 
<br/>
<br/>
Não se preocupe, pois, mesmo em caso de negativa, iremos informar a você os motivos pelos quais seu pedido não foi aprovado e todas as nossas respostas serão sempre enviadas pelo mesmo meio pelo qual você nos contatou. 
<br/>
Essas medidas são necessárias considerando a nossa preocupação com as informações que tratamos, sobretudo, as informações sobre crianças e adolescentes.
<br/>
Caso seu pedido seja aceito, faremos o possível para contatar os nossos fornecedores e parceiros comerciais que podem ter acesso aos seus Dados Pessoais para que eles também retifiquem, eliminem ou exerçam qualquer outro Direito que você requisitou. No entanto, infelizmente não podemos garantir o real exercício desses direitos por esses Terceiros, que utilizam, divulgam e protegem os dados pessoais de acordo com suas respectivas políticas.
<br/>
Poderemos armazenar e manter, em forma de registro, um histórico das requisições de direitos que você fez para que possamos, se necessário, apresentá-lo às autoridades competentes como prova de que respondemos os pedidos em tempo hábil e da maneira adequada, conforme a legislação estabelece.
<br/>
Recebida a sua requisição, o nosso time retornará a você uma resposta dentro do prazo legal, a partir do recebimento da requisição. 
<br/>
Caso sejam necessários esclarecimentos ou mais informações sobre o seu pedido, podemos enviar a você alguns questionamentos para que possamos responder à sua requisição de maneira satisfatória, de modo que os prazos poderão estar suspensos, desde o envio dos nossos questionamentos, até o recebimento da sua resposta.
<br/>
Em caso de não atendimento a algum dos direitos acima mencionados, ou em caso de qualquer violação à legislação de proteção de dados pessoais, o time de Privacidade da DEXCO está pronto para te atender, entre em contato com privacidade@dex.co.
<br/>

 
      </Typography>

   
      <Typography variant="h6" className={classes.subTitle}>
        Encarregado pelo Tratamento de Dados Pessoais
      </Typography>
      <Typography variant="body1" className={classes.description}>
A DEXCO tem em sua estrutura um Encarregado pelo Tratamento de Dados Pessoais, precisamente para garantir que tenhamos sempre as melhores diretrizes e orientações sobre o tratamento de dados pessoais. Ele é o canal de comunicação sobre os temas da Privacidade e da Proteção de Dados Pessoais com as autoridades competentes e com você. Por isso, caso tenha alguma dúvida ou solicitação, entre em contato no e-mail dpo@dex.co.
<br/>Caso tenha dúvidas, comentários ou sugestões relacionados a esta Política, você pode entrar em contato com o time de privacidade da Dexco por este mesmo endereço de e-mail.
    
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Atualizações desta Política
      </Typography>
      <Typography variant="body1" className={classes.description}>
        A DEXCO manterá essa Política sempre atualizada, para que ela reflita a realidade sobre o tratamento de seus dados pessoais em nossos sites e aplicativos.     
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Última atualização em 25/08/2022.     
      </Typography>
    </Container>
  );
}