import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, DialogContent } from '@material-ui/core';
import { dialogCss } from './dialogCss';
import { MentionsUsers } from '../Shared';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateDialogsActions, ideaCreateActions } from '../../actions';
import { CreateIdeaActions } from './CreateIdeaActions';
import { useCreateIdeaController } from './hooks';

function DialogTitleDescription() {
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const idea = useSelector((state) => state.ideaCreate);
  const { dialogTitleDescription } = useSelector((state) => state.ideaCreateDialogs);
  const { register, handleSubmit, errors } = useForm({  mode: 'onBlur' });
  const [value, setValue] = useState('');
  const { close } = useCreateIdeaController();

  function handleCharacters(e) {
    setValue(e.target.value)
  }

  function handleChangeFieldValue(e) {
    const { name, value } = e.target;
    dispatch(ideaCreateActions.setFieldsTitleDescription(name, value));
  }

  function onSubmit() {
    dispatch(ideaCreateDialogsActions.openDialogMultiArea('DIALOG_TITLE_DESCRIPTION'));
  }

  function handleMentions(mentioned) {
    dispatch(ideaCreateActions.setContributors(mentioned));
  }

  return (
    <DialogCreateIdea open={dialogTitleDescription}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('qual_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={dialogClasses.dividerBottom}>
        <div>
          <TextField
            fullWidth
            name="name"
            label={t('titulo').toUpperCase()}
            placeholder={t('de_um_titulo_para_sua_ideia')}
            defaultValue={idea.name}
            onChange={handleCharacters}
            onBlur={handleChangeFieldValue}
            error={!!errors.name}
            style={{ margin: 8 }}
            inputProps={{ maxLength: 70 }}
            InputProps={{ disableUnderline: true }}
            inputRef={register({
              required: t('o_titulo_da_ideia_e_obrigatorio'),
              maxLength: 70,
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={dialogClasses.message}>
                {(errors.name && errors.name.message)}
                {value ? (
                  <div className={dialogClasses.characters}>
                    <span>{value.length}/70 caracteres</span>
                  </div>
                ) : null}
              </div>
            } 
          />
          <TextField
            fullWidth
            multiline
            minRows={8}
            name="description"
            label={t('descricao').toUpperCase()}
            placeholder={t('em_qual_ideia_voce_esta_pensando_descreva') + '...'}
            defaultValue={idea.description}
            onBlur={handleChangeFieldValue}
            error={!!errors.description}
            style={{ margin: 8 }}
            InputProps={{ disableUnderline: true }}
            inputRef={register({
              required: t('a_descricao_da_ideia_e_obrigatoria'),
              style:({fontSize: '0.75rem',})
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={dialogClasses.message}>
                {errors.description && errors.description.message}
              </div>
            }
          />
          <MentionsUsers onAddMentions={handleMentions} />
        </div>
      </DialogContent>
      
      <CreateIdeaActions name="DIALOG_TITLE_DESCRIPTION" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}

export { DialogTitleDescription };