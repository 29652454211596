import React from 'react';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  IconButton,
  TablePagination,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from '@material-ui/core';

import { StatusIdea } from '../../components';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TurnedInIcon from '@material-ui/icons/TurnedIn';

import { tableCss } from './TableCss';
import { dateFormat, truncate } from '../../helpers';

import { useDispatch } from 'react-redux';
import { externalContentActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { TableMenu } from './TableMenu';
import { ImagineLinkify } from '../TextLinkify';

const columns = [
  { id: 'title', label: 'título' },
  { id: 'link', label: 'url' },
  { id: 'status', label: 'status' },
  { id: 'actions', label: 'editar conteúdo', align: 'right' },
  { id: 'highlight', label: '' }
];

function ExternalContentsTable({ externalContent, externalContents, paging, history, dialog, setExternalContent }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const columns = [
    { id: 'title', label: t('titulo') },
    { id: 'link', label: t('url') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: t('fixado'), align: 'right' },
    { id: 'highlight', label: '' }
  ];
  const tableClasses = tableCss({
    text: t('linhas_por_pagina'),
  });

  function openDialogDelete(item) {
    dialog.setOpenDialog(true);
    setExternalContent(item);
  }

  function handleEdit({ _id }) {
    history.push(`/external-content/edit/${_id}`);
  }

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(
      externalContentActions.getExternalContentList(
        newPage,
        paging.search,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;

    dispatch(
      externalContentActions.getExternalContentList(
        1,
        paging.search,
        limit,
        paging.statusCode
      )
    );
  }

  return (
    <Box display='flex'>
      <Paper className={tableClasses.paperRoot}>
        <TableContainer sx={{ position: 'relative' }}>
          <Table
            stickyHeader
            aria-label="external content table"
            classes={{ root: tableClasses.table }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                    align={column.align || 'left'}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {externalContents &&
                externalContents.map((item) => {
                  const link = item.link.length < 60
                    ? item.link
                    : item.link.substr(0, 60) + '...';

                  return (
                    <TableRow
                      hover
                      key={item._id}
                      style={{ cursor: 'pointer', height: 60 }}
                      classes={{ root: tableClasses.tableRow }}
                    >
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {truncate(item.title, 50)}
                      </TableCell>
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        <ImagineLinkify>{link}</ImagineLinkify>
                      </TableCell>
                      <TableCell classes={{ root: tableClasses.tableCell }}>
                        <StatusIdea
                          status={{
                            name: item.status ? 'ativo' : 'não ativo',
                            color: item.status ? theme.palette.secondary.dark : theme.palette.auxiliary.orange.main
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingRight: 8,
                          }}
                        >
                        {!!item.fixed && (
                          <TurnedInIcon color="primary" />
                        )}
                        </Box>
                      </TableCell>

                      <TableMenu>
                        <List>
                          <ListItem onClick={() => handleEdit(item)} button>
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>
                                {t('editar')}
                              </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                        <List>
                          <ListItem onClick={() => openDialogDelete(item)} button>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>
                                {t('delete')}
                              </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      </TableMenu>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
          component="Box"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          count={paging.total}
          rowsPerPage={paging.limit}
          page={paging.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('exibindo')}
          style={{ zIndex: 10, position: 'relative' }}
        />
      </Paper>
    </Box>
  );
}

export { ExternalContentsTable };
