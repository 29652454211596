import React from 'react';
import { 
  makeStyles, 
  Container,
  Box,
} from '@material-ui/core';
import { FooterTerms } from '../../components/Shared';
import { FaqTitle } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.neutrals.low.medium,
    background: theme.palette.neutrals.high.lightest
  },
  containerContent: {
    paddingTop: theme.spacing(4),
    minHeight: '100vh',
  },
  creationDate: {
    fontSize: 18,
    fontWeight: '600',
    paddingBottom: 30,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '600',
    paddingBottom: 10,
  },
  info: {
    fontSize: 13,
    paddingBottom: 30,
    lineHeight: 1.8
  },
}));

function RegulationPage() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
    <FaqTitle title="políticas de privacidade" />
      
      <Container maxWidth="lg" className={classes.containerContent}>
        <Box p={0}>
          <div className={classes.creationDate}>PROGRAMA IMAGINE</div>
          <div className={classes.subTitle}>Políticas de Segurança da Informação FCamara</div>
          <div className={classes.info}>
<p>A segurança é um dos assuntos mais importantes dentre as preocupações de qualquer empresa.</p>
<p>Corroborando com os nossos clientes, fornecedores, colaboradores e, de acordo com a Lei Geral de Proteção de Dados - Lei n. 13.709/2018 (“<b>LGPD</b>”), formulamos uma Política de Segurança de informação para implementação, desenvolvimento e assistência técnica do PROGRAMA IMAGINE. </p>
<p>A presente Política de Segurança visa garantir a proteção, a manutenção da privacidade, integridade, disponibilidade e confidencialidade das informações para aqueles que possuem acesso ao PROGRAMA IMAGINE.</p>
<p>Nesse documento apresentaremos nossas diretrizes relacionadas à privacidade e proteção dos dados dos colaboradores dos nossos clientes (“<b>Colaboradores</b>” e, individualmente, “<b>Colaborador</b>”) e procedimentos para normatizar e melhorar nossa visão e atuação em segurança dos dados dos nossos clientes e Colaboradores.</p>
<p><b>Ressaltamos que todas as informações inseridas no PROGRAMA IMAGINE serão públicas aos usuários do referido programa, sem garantir anonimato aos Colaboradores.</b></p>
<p>Considerando que, de acordo com os Termos de Uso, o intuito do PROGRAMA IMAGINE é promover a participação direta por Colaboradores dos nossos clientes (denominados individualmente como “<b>Empresa</b>”) no processo de inovação e desenvolvimento da Empresa, ao cadastrar a sua ideia no Programa, os colaboradores cedem exclusiva, irrevogável e irrestritamente à Empresa a titularidade e o uso de sua ideia para seu amplo, total, pleno e irrestrito uso, exploração e disposição, o que chamamos de “Ideia” ou “Ideias”, em conjunto ou isoladamente.  </p>
<p>A Empresa poderá implantar, a qualquer tempo e de acordo com seu exclusivo interesse, as Ideias que os Colaboradores apresentarem no PROGRAMA IMAGINE, sem qualquer tipo de restrição, tendo em vista que o resultado decorrente da Ideia apresentada será sempre de propriedade exclusiva da Empresa.</p>
<p>O Colaborador que apresentar a Ideia não terá direito a qualquer remuneração adicional ao seu salário pela sua apresentação e/ou implantação; somente terá direito à premiação específica caso a sua ideia seja escolhida como vencedora de um desafio, o qual terá as suas regras de premiação definidas de acordo com o critério estabelecido pela área criadora do referido desafio. Outras premiações podem ser aplicadas aos participantes da plataforma com base em critérios previamente definidos e comunicados pelas áreas de negócio, desde que previamente e por escrito.</p>
<p>Os Termos de Uso do Programa Imagine e as disposições de segurança da informação aqui previstas seguirão o disposto no contrato de prestação de serviços firmado entre a FCamara, como contratada, e a Empresa, na qualidade de Contratante (“<b>Contrato</b>”).</p>
          </div>

          <div className={classes.subTitle}>1. Objetivo - Responsabilidade FCamara</div>
          <div className={classes.info}>
<p>De acordo com as regras de controle do fluxo de informações, segredos comerciais e da <b>LGPD</b>, a FCamara, desenvolvedora do PROGRAMA IMAGINE, declara que não possui qualquer gerência em relação ao conteúdo disponibilizado pela Empresa ou pelos Colaboradores no sistema, de modo que em nenhuma circunstância e de nenhuma forma poderá ser responsabilizada por qualquer ideia, comentário, informação, ato, fato, técnica, estratégia, método de operação, pormenores, inovações, segredos comerciais, marcas, criações, especificações técnicas e comerciais (“<b>Dados</b>”) inseridos no PROGRAMA IMAGINE, incluindo, mas não limitando a quaisquer erros ou omissões em tais Dados ou conteúdos, ou qualquer perda ou dano de qualquer tipo incorrido como resultado da sua utilização, do acesso ou da negação de acesso aos Dados.</p>
<p>A FCamara declara, ainda, que cumpre integralmente com as suas obrigações de sigilo, nos termos do Contrato e sob quaisquer Leis aplicáveis relativas ao tratamento de dados, de acordo com os requisitos da <b>LGPD</b>, porém, não se responsabiliza por mau uso dos Dados por terceiros que tenham acesso ao sistema.</p>
<p>A Empresa (cliente) concorda em defender, isentar e até mesmo indenizar a FCamara, seus executivos, diretores, funcionários, consultores, agentes, fornecedores, prepostos e revendedores de todas e quaisquer reclamações judiciais ou extrajudiciais dos seus Colaboradores, em relação a violação dos Dados relacionados a utilização do PROGRAMA IMAGINE, exceto se comprovado dolo da FCamara no evento.</p>
<p>Em nenhum caso, a FCamara, seus gestores, diretores, consultores, agentes, sucessores e fornecedores serão responsáveis por: <b>(i)</b> qualquer perda ou dano (incluindo qualquer dano indireto, lucros cessantes, material, imaterial, danos morais, especial, incidental, punitivo ou exemplar) que não for previsível. A perda ou dano somente será considerada previsível quando ocorrer por culpa ou dolo da FCamara, judicialmente comprovada; <b>(ii)</b> qualquer reivindicação relacionada com aplicativos, sites ou sistemas de terceiros, como, mas não se limitando ao desempenho inadequado, indisponibilidade do sistema, mau uso dos Dados; e <b>(iii)</b> qualquer prejuízo, mesmo que de responsabilidade direta da FCamara, causado por força maior ou por qualquer motivo que não seja razoavelmente previsível ou esteja além do controle razoável da FCamara.</p>
          </div>

          <div className={classes.subTitle}>2. Confidencialidade</div>
          <div className={classes.info}>
<p>A FCamara garante que o acesso por ela aos Dados será realizado apenas por pessoas autorizadas. Todos os funcionários da FCamara incumbidos de exercer qualquer função envolvendo o PROGRAMA IMAGINE somente acessarão a plataforma para fins de suporte técnico e/ou mediante justificativa ou pedido da Empresa. </p>
<p>A FCamara se obriga a manter o mais absoluto sigilo com relação a quaisquer Dados a serem inseridos no PROGRAMA IMAGINE da Empresa.</p>
          </div>

          <div className={classes.subTitle}>3. Propriedade Intelectual </div>
          <div className={classes.info}>
<p>Todos os direitos autorais referentes ao PROGRAMA IMAGINE são de titularidade exclusiva da FCamara, sendo vedada à Empresa e seus Colaboradores a reprodução, a alteração e/ou o uso indevido do seu conteúdo, sem prévia autorização por escrito desta, estando o infrator sujeito às penalidades previstas em lei.</p>
<p>A FCamara e a Empresa acordam que o fato da Empresa e seus Colaboradores utilizarem o PROGRAMA IMAGINE durante a vigência do Contrato, não dá o direito a Empresa de comercializar o uso da plataforma com seus Colaboradores e/ou qualquer terceiro, devendo essa utilização se dar de forma inteiramente gratuita por seus colaboradores e não podendo ser utilizada por terceiros.</p>
<p>Os Dados são de propriedade exclusiva da Empresa.</p>
          </div>

          <div className={classes.subTitle}>4. Colaboradores</div>
          <div className={classes.info}>
<p>Os Dados inseridos no PROGRAMA IMAGINE estarão disponíveis aos usuários e não garantirão anonimato e terão acesso pela Empresa.</p>
<p>Todas as informações pessoais relativas a Colaboradores que utilizem o PROGRAMA IMAGINE serão tratadas pela FCamara em concordância com a LGPD. Os Colaboradores declaram que estão cientes e concordam com as disposições do Termo de Uso e desta Política de Segurança da Informação, sobretudo no que se refere a isenção da responsabilidade pela FCamara em relação aos possíveis prejuízos acarretados diretamente ou indiretamente pelo uso do PROGRAMA IMAGINE, seja pela Empresa ou por qualquer Colaborador.</p>

          </div>

          <div className={classes.subTitle}>5. Integridade</div>
          <div className={classes.info}>
<p>Todos as Informações Confidenciais dos Colaboradores e da Empresa que forem armazenados no PROGRAMA IMAGINE permanecerão íntegros e inalterados, inclusive eventuais comentários feitos em cada inovação implementada.</p>

          </div>

          <div className={classes.subTitle}>6. Disponibilidade</div>
          <div className={classes.info}>
<p>A FCamara garante que todos os Dados estarão disponíveis para todos os Colaboradores em qualquer tempo de uso do PROGRAMA IMAGINE.</p>

          </div>

          <div className={classes.subTitle}>7. Autenticidade</div>
          <div className={classes.info}>
<p>A FCamara garante a possibilidade de identificar o autor de cada inserção feita no PROGRAMA IMAGINE, inclusive de eventuais comentários feitos em cada inovação implementada.</p>

          </div>

          <div className={classes.subTitle}>8. Segurança</div>
          <div className={classes.info}>
<p>Para proteger todas as informações pessoais dos Colaboradores e da Empresa, a FCamara adota precauções técnicas, administrativas e organizacionais para proteger os dados pessoais da Empresa e de seus respectivos Colaboradores contra perda, uso ou acesso não autorizado. Os dados são armazenados em um ambiente operacional seguro que não é acessível ao público.</p>
<p>A FCamara envida esforços para proteger a privacidade da conta da Empresa e/ou de seus Colaboradores, mas infelizmente não pode garantir total segurança. O acesso ou uso não autorizado de conta, falha de hardware ou software e outros fatores podem comprometer a segurança das suas Informações Confidenciais a qualquer momento, por isso, é importante que a Empresa ajude a manter um ambiente seguro para todos. Além de adotar boas práticas de segurança em relação à sua conta e aos seus dados, caso você identifique ou tome conhecimento de algo que comprometa a segurança dos seus Dados, por favor entre em contato com a FCamara, pelo e-mail: <a className={classes.descriptionRegulation} href={"mailto:" + "suporte@imagineinovacao.com.br"}>suporte@imagineinovacao.com.br</a></p>

          </div>

          <div className={classes.subTitle}>9. Violações da Política de Segurança da informação e Sanções</div>
          <div className={classes.info}>
<p>Nos casos em que houver violação desta política, sanções administrativas e/ou legais poderão ser adotadas, sem prévio aviso.</p>

          </div>

          <div className={classes.subTitle}>10. Vigência e Validade</div>
          <div className={classes.info}>
<p>A presente política passa a vigorar a partir da celebração do Contrato entre FCamara e Empresa sendo válida pelo mesmo prazo da vigência contratual, exceto no tocante a informações que sejam consideradas confidenciais.</p>

          </div>
        </Box>
      </Container>

      <FooterTerms />
    </div>
  );
}

export { RegulationPage };
