import React, { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import { dateFormat, truncate } from '../../helpers';
import { tableCss } from './TableCss';
import { ArchiveService } from '../../services'
import ArchiveIdeasItem from '../ArchiveIdeas/ArchiveIdeasItem';
import { useTranslation } from 'react-i18next';

function IdeasArchiveTables({ ideas, setIdeas, page, setPage, total, limit, setLimit }) {
  const tableClasses = tableCss();
  const { t } = useTranslation();

  const columns = [
    { id: 'date', label: t('data') },
    { id: 'title', label: t('titulo_da_ideia') },
    { id: 'challenge', label: t('autoria') },
    { id: 'actions', label: '' },
  ];

  function handleChangePage(event, page) {
    const newPage = page + 1;
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    setLimit(limit)

    ArchiveService.getArchiveIdeas({ page, limit }).then(response => {
      setIdeas(response.data)
    })
      .catch((error) => {
        console.error(error);
      });
    // dispatch(profileActions.getIdeasByUserId(userId, 1, limit));
  }

  return (
    <div>

      <Paper className={tableClasses.paperRoot}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="idea table"
            classes={{ root: tableClasses.table }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ideas &&
                ideas.map((idea) => {
                  return (
                    <ArchiveIdeasItem
                      tableClasses={tableClasses}
                      idea={idea}
                    />
                    // <TableRow
                    //   hover
                    //   key={idea._id}
                    //   style={{ cursor: 'pointer' }}
                    //   classes={{ root: tableClasses.tableRow }}
                    // >

                    //   <TableCell classes={{ root: tableClasses.tableCell }}>
                    //     {dateFormat(idea.createdAt)}
                    //   </TableCell>
                    //   <TableCell
                    //     className={tableClasses.oneLine}
                    //     classes={{ root: tableClasses.tableCell }}
                    //   >
                    //     {truncate(idea.name, 70)}
                    //   </TableCell>
                    //   <TableCell
                    //     className={tableClasses.oneLine}
                    //     classes={{ root: tableClasses.tableCell }}
                    //   >
                    //     {idea.userName}
                    //   </TableCell>
                    //   <TableCell
                    //     classes={{ root: tableClasses.tableCellRadiusRight }}
                    //   >
                    //     <IconButton
                    //       onClick={() => {
                    //         handleView(idea._id);
                    //       }}
                    //     >
                    //       <ViewIcon />
                    //     </IconButton>
                    //   </TableCell>
                    // </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          rowsPerPage={limit}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          count={total}
          page={page - 1}
          onChangePage={handleChangePage}
          labelRowsPerPage={t('exibindo')}
        />

      </Paper>
    </div>
  );
}

export { IdeasArchiveTables };
