import { ideaConclusionConstanst } from '../constants';

const initialState = {
  startDate: null,
  conclusionDate: null,
  financialReturn: null,
  conclusionDetails: '',
  costs: [],
  loadingCancelIdea: false,
  loadingCancelIdeaSuccess: false,
  loadingCancelIdeaFailure: false,
  memoriaCalculoFiles: [],
  validacaoControladoriaFiles: [],
};

export function ideaConclusion(state = initialState, action) {
  switch (action.type) {
    case ideaConclusionConstanst.SET_CONCLUSION_INPUT: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }
    }

    case ideaConclusionConstanst.SET_CONCLUSION_COSTS: {
      return {
        ...state,
        costs: action.payload.map((item) => ({
          costDescription: item.costDescription,
          estimatedCost: !item.estimatedCost ? 0 : parseFloat(item.estimatedCost),
          cost: parseFloat(item.cost),
        }))
      }
    }

    case ideaConclusionConstanst.PUT_CONCLUSION_IDEA_SUCCESS: {
      return initialState;
    }
    case ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_REQUEST: {
      return {
        ...state,
        loadingCancelIdea: true
      }
    }

    case ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_FAILURE: {
      return {
        ...state,
        loadingCancelIdea: false,
        loadingCancelIdeaFailure: true
      }
    }
    case ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_SUCCESS: {
      return initialState;
    }

    case ideaConclusionConstanst.IDEA_CONCLUSION_CLEAR: {
      return initialState;
    }
    case ideaConclusionConstanst.SET_MEMORIA_CALCULO_FILES: {
      return { 
        ...state,
        memoriaCalculoFiles: [
          ...state.memoriaCalculoFiles,
          ...action.payload
        ]
      }
    }
    case ideaConclusionConstanst.SET_VALIDACAO_CONTROLADORIA_FILES: {
      return { 
        ...state,
        validacaoControladoriaFiles: [
          ...state.validacaoControladoriaFiles,
          ...action.payload,
        ]
      }
    }
    case ideaConclusionConstanst.DELETE_MEMORIA_CALCULO_FILE: {
      return {
        ...state,
        memoriaCalculoFiles: state.memoriaCalculoFiles.filter(item => item.id !== action.payload)
      }
    }
    case ideaConclusionConstanst.DELETE_VALIDACAO_CONTROLADORIA_FILE: {
      return {
        ...state,
        validacaoControladoriaFiles: state.validacaoControladoriaFiles.filter(item => item.id !== action.payload)
      }
    }
    default:
      return state;
  }
}
