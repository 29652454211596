import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogContent,
  makeStyles,
  FormControl,
  FormLabel,
  Grid,
  ButtonBase,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { 
  ideaCreateActions,
  ideaCreateDialogsActions,
  multiAreaCascadeActions
} from '../../actions';
import { dialogCss } from './dialogCss';
import { DialogCustomTitle } from './DialogCustomTitle';
import { DialogCreateIdea } from './DialogCreateIdea';
import { MultiAreaCascade } from "./../../components/Shared"
import { CreateIdeaActions } from './CreateIdeaActions';
import { useCreateIdeaController } from './hooks';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  addMoreIconWrapper: {
    width: 18,
    height: 18,
    marginRight: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main
  },
  addButton: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  fieldsWrap: {
    [theme.breakpoints.down('xs')]: {
      "&:not(:first-child)": {
        borderTop: "1px solid rgb(170, 170, 170)"
      }
    }
  }
}));

function DialogMultiArea({ challenge, onClose }) {
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { imagineLocals } = useSelector((state) => state.domains);
  const idea = useSelector((state) => state.ideaCreate);
  const { fields } = useSelector(state => state.multiAreaCascade);
  const { dialogMultiArea } = useSelector(state => state.ideaCreateDialogs);

  const { previous, close } = useCreateIdeaController();

  function onSubmit() {
    if (fields.filter(field => field.selectedBusinessUnit).length > 0) {
      const multiAreaFields = fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit)
      dispatch(ideaCreateActions.setSelectedBusiness(multiAreaFields))

      if (challenge) {
        dispatch(ideaCreateDialogsActions.openDialogSendIdea('DIALOG_MULTI_AREA'));
      } else {
        dispatch(ideaCreateDialogsActions.openDialogRadical('DIALOG_MULTI_AREA'));
      }
    }
  }

  function handleAddAreaFIeld() {
    dispatch(multiAreaCascadeActions.addField(imagineLocals));
  }

  return (
    <DialogCreateIdea maxWidth="lg" open={dialogMultiArea}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('qual_e_a_area_de_implementacao_da_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={dialogClasses.dividerBottom}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('selecione_a_area_em_que_esta_ideia_podera_ser_implementada')}.{' '}
            {t('caso_necessario_inclua_novas_areas')}.
          </FormLabel>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MultiAreaCascade 
                    hasDeleteButton={true} 
                    loadBusinessUnitLocals={dialogMultiArea && idea.selectedBusiness} 
                    clearAll={false}
                  />
                </Grid>
                { fields.filter(item => item.selectedBusinessUnit).length > 0 && (
                  <Grid item xs={12}>
                    <ButtonBase
                      variant="contained"
                      color="transparent"
                      className={classes.addButton}
                      onClick={handleAddAreaFIeld}
                    >
                      <div className={classes.addMoreIconWrapper}>
                        <AddIcon fontSize="small" style={{ color: "white" }} />
                      </div>
                      {t('incluir_area')}
                    </ButtonBase>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>

      <CreateIdeaActions name="DIALOG_MULTI_AREA" onSubmit={onSubmit} />
    </DialogCreateIdea>
  );
}

export { DialogMultiArea };