export const timelineConstanst = {
  CLEAR_TIMELINE_FLAGS: "CLEAR_TIMELINE_FLAGS",
  TIMELINE_REQUEST: 'GET_TIMELINE_REQUEST',
  TIMELINE_SUCCESS: 'GET_TIMELINE_SUCCESS',
  TIMELINE_FAILURE: 'GET_TIMELINE_FAILURE',
  TIMELINE_REQUEST_REFRESH: "TIMELINE_REQUEST_REFRESH",
  TIMELINE_SUCCESS_REFRESH: "TIMELINE_SUCCESS_REFRESH",
  TIMELINE_FAILURE_REFRESH: "TIMELINE_FAILURE_REFRESH",

  MORE_TIMELINE_REQUEST: 'GET_MORE_TIMELINE_REQUEST',
  MORE_TIMELINE_SUCCESS: 'GET_MORE_TIMELINE_SUCCESS',
  MORE_TIMELINE_FAILURE: 'GET_MORE_TIMELINE_FAILURE',

  SORT_TIMELINE_CHANGE: 'SORT_TIMELINE_CHANGE',
  SORT_TIMELINE_CHANGE_FAILURE: 'SORT_TIMELINE_CHANGE_FAILURE',

  UP_IDEA_REQUEST: 'UPDATE_UP_IDEA_REQUEST',
  UP_IDEA_SUCCESS: 'UPDATE_UP_IDEA_SUCCESS',
  UP_IDEA_FAILURE: 'UPDATE_UP_IDEA_FAILURE',

  FAVORITE_IDEA_REQUEST: 'FAVORITE_IDEA_REQUEST',
  FAVORITE_IDEA_SUCCESS: 'FAVORITE_IDEA_SUCCESS',
  FAVORITE_IDEA_FAILURE: 'FAVORITE_IDEA_FAILURE',

  COMMENTS_IDEA_REQUEST: 'GET_COMMENTS_IDEA_REQUEST',
  COMMENTS_IDEA_SUCCESS: 'GET_COMMENTS_IDEA_SUCCESS',
  COMMENTS_IDEA_FAILURE: 'GET_COMMENTS_IDEA_FAILURE',

  CREATE_COMMENT_IDEA_REQUEST: 'POST_COMMENT_IDEA_REQUEST',
  CREATE_COMMENT_IDEA_SUCCESS: 'POST_COMMENT_IDEA_SUCCESS',
  CREATE_COMMENT_IDEA_FAILURE: 'POST_COMMENT_IDEA_FAILURE',

  UP_COMMENTS_IDEA_REQUEST: 'UPDATE_UP_COMMENTS_IDEA_REQUEST',
  UP_COMMENTS_IDEA_SUCCESS: 'UPDATE_UP_COMMENTS_IDEA_SUCCESS',
  UP_COMMENTS_IDEA_FAILURE: 'UPDATE_UP_COMMENTS_IDEA_FAILURE',

  REMOVE_COMMENTS_IDEA_REQUEST: 'UPDATE_REMOVE_COMMENTS_IDEA_REQUEST',
  REMOVE_COMMENTS_IDEA_SUCCESS: 'UPDATE_REMOVE_COMMENTS_IDEA_SUCCESS',
  REMOVE_COMMENTS_IDEA_FAILURE: 'UPDATE_REMOVE_COMMENTS_IDEA_FAILURE',

  GET_BANNERS_CHALLENGE_REQUEST: 'GET_BANNERS_CHALLENGE_REQUEST',
  GET_BANNERS_CHALLENGE_SUCCESS: 'GET_BANNERS_CHALLENGE_SUCCESS',
  GET_BANNERS_CHALLENGE_FAILURE: 'GET_BANNERS_CHALLENGE_FAILURE',
  SET_TIMELINE_SEARCH: "SET_TIMELINE_SEARCH",
  CLEAR_TIMELINE_ALL: "CLEAR_TIMELINE_ALL"
};
