import React, { useState, useEffect } from 'react';
import { UploadFile } from './UploadFile';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ImageList } from './ImageList';
import { useDispatch, useSelector } from 'react-redux';
import { ideaCreateActions, executionActions, ideaConclusionActions } from '../../actions';
import { VideoPreview } from './VideoPreview';
import { FileList } from './FileList';

function Upload({ type, img, name, message, accept, maxSize, uploadFrom = "default" }) {
  const dispatch = useDispatch();
  const { images, video, files } = useSelector((state) => state.ideaCreate);
  const { validacaoControladoriaFiles, memoriaCalculoFiles } = useSelector((state) => state.execution);
  const [filesState, setFilesState ] = useState([])

  function handleUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    }));

    switch(uploadFrom) {
      case 'default': 
    if (type === 'photo') {
      dispatch(ideaCreateActions.setImages(newUploadedFiles));
    }

    if (type === 'video') {
      dispatch(ideaCreateActions.setVideo(newUploadedFiles[0]));
    }

    if (type === 'file') {
      dispatch(ideaCreateActions.setFiles(newUploadedFiles));
        }
      break;
      case 'memoria-calculo':
        dispatch(ideaConclusionActions.setFiles("memoria-calculo", newUploadedFiles));
      break;
      case 'validacao-controladoria':
        dispatch(ideaConclusionActions.setFiles("validacao-controladoria", newUploadedFiles));
      break;
      default: 
        return;
    }
  }

  function render() {
    if (type === 'photo') {
      return images.length === 0 && <img src={img} alt={name} />;
    }

    if (type === 'video') {
      return !video.id && <img src={img} alt={name} />;
    }

    if (type === 'file') {
      return files.length === 0 && <img src={img} alt={name} />;
    }
  }
  useEffect(() => {
    function getFilesArray() {
      switch (uploadFrom) {
        case 'default': 
          return files
        case 'memoria-calculo':
          return memoriaCalculoFiles
        case 'validacao-controladoria':
          return validacaoControladoriaFiles
        default:
          return []
      }
    }
    setFilesState(getFilesArray())
  }, [files, validacaoControladoriaFiles, memoriaCalculoFiles, uploadFrom])

  return (
    <div>
      {images.length < 1 && type === 'photo' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {video && !video.id && type === 'video' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {filesState.length < 1 && type === 'file' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {type === 'photo' && !!images.length && <ImageList files={images} />}
      {type === 'video' && video && video.id && <VideoPreview video={video} />}
      {type === 'file' && !!filesState.length && <FileList uploadFrom={uploadFrom} files={filesState} />}
    </div>
  );
}

export { Upload };
