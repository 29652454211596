import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { 
  DialogContent,
  makeStyles,
  withStyles,
  FormControl,
  FormLabel,
  Radio,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ideaCreateActions, ideaCreateDialogsActions } from '../../actions';
import { DialogCustomTitle } from './DialogCustomTitle';
import { DialogCreateIdea } from './DialogCreateIdea';
import { dialogCss } from './dialogCss';
import { CreateIdeaActions} from './CreateIdeaActions';
import { useCreateIdeaController } from './hooks';

const ExpansionPanel = withStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    '&:before': { display: 'none' },
    '&$expanded': { margin: 'auto', },
  },
  expanded: {},
})) (MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    height: 64,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {}
})) (MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    fontWeight: 'none',
    padding: theme.spacing(1, 2, 1, 3),
  }
})) (MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  dividerBottom: {
    borderBottom: 'none',
  },
  name: {
    fontSize: 16,
    color: theme.palette.neutrals.low.main,
  },
  description: {
    fontSize: 13,
  },
}));

function DialogRadicalIdea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dialogClasses = dialogCss();
  const { handleSubmit, register } = useForm();
  const { methodology } = useSelector((state) => state.domains);
  const { radicals } = useSelector((state) => state.ideaCreate);
  const { dialogRadicalIdea } = useSelector((state) => state.ideaCreateDialogs);
  const { previous, close } = useCreateIdeaController();

  function onSubmit() {
    dispatch(ideaCreateDialogsActions.openDialogAmbit('DIALOG_RADICAL')); 
  }

  function selectedAmbit(methodology) {
    dispatch(ideaCreateActions.setRadicalIdea(methodology));
  }

  return(
    <DialogCreateIdea open={dialogRadicalIdea}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('como_voce_classifica_a_sua_idea')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <FormControl fullWidth>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('selecione_como_a_sua_idea_deve_ser_classificada')}
          </FormLabel>

          {methodology &&
            methodology.map((item, index) => (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary
                  classes={{root:"root", expanded: 'expanded'}}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormControlLabel
                    key={item._id}
                    value={item._id}
                    label={item.name}
                    inputRef={register}
                    className={classes.name}
                    control={<Radio color="secondary" />}
                    onChange={() => selectedAmbit(item)}
                    checked={radicals?._id === item?._id}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.description}>
                    {item.sequence === 1 && t('ideias_que_fazem_pequenas_e_constantes_melhorias_nos_processos_produtos_ou_servicos_existentes_para_torna_los_mais_eficientes')}
                    {item.sequence === 2 && t('ideias_que_fazem_grandes_mudancas_nos_processos_produtos_ou_servicos_existentes_alterando_significativamente_como_as_coisas_sao_feitas')}
                    {item.sequence === 3 && t('ideias_que_criam_novos_mercados_ou_mudam_completamente_os_mercados_atuais_substituindo_as_formas_tradicionais_de_fazer_as_coisas')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          }
        </FormControl>
      </DialogContent>

      <CreateIdeaActions name="DIALOG_RADICAL" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}

export { DialogRadicalIdea };