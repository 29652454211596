import React, { useEffect, useState } from 'react';
import { Box, Card, Container, Grid, makeStyles } from '@material-ui/core';
import { IdeaEditGeneral } from "./../../components/IdeaEdit/IdeaEditGeneral"
import { IdeaEditGeneralImage } from "./../../components/IdeaEdit/IdeaEditGeneralImage"
import { IdeaEditGeneralVideo } from "./../../components/IdeaEdit/IdeaEditGeneralVideo"
import { IdeaEditGeneralDocs } from "./../../components/IdeaEdit/IdeaEditGeneralDocs"
import { IdeaEditGeneralBusinessCanvas } from "./../../components/IdeaEdit/IdeaEditGeneralBusinessCanvas"
import { IdeaEditGeneralValueCanvas } from "./../../components/IdeaEdit/IdeaEditGeneralValueCanvas"
import { IdeaEditGeneralLinks } from "./../../components/IdeaEdit/IdeaEditGeneralLinks"
import { IdeaEditGeneralParticipants } from "./../../components/IdeaEdit/IdeaEditGeneralParticipants"
import { IdeaEditGeneralCoverage } from "./../../components/IdeaEdit/IdeaEditGeneralCoverage"
import { IdeaEditActions } from "./../../components/IdeaEdit/IdeaEditActions"
import { BackNavAdmin } from "../../components/Shared/BackNavAdmin"
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { domainsActions, ideaUpdateActions, challengeActions } from '../../actions';
import { history } from '../../helpers';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 14,
    },
  },
  container: {
    paddingLeft: 16,
    justifyContent: 'center'
  },
  btnContainer: {
    width: '92%',
    display: 'flex',
    justifyContent: 'end',
  },
  skeletonCard: {
    display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 50
  }
}));

export function IdeaEditPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ownerIdea, setOwnerIdea] = useState(false);
  const { ideaUpdate, user } = useSelector(state => state);
  const { challenges, paging } = useSelector(state => state.challenge);
  const { ideaFailed } = useSelector(state => state.ideaUpdate);

  useEffect(() => {
    dispatch(domainsActions.getDomains());
    dispatch(ideaUpdateActions.getIdeaDetails(id, 'general'));
    if (user.typeProfile.type !== 'admin') {
    dispatch(challengeActions.getChallengesActive());
    } else {
      dispatch(challengeActions.getChallenges(-1, paging.sort, paging.filter));
    }
  }, [dispatch, id]);
  useEffect(() => {
    if (challenges.length > 0) {
      dispatch(ideaUpdateActions.loadChallenges(challenges.map(item => ({
        label: item.name,
        value: item._id,
      }))));
    }
  }, [ dispatch, challenges ])

  useEffect(() => {
    const isAdmin = user.typeProfile.type;
    const isOwner = ideaUpdate.user._id === user._id;
    const isParticipant = (Array.isArray(ideaUpdate.contributors) && ideaUpdate.contributors.length) && ideaUpdate.contributors.filter(item => item === user._id).length > 0

    if (isAdmin || isOwner || isParticipant) {
      setOwnerIdea(true)
    }
  }, [ideaUpdate, user]);

  useEffect(() => {
    if (ideaFailed) {
      //nao existe a ideia
      history.goBack()
    }
  }, [ideaFailed])

  useEffect(() => {
    return () => dispatch(ideaUpdateActions.clearIdeaDetails());
  }, [dispatch])

  function SkeletonComponent() {
    const SkeletonCard = () => (<Card className={classes.skeletonCard}>
          <Box display='flex' justifyContent='space-evenly' width='100%' paddingTop={3}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton width="85%" height={40}/>
          </Box>
          <Skeleton width="95%" height={200}/>
        </Card>);
    return (
      <>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      </>
    )
  }

  return (
    ownerIdea && (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BackNavAdmin title={ideaUpdate?.name ? ideaUpdate?.name :`${t('carregando')}`} />
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                {ideaUpdate?._id ? (
                  <Grid container spacing={4}>
                    <IdeaEditGeneral ideaUpdate={ideaUpdate} />
                  <IdeaEditGeneralImage />
                  <IdeaEditGeneralVideo />
                  <IdeaEditGeneralDocs />
                  <IdeaEditGeneralBusinessCanvas />
                  <IdeaEditGeneralValueCanvas />
                  <IdeaEditGeneralLinks />
                  <IdeaEditGeneralParticipants />
                  <IdeaEditGeneralCoverage />
                  </Grid> 
                ) : (
                  <SkeletonComponent />
                )}
              </Grid>
              <Grid item xs={4}>
                <IdeaEditActions />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    )
  );
}
