import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Box, Grid, Card, CardMedia } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import config from '../../config';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { ChallengeRankCard } from './ChallengeRankCard';
import { ChallengeSchedule } from './ChallengeSchedule';
import { ChallengeInfoDetails } from './ChallengeInfoDetails';
import { ChallengeInfoAwardsCard } from './ChallengeInfoAwardsCard';
import { ChallengeIdeaTable } from '../Tables/ChallengeIdeaTable';
import { AvatarIconWithText } from '../Shared';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    padding: theme.spacing(4),
  },
  media: {
    height: 203,
    backgroundColor: theme.palette.neutrals.low.light,
    borderRadius: 8,
  },
  videoContainer: {
    marginTop: theme.spacing(5)
  }
}));

export function ChallengeDetailsContent({ id, challengeDetail, ideas, paging, type }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const imgUrl = challengeDetail?.banner?.url ? challengeDetail?.banner?.url : challengeDetail?.attachment?.url;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CardMedia className={classes.media} image={ imgUrl || imageCard }/>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              {challengeDetail && <ChallengeInfoDetails challenge={challengeDetail} />}
              <ChallengeSchedule challenge={challengeDetail} />
            </Card>
          </Grid>
          {challengeDetail.video && (
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <AvatarIconWithText
                text={t('video')}
                icon={<VideocamIcon />}
              />
              <Box className={classes.videoContainer}>
                <video style={{ width: '100%' }} controls>
                  <source src={challengeDetail.video.url} type={challengeDetail.video.mimeType.mimeType} />
                  <object data={challengeDetail.video.url}>
                    <embed src={challengeDetail.video.url}/>
                  </object>
                </video>
              </Box>
            </Card>
          </Grid>
          )}
          
        </Grid>
      </Grid>
      
      {config.SHOW_REWARD_FIELDS_ON_CHALLENGE && (
        <Grid item xs={12} lg={4}>
          {challengeDetail?.rewards?.every(reward => reward.win) && (
            <ChallengeRankCard challenge={challengeDetail} />
          )}
          <ChallengeInfoAwardsCard challenge={challengeDetail} />
        </Grid>
      )}
      <Grid item xs={12}>
        {ideas && ideas.length > 0 && (
          <ChallengeIdeaTable id={id} ideas={ideas} paging={paging} userRule={type} />
        )}
      </Grid>
    </Grid>
  );
}