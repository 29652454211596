import React, { useState, useEffect } from 'react';
import { makeStyles, InputBase, fade } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.grey[500], 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

function SearchArchive({
  query,
  placeholder = 'Pesquise por nome da ideia ou desáfio',
}) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  function sendQuery(q) {
    query(q);
  }

  const debouncedSearchTerm = useDebounce(value, 500);

  useEffect(() => {
    sendQuery(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
}

export { SearchArchive };
